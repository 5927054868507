import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, ThemeProvider } from '@mui/material';
import useApi from '../../hooks/useApi';
import notify from '../../utils/notification';
import signlogo from "../../asset/signlogo.png";
import Footer from "../common/Footer";
import { customTheme } from '../../utils/theme';

const categories = [
    { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
    { name: "Educational", value: "EDUCATIONAL" },
    { name: "Entertainment", value: "ENTERTAINMENT" },
    { name: "Technology", value: "TECHNOLOGY" },
    { name: "Travel", value: "TRAVEL" },
    { name: "Lifestyle", value: "LIFESTYLE" },
    { name: "Fashion", value: "FASHION" },
    { name: "Gaming", value: "GAMING" },
    { name: "Auto", value: "AUTO" },
    { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
    { name: "Other", value: "OTHER" },
];

const deliveryTypes = [
    { name: 'Instagram Posts', value: 'INSTAGRAM_POSTS', inputValue: 'post' },
    { name: 'Instagram Reels', value: 'INSTAGRAM_REELS', inputValue: 'reel' },
    { name: 'Instagram Stories', value: 'INSTAGRAM_STORIES', inputValue: 'stories' },
    { name: 'YouTube Shorts', value: 'YOUTUBE_SHORTS', inputValue: 'shorts' },
    { name: 'YouTube Integrated Videos', value: 'YOUTUBE_INTEGRATED_VIDEOS', inputValue: 'integratedVideo' },
    { name: 'YouTube Streams', value: 'YOUTUBE_STREAMS', inputValue: 'streaming' },
    { name: 'YouTube Dedicated Reels', value: 'YOUTUBE_DEDICATED_REELS', inputValue: 'dedicatedReel' },
]

const InfluencerOnboard = () => {
    const { makeRequest } = useApi();
    const navigate = useNavigate();
    const location = useLocation();
    const token = new URLSearchParams(location.search).get("token");
    const [types, setTypes] = useState([]);
    const [detail, setDetail] = useState({
        name: "",
        category: "",
        types: []
    })

    const [data, setData] = useState({
        instagramPrice: {
            reel: 0,
            post: 0,
            stories: 0
        },
        youtubePrice: {
            shorts: 0,
            dedicatedReel: 0,
            integratedVideo: 0,
            streaming: 0
        },
        message: ""
    });

    const removeZeroValues = (obj) => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== 0)
        );
    };

    const convertValue = (value) => {
        const item = deliveryTypes.find(item => item.value === value);
        return item.name;
    }
    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === "price") {
            value = parseFloat(value)
        }
        setData((prev) => ({ ...prev, [name]: value }));
    };

    const getInput = (value) => {
        const item = deliveryTypes.find(item => item.value === value);
        return item.inputValue;
    }

    const getDetails = async () => {
        try {
            const res = await makeRequest("GET", "/form/influencer/details", null, token)
            if (res) {
                setDetail(res.data.data);
                console.log(res.data.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const cleanedData = {
            ...data,
            name: detail.name,
            category: detail.category,
            instagramPrice: removeZeroValues(data.instagramPrice),
            youtubePrice: removeZeroValues(data.youtubePrice),
        };

        try {
            const res = await makeRequest("POST", "/form/influencer/submit", { "details": cleanedData }, token)
            if (res) {
                notify("success", "Your response is recorded!");
                navigate(`/onboard/success?token=${token}`);
            }
        } catch (error) {
            console.log(error);
            notify("error", "Error recording response");
        }
    }

    useEffect(() => {
        getDetails();
    }, [])

    return (
        <>
            <div className="flex flex-col gap-4 w-11/12 h-[90vh] mx-auto mt-8">
                <div>
                    <img src={signlogo} className="h-9" alt="Logo" />
                </div>
                <p className="text-2xl font-medium">Influencer Response Form</p>
                <ThemeProvider theme={customTheme}>
                    <form
                        onSubmit={handleSubmit}
                        className="w-3/4 flex flex-col gap-4 mx-auto shadow-md p-3 px-4 my-4"
                    >
                        <TextField
                            id="name"
                            name="name"
                            label="Influencer Name"
                            variant="outlined"
                            value={detail.name}
                            disabled
                            required
                        />

                        {/* Category */}
                        <FormControl required>
                            <InputLabel id="categoryLabel">Category</InputLabel>
                            <Select
                                labelId="categoryLabel"
                                id="category"
                                name="category"
                                value={detail.category}
                                disabled
                                label="Category"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {categories.map((item, i) => (
                                    <MenuItem value={item.value} key={i}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <div className="flex gap-20">
                            <div className="flex flex-col gap-4">
                                <div className="mt-2 flex justify-between w-4/5">
                                    <h2 className="font-medium">Delivery Type</h2>
                                    <h2 className="font-medium">Prices</h2>
                                </div>

                                {detail.deliveryTypes?.map(item => (
                                    <div key={item} className="flex w-full justify-between items-center gap-4">
                                        <h3 className="border border-[#2DA9B2] rounded-md px-3 py-2 text-[#2DA9B2] font-medium">{convertValue(item)}</h3>
                                        <TextField
                                            type="number"
                                            placeholder={`Enter price for ${convertValue(item)} in ₹`}
                                            className="border border-[darkgray] outline-none px-2 rounded-md text-center"
                                            inputProps={{ min: "0" }}
                                            value={
                                                item.includes('INSTAGRAM')
                                                    ? data.instagramPrice[getInput(item)] || 0
                                                    : data.youtubePrice[getInput(item)] || 0
                                            }
                                            onChange={(e) => {
                                                const value = parseFloat(e.target.value);
                                                setData(prev => ({
                                                    ...prev,
                                                    [item.includes('INSTAGRAM') ? 'instagramPrice' : 'youtubePrice']: {
                                                        ...prev[item.includes('INSTAGRAM') ? 'instagramPrice' : 'youtubePrice'],
                                                        [getInput(item)]: value
                                                    }
                                                }));
                                            }}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>

                        <TextField
                            id="message"
                            name="message"
                            label="Message"
                            variant="outlined"
                            multiline
                            rows={3}
                            value={data.message}
                            onChange={handleChange}
                        />

                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="bg-[#2DA9B2] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
                            >
                                CONFIRM
                            </button>
                        </div>
                    </form>
                </ThemeProvider>
            </div>
            <Footer />
        </>
    )
}

export default InfluencerOnboard