import { createSlice } from "@reduxjs/toolkit";

const  initialState = {
    deliverySidebar: false,
    brandDeliverySidebar: false,
    error: null,
}

const componentSlice = createSlice({
  name: "component",
  initialState: initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      return{
        ...state,
        deliverySidebar: !state.deliverySidebar,
      }
    },
    toggleBrandSidebar: (state, action) => {
      return{
        ...state,
        brandDeliverySidebar: !state.brandDeliverySidebar,
      }
    }
  }
})

export const { toggleSidebar, toggleBrandSidebar } = componentSlice.actions
export default componentSlice.reducer