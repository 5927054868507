import React, { useState } from "react";
import Navbar from "../common/Navbar";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import reduce from "../../utils/reduce";
import notification from "../../utils/notification";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { customTheme } from "../../utils/theme";
import { FaArrowLeft } from "react-icons/fa";
import Footer from "../common/Footer";

const categories = [
  { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
  { name: "Educational", value: "EDUCATIONAL" },
  { name: "Entertainment", value: "ENTERTAINMENT" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Lifestyle", value: "LIFESTYLE" },
  { name: "Fashion", value: "FASHION" },
  { name: "Gaming", value: "GAMING" },
  { name: "Auto", value: "AUTO" },
  { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
  { name: "Other", value: "OTHER" },
];
const platforms = [
  { name: "Instagram", value: "INSTAGRAM" },
  { name: "Youtube", value: "YOUTUBE" },
];
const languages = [
  { name: "English", value: "English" },
  { name: "Hindi", value: "Hindi" },
];

const CreatePlan = () => {
  const [details, setDetail] = useState({
    name: "",
    description: "",
    category: "",
    platforms: [""],
    language: [""],
  });

  const [platform, setPlatform] = useState([]);
  const [language, setLangauge] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { makeRequest } = useApi();

  // Handle change in input element
  const handleChange = (e) => {
    setDetail((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  // Handle change for platform
  const handlePlatformChange = (event) => {
    const {
      target: { value },
    } = event;
    setPlatform(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  // Handle change for languages
  const handleLanguageChange = (event) => {
    const {
      target: { value },
    } = event;
    setLangauge(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      details.platforms = platform;
      details.language = language;
      let req = reduce(details);
      const response = await makeRequest("POST", "/groups/create", req);
      notification("success", "Plan created successfully!");
      navigate("/influencerPlan");
    } catch (error) {
      console.log("error", error.response.data.message);
      notification("error", "Error, try again!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Navbar />
      <div className="flex flex-col gap-4 w-11/12 mx-auto">
        <div>
          <button
            className="pt-8 pb-4 flex gap-1 items-center text-[#2DA9B2]"
            onClick={() => navigate("/influencerPlan")}
          >
            <FaArrowLeft />
            Back
          </button>
        </div>

        <p className="text-2xl ml-4 font-medium">Create Plan</p>

        <div className="flex justify-center">
          {/*right part*/}
          <form
            className="w-3/4 flex flex-col gap-4 mx-4 shadow-md rounded-md py-4 px-4 my-8 bg-white"
            onSubmit={handlesubmit}
          >
            <ThemeProvider theme={customTheme}>
              <TextField
                id="name"
                name="name"
                label="Plan Name"
                variant="outlined"
                value={details.name}
                onChange={handleChange}
                required
              />

              <TextField
                id="desc"
                name="description"
                label="Plan Desc"
                variant="outlined"
                placeholder="Plan Description. Eg:- It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                value={details.description}
                onChange={handleChange}
              />

              {/*plan category*/}
              <FormControl>
                <InputLabel id="categoryLabel">Plan Category</InputLabel>
                <Select
                  labelId="categoryLabel"
                  id="category"
                  name="category"
                  value={details.category}
                  label="Plan Category *"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((item, i) => (
                    <MenuItem value={item.value} key={i}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Platform */}
              <FormControl required>
                <InputLabel id="platformLabel">PlatForm</InputLabel>
                <Select
                  labelId="platformLabel"
                  id="platform"
                  multiple
                  value={platform}
                  onChange={handlePlatformChange}
                  input={<OutlinedInput label="PlatForm" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {platforms.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      <Checkbox checked={platform.indexOf(item.value) > -1} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/*Influencer language*/}
              <FormControl required>
                <InputLabel id="languageLabel">Influencer Language</InputLabel>
                <Select
                  labelId="languageLabel"
                  id="language"
                  multiple
                  value={language}
                  onChange={handleLanguageChange}
                  input={<OutlinedInput label="Influencer Language" />}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {languages.map((item, i) => (
                    <MenuItem key={i} value={item.value}>
                      <Checkbox checked={language.indexOf(item.name) > -1} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </ThemeProvider>

            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-4 m-3 h-[35px]"
                disabled={loading}
              >
                {!loading ? "CREATE" : "Loading..."}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CreatePlan;
