import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import authImage from "../../Assets/auth.jpg";
import signlogoImage from "../../asset/signlogo.png";
import notify from "../../utils/notification";
import { TextField, ThemeProvider } from "@mui/material";
import { customTheme } from "../../utils/theme";
import Design from "./Design";

const AgentSignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [detail, setDetails] = useState({
    name: "",
    email: "",
    otp: "",
    password: "",
  });

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://agencyapi.getmentore.com/agent/auth/join",
        detail
      );
      if (response) {
        notify("success", "Agent Signup Successful!");
        navigate("/signin");
      }
    } catch (error) {
      console.log("error", error);
      notify("error", error.response.data.message || "Error, try again!");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <div className="flex justify-center items-center h-screen">
        <form
          className="flex w-5/6 rounded-md overflow-hidden"
          onSubmit={handleSignUp}
        >
          <Design />

          <div className="w-1/2 flex flex-col justify-center lg:px-8 px-4 py-4 gap-4 bg-white">
            <div className="flex gap-1 items-center">
              <img src={signlogoImage} alt="Echio Logo" className="w-8 h-8" />
              <span className="text-2xl font-medium text-gray-600">Echio</span>
            </div>

            <div className="my-2">
              <p className="text-xl">Sign Up as Agent</p>
              <p className="text-sm text-gray-600">to access Echio Home</p>
            </div>

            <TextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              placeholder="Name"
              value={detail.name}
              onChange={handleChange}
              required
              fullWidth
              size="small"
            />

            <div className="flex gap-2 w-full">
              <TextField
                id="email"
                type="email"
                name="email"
                label="Email"
                variant="outlined"
                placeholder="support@echio.in"
                value={detail.email}
                onChange={handleChange}
                className="w-4/6"
                required
                size="small"
              />
              <TextField
                id="code"
                label="Invite Code"
                variant="outlined"
                name="otp"
                placeholder="Invite Code(check mail)"
                className="w-2/6"
                value={detail.otp}
                onChange={handleChange}
                required
                size="small"
              />
            </div>

            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              type="password"
              placeholder="**************"
              value={detail.password}
              onChange={handleChange}
              required
              fullWidth
              size="small"
            />

            <button
              type="submit"
              className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white mt-2 py-2 rounded-md"
              disabled={loading}
            >
              {!loading ? "CREATE YOUR AGENT ACCOUNT" : "Loading..."}
            </button>

            <div className="text-sm flex items-center flex-col gap-2">
              <p>
                Already a User?{" "}
                <a
                  href="/signin"
                  className="text-[#2DA9B2] hover:text-[#2da9b2d8] font-bold"
                >
                  Login into your account
                </a>
              </p>
              <p>
                New User?{" "}
                <a
                  href="/signup"
                  className="text-[#2DA9B2] hover:text-[#2da9b2d8] font-bold"
                >
                  Create your account
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default AgentSignUp;
