import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Chart as ChartJS } from "chart.js/auto";
import { FaArrowTrendUp } from "react-icons/fa6";
import { FaArrowTrendDown } from "react-icons/fa6";
import { customTheme } from "../../utils/theme";
import { Avatar, Button, ThemeProvider } from "@mui/material";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import Carousel from "./Carousel";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import useApi from "../../hooks/useApi";
import {
  calculateTime,
  capitalize,
  formatAlias,
} from "../../utils/formatNumber";

const Home = () => {
  const [campaign, setCampaign] = useState([]);
  const [activity, setActivity] = useState([]);
  const [loadingCampaign, setLoadingCampaign] = useState(false);
  const [loadingActitity, setLoadingActivity] = useState(false);
  const [pageCampaign, setPageCampaign] = useState(1);
  const [totalCampaign, setTotalCampaign] = useState(1);
  const [pageActivity, setPageActivity] = useState(1);
  const [totalActivity, setTotalActivity] = useState(1);
  const { makeRequest } = useApi();
  const navigate = useNavigate();

  const showMessage = (item) => {
    switch (item.kind) {
      case "INFLUENCER_SUGGESTED":
        return (
          <span className="">
            {item.influencerIds.length} influencers Suggested in the{" "}
            {item?.campaign?.name} campaign
          </span>
        );
      case "INFLUENCER_REVIEWED":
        return (
          <span className="break-words">
            A influencer is {item.approved ? "approved" : "rejected"} in the{" "}
            {item?.campaign?.name} campaign
          </span>
        );
      case "WORK_SUBMITTED":
        return (
          <span className="word-wrap">
            {item?.influencer?.name} has submitted{" "}
            {capitalize(item.deliveryStep)} of {formatAlias(item.deliveryType)}{" "}
            in {" " + item?.campaign?.name} campaign
          </span>
        );
      case "WORK_REVIEWED":
        return (
          <span className="word-wrap">
            {capitalize(item.deliveryStep)} of {formatAlias(item.deliveryType)}{" "}
            for {item?.influencer?.name} has been{" "}
            {item.approved ? "approved" : "rejected"} by the brand in
            {" " + item?.campaign?.name} campaign
          </span>
        );
      default:
        return <></>;
    }
  };

  const fetchCampaigns = async (pageNumber = 1) => {
    if (totalCampaign * 4 === campaign.length) {
      return;
    }
    if (pageNumber === 1) {
      setCampaign([]);
    }

    try {
      setLoadingCampaign(true);
      const requestData = {
        filters: {},
        sort: {
          createdDate: -1,
        },
        page: pageNumber,
        pageSize: 4,
      };

      const response = await makeRequest(
        "POST",
        "/campaigns/list",
        requestData
      );

      if (response) {
        const newData = response?.data?.data?.results || [];

        setCampaign((prevData) => [...prevData, ...newData]);
        console.log("campaign data: ", newData);
        setTotalCampaign(response?.data?.data?.total);
      }
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoadingCampaign(false);
    }
  };

  const fetchActivities = async (pageNumber = 1) => {
    if (totalActivity * 3 === activity.length) {
      return;
    }
    if (pageNumber === 1) {
      setActivity([]);
    }

    setLoadingActivity(true);

    const reqData = {
      filters: {},
      sort: {
        createdAt: -1,
      },
      expand: {
        influencer: {},
        campaign: {},
      },
      pageSize: 3,
      page: pageNumber,
    };
    try {
      const res = await makeRequest("POST", "/notifications/list", reqData);
      if (res) {
        const data = res.data.data?.results;
        console.log(data);
        setActivity((prev) => [...prev, ...data]);
        setTotalActivity(res.data.data?.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingActivity(false);
    }
  };

  useEffect(() => {
    fetchActivities(pageActivity);
  }, [pageActivity]);

  useEffect(() => {
    fetchCampaigns(pageCampaign);
  }, [pageCampaign]);

  return (
    <ThemeProvider theme={customTheme}>
      <Navbar />
      <div className="flex gap-2 max-w-[95vw] mx-auto">
        {/* Left Section */}
        <div className="flex flex-col gap-4 my-5 w-4/5 h-fit px-2">
          {/* Top Row for stats */}
          <div className="flex gap-3 w-full h-1/6 overflow-x-scroll">
            <div className="bg-[#E6E6E6] text-[#1C1C1C] py-6 px-8 rounded-lg shadow-md  flex flex-col gap-3 justify-center w-1/5 min-w-fit">
              <p className="font-medium text-sm">Views</p>
              <p className="flex gap-4">
                <span className="font-bold text-2xl text-black">7,265</span>
                <span className="text-sm flex gap-1 items-center">
                  +11.01%
                  <FaArrowTrendUp />
                </span>
              </p>
            </div>

            <div className="bg-[#B5D9DC] text-[#1C1C1C] py-6 px-8 rounded-lg shadow-md  flex flex-col gap-3 justify-center w-1/5 min-w-fit">
              <p className="font-medium text-sm">Visits</p>
              <p className="flex gap-4">
                <span className="font-bold text-2xl text-black">3,671</span>
                <span className="text-sm flex gap-1 items-center">
                  -0.03%
                  <FaArrowTrendDown />
                </span>
              </p>
            </div>

            <div className="bg-[#E1E1E1] text-[#1C1C1C] py-6 px-8 rounded-lg shadow-md  flex flex-col gap-3 justify-center w-1/5 min-w-fit">
              <p className="font-medium text-sm">New Users</p>
              <p className="flex gap-4">
                <span className="font-bold text-2xl text-black">156</span>
                <span className="text-sm flex gap-1 items-center">
                  +15.03%
                  <FaArrowTrendUp />
                </span>
              </p>
            </div>

            <div className="bg-[#B5D9DC] text-[#1C1C1C] py-6 px-8 rounded-lg shadow-md  flex flex-col gap-3 justify-center w-1/5 min-w-fit">
              <p className="font-medium text-sm">Active Users</p>
              <p className="flex gap-4">
                <span className="font-bold text-2xl text-black">2,305</span>
                <span className="text-sm flex gap-1 items-center">
                  +7.67%
                  <FaArrowTrendUp />
                </span>
              </p>
            </div>

            <div className="bg-[#E5ECF6] text-[#1C1C1C] py-6 px-8 rounded-lg shadow-md  flex flex-col gap-3 justify-center w-1/5 min-w-fit">
              <p className="font-medium text-sm">Total Campaign</p>
              <p className="flex gap-4">
                <span className="font-bold text-2xl text-black">18</span>
                <span className="text-sm flex gap-1 items-center">
                  +64.08%
                  <FaArrowTrendUp />
                </span>
              </p>
            </div>
          </div>

          {/* Middle Row for Images */}
          <div className="flex justify-center w-full h-3/7 gap-4">
            <div className="w-2/3 bg-[#FFFFFF] text-[#1C1C1C] py-4 px-4 rounded-lg shadow-md flex flex-col gap-3">
              <h2 className="text-sm text-[#1C1C1C] font-semibold">
                Total Influencers
              </h2>
              <LineChart />
            </div>

            <Carousel />
          </div>

          {/* Bottom Stats */}
          <div className="flex gap-4 w-full">
            <div className="bg-[#FFFFFF] text-[#1C1C1C] py-2 px-4 rounded-lg shadow-md flex flex-col gap-3 w-1/3">
              <h2 className="text-sm text-[#1C1C1C] font-semibold">
                Influencers By Location
              </h2>
              <PieChart />
            </div>

            {/* <div className="bg-[#FFFFFF] text-[#1C1C1C] py-2 px-4 rounded-lg shadow-md h-60 flex flex-col gap-3 w-1/5">
              <h2 className="text-sm text-[#1C1C1C] font-semibold">
                Active Campaign
              </h2>
              <div className="flex justify-between items-center gap-4 w-full">
                <p className="text-[14px]">GN Tunes</p>
                <div className="h-1 w-1/3 flex gap-1">
                  <span className="rounded-2xl bg-black w-1/3"></span>
                  <span className="rounded-2xl bg-gray-400 w-1/3"></span>
                  <span className="rounded-2xl bg-gray-200 w-1/3"></span>
                </div>
              </div>
              <div className="flex justify-between items-center gap-4 w-full">
                <p className="text-[14px]">GrooveNexus</p>
                <div className="h-1 w-2/3 flex gap-1">
                  <span className="rounded-2xl bg-black w-1/3"></span>
                  <span className="rounded-2xl bg-gray-400 w-1/3"></span>
                  <span className="rounded-2xl bg-gray-200 w-1/3"></span>
                </div>
              </div>
              <div className="flex justify-between items-center gap-4 w-full">
                <p className="text-[14px]">GN Souls</p>
                <div className="h-1 w-1/3 flex gap-1">
                  <span className="rounded-2xl bg-black w-1/3"></span>
                  <span className="rounded-2xl bg-gray-400 w-1/3"></span>
                  <span className="rounded-2xl bg-gray-200 w-1/3"></span>
                </div>
              </div>
              <div className="flex justify-between items-center gap-4 w-full">
                <p className="text-[14px]">GN Studios</p>
                <div className="h-1 w-1/2 flex gap-1">
                  <span className="rounded-2xl bg-black w-1/3"></span>
                  <span className="rounded-2xl bg-gray-400 w-1/3"></span>
                  <span className="rounded-2xl bg-gray-200 w-1/3"></span>
                </div>
              </div>
              <div className="flex justify-between items-center gap-4 w-full">
                <p className="text-[14px]">Higher Pitch</p>
                <div className="h-1 w-1/3 flex gap-1">
                  <span className="rounded-2xl bg-black w-1/3"></span>
                  <span className="rounded-2xl bg-gray-400 w-1/3"></span>
                  <span className="rounded-2xl bg-gray-200 w-1/3"></span>
                </div>
              </div>
              <div className="flex justify-between items-center gap-4 w-full">
                <p className="text-[14px]">VibesXP</p>
                <div className="h-1 w-1/2 flex gap-1">
                  <span className="rounded-2xl bg-black w-1/3"></span>
                  <span className="rounded-2xl bg-gray-400 w-1/3"></span>
                  <span className="rounded-2xl bg-gray-200 w-1/3"></span>
                </div>
              </div>
            </div> */}

            <div className="bg-[#FFFFFF] text-[#1C1C1C] py-4 px-4 rounded-lg shadow-md flex flex-col gap-3 w-2/3 h-fit">
              <h2 className="text-sm text-[#1C1C1C] font-semibold">
                Active Inflencers
              </h2>
              <div className="flex gap-4 w-full">
                <p className="text-[14px] w-1/4">YouTube</p>
                <div className="h-2 bg-black w-2/3 flex items-center rounded-2xl mt-2"></div>
              </div>
              <div className="flex gap-4 w-full">
                <p className="text-[14px] w-1/4">Instagram</p>
                <div className="h-2 bg-black w-3/5 flex items-center rounded-2xl mt-2"></div>
              </div>
              <div className="flex gap-4 w-full">
                <p className="text-[14px] w-1/4">Facebook</p>
                <div className="h-2 bg-[#1C1C1C1A] w-2/3 flex items-center rounded-2xl mt-2"></div>
              </div>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="w-1/5 h-[90vh] flex flex-col gap-3 border-l-2 border-[#1C1C1C1A] pl-3 py-4">
          {/* Top Section - Recent Campaign */}
          <div className="h-1/2">
            <h2 className="text-sm font-medium mb-2">Recent Campaigns</h2>
            <div className="flex flex-col h-[90%] w-full overflow-y-scroll overflow-x-hidden">
              {campaign.length > 0
                ? campaign.map((item, i) => (
                    <div
                      key={i}
                      className="flex flex-col gap-1 items-center w-full rounded-md hover:bg-gray-100 text-[#000000c2] py-1 px-1 cursor-pointer"
                      onClick={() =>
                        navigate(`/campaignDetail?id=${item?._id}`)
                      }
                    >
                      <div className="flex gap-2 w-full">
                        <Avatar src={item.image} />
                        <div className="w-full flex flex-col gap-2">
                          <span>{item.name}</span>
                          <span className="text-[0.75rem] text-[#637381]">
                            {calculateTime(item.createdDate)}
                          </span>
                        </div>
                      </div>
                      <span className="border border-b-2 w-3/4"></span>
                    </div>
                  ))
                : !loadingCampaign && (
                    <p className="flex justify-center items-center h-full w-full">
                      No Campaigns to display!
                    </p>
                  )}
              {loadingCampaign && (
                <div className="py-4">
                  <Loader />
                </div>
              )}
              {!loadingCampaign && pageCampaign * 4 < totalCampaign && (
                <div className="flex justify-center">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setPageCampaign((page) => page + 1)}
                  >
                    See More
                  </Button>
                </div>
              )}
            </div>
          </div>

          {/* Bottom Section - Recent Activities */}
          <div className="h-1/2">
            <h2 className="text-sm font-medium mb-2">Recent Activities</h2>
            <div className="flex flex-col h-[90%] w-full overflow-y-scroll">
              {activity.length > 0
                ? activity.map((item, i) => (
                    <div
                      key={i}
                      className="flex flex-col gap-1 items-center w-full rounded-md hover:bg-gray-100 text-[#000000c2] py-1 px-1 cursor-pointer"
                      onClick={() =>
                        navigate(`/campaignDetail?id=${item?.campaignId}`)
                      }
                    >
                      {showMessage(item)}
                      <span className="text-[0.75rem] text-[#637381] w-full">
                        {calculateTime(item.createdAt)}
                      </span>
                      <span className="border border-b-2 w-3/4"></span>
                    </div>
                  ))
                : !loadingActitity && (
                    <p className="flex justify-center items-center h-full w-full">
                      No Activities to display!
                    </p>
                  )}
              {loadingActitity && (
                <div className="py-4">
                  <Loader />
                </div>
              )}
              {!loadingActitity && pageActivity * 3 < totalActivity && (
                <div className="flex justify-center">
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setPageActivity((page) => page + 1)}
                  >
                    See More
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default Home;
