import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { FaCloudUploadAlt } from "react-icons/fa";
import { formatBytes, getAlias } from "../../utils/formatNumber";
import Navbar from "../common/Navbar";
import InfluencerTable from "./InfluencerTable";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import useApi from "../../hooks/useApi";
import notify from "../../utils/notification";
import Footer from "../common/Footer";
import reduce from "../../utils/reduce";

const AddBulkInfluencer = () => {
  const [file, setFile] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const { makeRequest } = useApi();

  const checkFile = (selectedFile) => {
    setLoading(true);
    // Read and convert based on file extension
    if (selectedFile.type === "text/csv" || selectedFile.type === "text/tsv") {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const csvData = e.target.result;
          Papa.parse(csvData, {
            header: true,
            complete: function (results) {
              const jsonData = results.data;
              console.log("Conversion completed. JSON data:");
              console.log("Data ", jsonData, "length: ", jsonData.length);
              formatData(jsonData);
              console.log(JSON.stringify(jsonData, null, 2));
            },
          });
        };
        reader.readAsText(selectedFile);
      } catch (error) {
        console.log(error);
        notify("warning", "Couldn't generate preview for the file!");
        setRows([]);
      }
    } else if (
      selectedFile.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      try {
        const reader = new FileReader();

        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          console.log("Conversion completed. JSON data:");

          const headers = jsonData[0];
          const dataArray = jsonData.slice(1);

          const resultArray = dataArray.map((dataRow) => {
            let obj = {};
            headers.map((header, index) => {
              obj[header] = dataRow[index];
            });
            return obj;
          });

          console.log(resultArray);
          formatData(resultArray);
        };

        reader.readAsArrayBuffer(selectedFile);
      } catch (error) {
        notify("warning", "Couldn't generate preview for the file!");
        setRows([]);
        console.log(error);
      }
    } else {
      notify("error", "Unsupported File format!");
      setFile(null);
      setRows([]);
    }
    setLoading(false);
  };

  const formatData = (data) => {
    const rows = [];
    console.log("Rows: ", data);
    data.map((item) => {
      let formattedData = {
        instagram: {},
        youtube: {},
        workHistory: [],
        testimonials: [],
      };

      if (item.Name !== "" && item.Name !== undefined) {
        formattedData.name = item.Name;
        formattedData.email = item["Email(optional)"];
        formattedData.gst = item["Gst No(optional)"];
        formattedData.pan = item["Pan No(optional)"];
        formattedData.instagram.profileLink =
          item["Insta Profile Link(optional)"];
        formattedData.instagram.audience = Number(
          item["Insta Followers(optional)"]
        );
        formattedData.instagram.reach = Number(item["Insta Reach(optional)"]);
        formattedData.instagram.engagementRate = Number(
          item["Insta Engagement Rate(optional)"]
        );
        formattedData.instagram.avgLikes = Number(
          item["Insta Average Like(optional)"]
        );
        formattedData.instagram.avgViews = Number(
          item["Insta Average Views(optional)"]
        );
        formattedData.youtube.profileLink =
          item["Youtube Profile Link(optional)"];
        formattedData.youtube.audience = Number(
          item["Youtube Followers(optional)"]
        );
        formattedData.youtube.reach = Number(item["Youtube Reach(optional)"]);
        formattedData.youtube.engagementRate = Number(
          item["Youtube Engagement Rate(optional)"]
        );
        formattedData.youtube.avgLikes = Number(
          item["Youtube Average Like(optional)"]
        );
        formattedData.youtube.avgViews = Number(
          item["Youtube Average Views(optional)"]
        );

        if (item["Category(optional)"] && item["Category(optional)"] !== "") {
          formattedData.category = getAlias(item["Category(optional)"]);
        }
        if (item["Gender(optional)"] && item["Gender(optional)"] !== "") {
          formattedData.gender = getAlias(item["Gender(optional)"]);
        }
        if (item["Country(optional)"] && item["Country(optional)"] !== "") {
          formattedData.country = item["Country(optional)"];
        }
        if (item["State(optional)"] && item["State(optional)"] !== "") {
          formattedData.state = getAlias(item["State(optional)"]);
        }
        if (item["Language(optional)"] && item["Language(optional)"] !== "") {
          formattedData.language = [item["Language(optional)"]];
        }

        if (item["Phone No(optional)"] && item["Phone No(optional)"] !== "") {
          const phoneNo=item["Phone No(optional)"];
          formattedData.phoneNo = phoneNo.includes("+") ? phoneNo : "+" + phoneNo;
        }

        rows.push(formattedData);
      }
    });
    console.log("format: ", rows);
    setRows(rows);
  };

  const handleFileChange = (e) => {
    if (e.target && e.target.files) {
      const inputFile = e.target.files[0];
      setFile(inputFile);
      checkFile(inputFile);
    } else {
      alert("File upload error!");
    }
  };

  const handleDownload = () => {
    const googleSheetsLink =
      "https://docs.google.com/spreadsheets/d/1t6iRzttoSdkBPmCCjFxY383R4WsnOKLSTuoRyJFLPTI";

    const downloadLink = `${googleSheetsLink}/export?format=xlsx`;

    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the export link
    link.href = downloadLink;

    // Set the download attribute to specify the default file name
    link.download = "downloaded_google_sheet.xlsx";

    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  const addInfluencers = async () => {
    try {
      console.log("Requ Dta: ", rows);

      const res = await makeRequest("POST", "/influencers/bulk-create", rows);
      if (res) {
        notify("success", "Successfully added Influencers!");
        setRows([]);
        setFile(null);
      }
    } catch (error) {
      notify(
        "error",
        "Error while adding the influencers, check format again!"
      );
    }
  };

  useEffect(() => {
    console.log("Rows: ", rows);
  }, [rows]);

  return (
    <>
      <Navbar />
      <div className="flex flex-col gap-4 w-11/12 mx-auto">
        <div className="flex justify-between my-10 relative gap-2 items-center">
          <p className="text-2xl font-medium">Agency Influencers</p>

          <Link
            to="/addInfluencer"
            className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
          >
            ADD SINGLE INFLUENCER
          </Link>
        </div>
        <div className="w-11/12 mx-auto shadow-md px-4 py-4 mb-7 rounded-md bg-white">
          <div className="flex justify-between gap-4 py-8">
            <div className="w-1/2 flex justify-center items-center flex-col gap-2">
              {!file ? (
                <>
                  <Button
                    component="label"
                    variant="contained"
                    startIcon={<FaCloudUploadAlt />}
                    sx={{
                      bgcolor: "#2DA9B2",
                      ":hover": {
                        bgcolor: "#2da9b2d8",
                      },
                    }}
                    className="text-white rounded-md"
                  >
                    UPLOAD DATA FROM FILE
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                      accept=".csv, .tsv, .xlsx"
                    />
                  </Button>
                </>
              ) : (
                <div className="flex flex-col gap-1">
                  <p className="text-[#2DA9B2]">{file?.name}</p>
                  <div className="text-gray-500 text-sm mb-2">
                    <p>size: {formatBytes(file?.size || 0)}</p>
                  </div>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<FaCloudUploadAlt />}
                    className="text-white rounded-md"
                    sx={{
                      bgcolor: "#2DA9B2",
                      color: "#FFFFFF",
                      ":hover": {
                        bgcolor: "#2da9b2d8",
                      },
                    }}
                  >
                    UPLOAD ANOTHER FILE
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                      accept=".csv, .tsv, .xlsx"
                    />
                  </Button>
                </div>
              )}
              <p className="text-sm text-gray-600">
                .csv, .tsv or excel spreadsheet accepted
              </p>
            </div>
            <p className="text-center">
              You can upload any csv or excel file in particular format. It can
              be uploaded with any set of columns as long as it has 1 record per
              row. You can match your data in further steps and will also be
              able to edit details later.
            </p>
          </div>
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-medium">Sample Format</h2>
            <Button
              variant="contained"
              className="text-white rounded-md"
              sx={{
                bgcolor: "#2DA9B2",
                ":hover": {
                  bgcolor: "#2da9b2d8",
                },
              }}
              onClick={handleDownload}
            >
              DOWNLOAD SAMPLE .XLSX
            </Button>
          </div>
          <InfluencerTable rows={rows} />
          <div className="flex justify-end">
            <button
              type="button"
              className="m-3 bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
              onClick={addInfluencers}
            >
              CONFIRM
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AddBulkInfluencer;
