import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import logo from "../../Assets/signlogo.png";
import { formatCurrency } from "../../utils/formatNumber";
const date = new Date();

const Invoice = ({ data }) => {

  const beforeTaxAmount = (totalAmountWithTax, returnTax = false) => {
    let taxRate = 18 / 100;
    let amountBeforeTax = totalAmountWithTax / (1 + taxRate);

    if (!returnTax) return amountBeforeTax?.toFixed(2);
    else return ((amountBeforeTax * 9) / 100)?.toFixed(2);
  };

  return (
    <div className="w-full mx-auto h-fit bg-white rounded shadow-lg">
      {/* Company Info Section */}
      <div className="bg-[#2DA9B2] text-white py-6 px-6 flex justify-between items-center">
        {/* Company Logo */}
        <div>
          <div className="h-12 w-auto max-w-12 p-1 bg-white rounded-sm">
            <Avatar
              src={data?.agency?.logo ? data?.agency?.logo : logo}
              variant="rounded"
            />
          </div>
          <h2 className="text-3xl">Invoice</h2>
        </div>

        {/* Company Address and Name */}
        <div className="flex flex-col gap-4 text-right">
          <div>
            <h2 className="font-medium text-lg">{data?.agency?.name}</h2>
            <p className="text-sm">{data?.agency?.address}</p>
          </div>

          <div className="text-sm">
            {/* <p>CIN No:- Agency CIN No</p> */}
            <p>GSTIN No:- {data?.agency?.gst}</p>
          </div>
        </div>
      </div>

      {/* More company info section */}
      <div className="my-4 px-6 py-4 flex flex-col gap-1">
        <div className="flex justify-between gap-2">
          <div className="flex flex-col gap-2">
            <p className="text-sm">Invoice To:</p>
            <h2 className="font-medium text-xl">{data?.name}</h2>
            {/* <p className="text-sm">{data?.agency?.address}</p> */}
          </div>

          <div className="flex flex-col gap-2 text-sm">
            <p className="font-medium">Transaction Id</p>
            <p>
              {
                data?.paymentHistory?.[data?.paymentHistory?.length - 1]
                  ?.transactionId
              }
            </p>
            <p className="font-medium">DATE</p>
            <p>
              {data?.paymentHistory?.[data?.paymentHistory?.length - 1]?.date}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2 text-sm">
          <p>Contact Number:- {data?.phoneNo ? data?.phoneNo : "N/A"}</p>
          <p>GSTIN No:- {data?.gst ? data?.gst : "N/A"}</p>
        </div>
      </div>

      {/* Product Table Section */}
      <div className="pt-4">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="border border-black px-2 py-2">
                  Items
                </TableCell>
                <TableCell className="border border-black px-7 py-2">
                  Description
                </TableCell>
                <TableCell className="border border-black px-2 py-2">
                  SAC Code
                </TableCell>
                <TableCell className="border border-black px-5 py-2">
                  Price
                </TableCell>
                <TableCell className="border border-black px-3 py-2">
                  CGST (9%)
                </TableCell>
                <TableCell className="border border-black px-3 py-2">
                  SGST (9%)
                </TableCell>
                <TableCell className="border border-black px-5 py-2">
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Insert dynamic data for product details */}
              <TableRow>
                <TableCell className="px-2 py-2 border border-black">
                  1
                </TableCell>
                <TableCell className="px-7 py-4 border border-black !flex flex-col gap-2">
                  <p className="py-4">Influencer Marketing Services</p>
                </TableCell>
                <TableCell className="border border-black px-2 py-2">
                  998314
                </TableCell>
                <TableCell className="px-5 py-2 border border-black">
                  {formatCurrency(beforeTaxAmount(data?.paidAmount))}
                </TableCell>
                <TableCell className="px-5 py-2 border border-black">
                  {formatCurrency(beforeTaxAmount(data?.paidAmount, true))}
                </TableCell>
                <TableCell className="px-5 py-2 border border-black">
                  {formatCurrency(beforeTaxAmount(data?.paidAmount, true))}
                </TableCell>
                <TableCell className="px-5 py-2 border border-black">
                  {formatCurrency(data?.paidAmount)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Signature and Date Section */}
      <div className="flex">
        <div className="flex flex-col gap-2 w-3/4 items-center border border-black py-2 bg-[#FAD9D6] text-sm">
          <p>Digitally Signed By</p>
          <p>{data?.agency?.name}</p>
          <p>{date?.toLocaleDateString()}</p>
        </div>
        <div className="flex flex-col gap-2 w-2/5 justify-center border border-black py-2 px-4 bg-[#2DA9B2] text-white">
          <p className="text-sm">TOTAL</p>
          <p className="text-2xl font-medium">
            {formatCurrency(data?.paidAmount)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
