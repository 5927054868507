import {
  Avatar,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Loader from "../common/Loader";
import { formatNumber } from "../../utils/formatNumber";
import { customTheme } from "../../utils/theme";

const InfluencerTable = ({ rows, loading }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead style={{ background: "lightgray" }}>
            <TableRow key={"100"}>
              {rows.length > 0 ? (
                <>
                  <TableCell key={"1"}>Name</TableCell>
                  <TableCell key={"2"}>Gender</TableCell>
                  <TableCell key={"3"}>Country</TableCell>
                  <TableCell key={"6"}>State</TableCell>
                  <TableCell key={"4"}>Category</TableCell>
                  <TableCell key={"5"}>Email</TableCell>
                  <TableCell key={"7"}>Phone No</TableCell>
                  <TableCell key={"8"}>Pan No</TableCell>
                  <TableCell key={"9"}>Gst No</TableCell>
                  <TableCell key={"10"}>Language</TableCell>
                  <TableCell key={"11"}>Insta Average Likes</TableCell>
                  <TableCell key={"12"}>Insta Average Views</TableCell>
                  <TableCell key={"13"}>Insta Followers</TableCell>
                  <TableCell key={"14"}>Insta Engagement Rate</TableCell>
                  <TableCell key={"15"}>Insta Profile Link</TableCell>
                  <TableCell key={"16"}>Insta Reach</TableCell>
                  <TableCell key={"17"}>Youtube Average Likes</TableCell>
                  <TableCell key={"18"}>Youtube Average Views</TableCell>
                  <TableCell key={"19"}>Youtube Followers</TableCell>
                  <TableCell key={"21"}>Youtube Engagement Rate</TableCell>
                  <TableCell key={"20"}>Youtube Profile Link</TableCell>
                  <TableCell key={"22"}>Youtube Reach</TableCell>
                </>
              ) : (
                <>
                  <TableCell>Preview Influencers</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {!loading ? (
              rows.length > 0 ? (
                rows.map((row, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={"10px"}
                      >
                        {/* <Avatar
                          alt={row.name}
                          src={row.image}
                          variant="circle"
                        /> */}
                        <Typography sx={{ alignItems: "center" }}>
                          {row.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{row.gender}</TableCell>
                    <TableCell>{row.country}</TableCell>
                    <TableCell>{row.state}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phoneNo}</TableCell>
                    <TableCell>{row.pan}</TableCell>
                    <TableCell>{row.gst}</TableCell>
                    <TableCell>{row.language}</TableCell>
                    <TableCell>
                      {formatNumber(row.instagram?.avgViews)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(row.instagram?.avgLikes)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(row.instagram?.audience)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(row.instagram?.engagementRate)}
                    </TableCell>
                    <TableCell>{row.instagram?.profileLink}</TableCell>
                    <TableCell>{formatNumber(row.instagram?.reach)}</TableCell>
                    <TableCell>{formatNumber(row.youtube?.avgViews)}</TableCell>
                    <TableCell>{formatNumber(row.youtube?.avgLikes)}</TableCell>
                    <TableCell>{formatNumber(row.youtube?.audience)}</TableCell>
                    <TableCell>
                      {formatNumber(row.youtube?.engagementRate)}
                    </TableCell>
                    <TableCell>{row.youtube?.profileLink}</TableCell>
                    <TableCell>{formatNumber(row.youtube?.reach)}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableCell colSpan={8} height={150} align="center">
                  No influencers to display, upload a file to preview
                  influencers!
                </TableCell>
              )
            ) : (
              <TableCell colSpan={8} height={150} align="center">
                <Loader />
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ThemeProvider>
  );
};

export default InfluencerTable;
