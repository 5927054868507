import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
  Popover,
  ThemeProvider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosNotifications, IoMdHome } from "react-icons/io";
import { MdFactCheck } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import signlogo from "../../asset/signlogo.png";
import { removeUser } from "../../redux/slices/authSlice";
import { removeCampaigns } from "../../redux/slices/campaignSlice";
import useApi from "../../hooks/useApi";
import {
  calculateTime,
  capitalize,
  formatAlias,
} from "../../utils/formatNumber";
import Loader from "../common/Loader";
import { customTheme } from "../../utils/theme";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverEl, setPopoverEl] = useState(null);
  const [notification, setNotification] = useState([]);
  const [total, setTotal] = useState(-1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const { makeRequest } = useApi();
  const token = localStorage.getItem("token");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePopoverClick = (event) => {
    setPopoverEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleLogout = () => {
    dispatch(removeUser());
    dispatch(removeCampaigns());
    localStorage.removeItem("token");
    navigate("/signIn");
  };

  const getLinkClass = (path) => {
    return location.pathname === path
      ? "text-[#0000008C] bg-[#ABB3BB4D] font-medium rounded-md px-2"
      : "text-[#637381]";
  };

  const getNotifications = async (pageNumber) => {
    if (total === notification.length) {
      return;
    }
    if (pageNumber === 1) {
      setNotification([]);
    }

    setLoading(true);
    const reqData = {
      filters: {},
      sort: {
        createdAt: -1,
      },
      expand: {
        influencer: {},
        campaign: {},
      },
      pageSize: 5,
      page: pageNumber,
    };
    try {
      const res = await makeRequest("POST", "/notifications/list", reqData);
      if (res) {
        const data = res.data.data?.results;
        console.log(data);
        setNotification((prev) => [...prev, ...data]);
        setTotal(res.data.data?.total);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const showMessage = (item) => {
    switch (item.kind) {
      case "INFLUENCER_SUGGESTED":
        return (
          <span className="">
            {item.influencerIds.length} influencers Suggested in the{" "}
            {item?.campaign?.name} campaign
          </span>
        );
      case "INFLUENCER_REVIEWED":
        return (
          <span className="break-words">
            A influencer is {item.approved ? "approved" : "rejected"} in the{" "}
            {item?.campaign?.name} campaign
          </span>
        );
      case "WORK_SUBMITTED":
        return (
          <span className="word-wrap">
            {item?.influencer?.name} has submitted{" "}
            {capitalize(item.deliveryStep)} of {formatAlias(item.deliveryType)}{" "}
            in {" " + item?.campaign?.name} campaign
          </span>
        );
      case "WORK_REVIEWED":
        return (
          <span className="word-wrap">
            {capitalize(item.deliveryStep)} of {formatAlias(item.deliveryType)}{" "}
            for {item?.influencer?.name} has been{" "}
            {item.approved ? "approved" : "rejected"} by the brand in
            {" " + item?.campaign?.name} campaign
          </span>
        );
      default:
        return <></>;
    }
  };

  useEffect(() => {
    if (popoverEl) {
      getNotifications(page);
    }
  }, [popoverEl, page]);

  return (
    <ThemeProvider theme={customTheme}>
      <div className="flex justify-between items-center px-4 py-2 border shadow-md bg-white z-50 relative">
        <Link to="/">
          <img src={signlogo} className="h-9" alt="Logo" />
        </Link>
        <div className="flex gap-6">
          <p
            className={`flex items-center text-base cursor-pointer ${getLinkClass(
              "/"
            )}`}
            onClick={() => {
              navigate("/");
            }}
          >
            <IoMdHome />
            <span>Home</span>
          </p>
          <p
            className={`flex items-center text-base cursor-pointer ${getLinkClass(
              "/Onboarding"
            )}`}
            onClick={() => {
              navigate("/Onboarding");
            }}
          >
            Onboarded influencers
          </p>
          <p
            className={`flex items-center text-base cursor-pointer ${getLinkClass(
              "/influencerPlan"
            )}`}
            onClick={() => {
              navigate("/influencerPlan");
            }}
          >
            Influencer Plan
          </p>
          <p
            className={`flex gap-1 items-center text-base cursor-pointer ${getLinkClass(
              "/campaignlist"
            )}`}
            onClick={() => {
              navigate("/campaignlist");
            }}
          >
            <MdFactCheck />
            <span>Campaign</span>
          </p>
          <p
            className={`flex items-center text-base cursor-pointer ${getLinkClass(
              "/agencyPool"
            )}`}
            onClick={() => {
              navigate("/agencyPool");
            }}
          >
            Agency Pool
          </p>
        </div>
        <div className="flex gap-5 items-center">
          <button>
            <IoIosNotifications
              className="text-2xl text-[#abb3bb] outline-none border-none"
              onClick={handlePopoverClick}
            />
            <Popover
              id="notifications"
              open={Boolean(popoverEl)}
              className="bg-transparent mt-4"
              anchorEl={popoverEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="py-1 px-1 w-[330px] h-[400px] flex flex-col gap-1 overflow-y-scroll">
                <div className="flex w-full bg-[#ABB3BB4D] text-[#000000c2] py-1 px-2 font-medium rounded-md justify-center">
                  Notifications
                </div>
                {notification.length > 0
                  ? notification.map((item, i) => (
                      <div
                        key={i}
                        className="flex flex-col gap-1 w-full rounded-md bg-[#ABB3BB4D] hover:bg-[#abb3bb2b] text-[#000000c2] py-1 px-2 cursor-pointer"
                        onClick={() =>
                          navigate(`/campaignDetail?id=${item?.campaignId}`)
                        }
                      >
                        {showMessage(item)}
                        <span className="text-[0.75rem] text-[#637381] text-right">
                          {calculateTime(item.createdAt)}
                        </span>
                      </div>
                    ))
                  : !loading && (
                      <p className="flex justify-center items-center h-full w-full">
                        No notifications to display!
                      </p>
                    )}
                {loading && (
                  <div className="py-4">
                    <Loader />
                  </div>
                )}
                {!loading && page * 5 < total && (
                  <div className="flex justify-center">
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => setPage(page + 1)}
                    >
                      See More
                    </Button>
                  </div>
                )}
              </div>
            </Popover>
          </button>

          {token ? (
            <>
              <button className="outline-none" onClick={handleClick}>
                <Avatar variant="solid" sx={{ width: 30, height: 30 }} />
              </button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="mt-3"
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => navigate("/editProfile")}>
                  My account
                </MenuItem>
                <MenuItem onClick={() => navigate("/userRole")}>
                  Agent List
                </MenuItem>
                <MenuItem onClick={handleDialogOpen}>Logout</MenuItem>
                <span>
                  <Dialog
                    open={openDialog}
                    onClose={handleDialogClose}
                    aria-labelledby="responsive-dialog-title"
                  >
                    <DialogTitle id="responsive-dialog-title">
                      Logging out from Echio
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Are you sure you want to logout?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleDialogClose}>No</Button>
                      <Button onClick={handleLogout} autoFocus>
                        Yes
                      </Button>
                    </DialogActions>
                  </Dialog>
                </span>
              </Menu>
            </>
          ) : (
            <div className="flex gap-3">
              <button
                className="border uppercase text-white bg-[#2DA9B2] border-[#2DA9B2] p-1 rounded-lg px-2 font-sans"
                onClick={() => {
                  navigate("/signin");
                }}
              >
                Login
              </button>
              <button
                className="font-sans border uppercase text-white bg-[#2DA9B2] border-[#2DA9B2] p-1 rounded-lg px-2"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                SignUp
              </button>
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Navbar;
