import React, { useCallback, useState } from "react";
import Navbar from "../common/Navbar";
import { useDropzone } from "react-dropzone";
import FramePic from "../../asset/FramePic.png";
import { FaArrowLeft } from "react-icons/fa";
import {
  Checkbox,
  FormControl,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FilePreview from "./FilePreview";
import useApi from "../../hooks/useApi";
import useFileUpload from "../../hooks/useFileUpload";
import notify from "../../utils/notification";
import { useNavigate } from "react-router-dom";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import { formatAlias } from "../../utils/formatNumber";
import Footer from "../common/Footer";
import { customTheme } from "../../utils/theme";
import { ArrowDropDown } from "@mui/icons-material";

const validFileTypes = [
  "image/svg+xml",
  "image/png",
  "image/jpeg",
  "image/gif",
  "video/mp4",
  "video/webm",
  "video/ogg",
];
const maxFileSize = 3 * 1024 * 1024; // 3MB

const categories = [
  { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
  { name: "Educational", value: "EDUCATIONAL" },
  { name: "Entertainment", value: "ENTERTAINMENT" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Lifestyle", value: "LIFESTYLE" },
  { name: "Fashion", value: "FASHION" },
  { name: "Gaming", value: "GAMING" },
  { name: "Auto", value: "AUTO" },
  { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
  { name: "Other", value: "OTHER" },
];

const platforms = [
  { name: "Instagram", value: "INSTAGRAM" },
  { name: "Youtube", value: "YOUTUBE" },
];

const delTypes = [
  { name: "Instagram Stories", value: "INSTAGRAM_STORIES" },
  { name: "Instagram Posts", value: "INSTAGRAM_POSTS" },
  { name: "Instagram Reels", value: "INSTAGRAM_REELS" },
  { name: "YouTube Shorts", value: "YOUTUBE_SHORTS" },
  { name: "YouTube Dedicated Reels", value: "YOUTUBE_DEDICATED_REELS" },
  { name: "YouTube Integrated Videos", value: "YOUTUBE_INTEGRATED_VIDEOS" },
  { name: "YouTube Streams", value: "YOUTUBE_STREAMS" },
];

const CreateCampaign = () => {
  const { makeRequest } = useApi();
  const { uploadToS3 } = useFileUpload();
  const navigate = useNavigate();
  const [fileInputRef, setFileInputRef] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [productFile, setProductFiles] = useState([]);
  const [details, setDetail] = useState({
    name: "",
    description: "",
    campaignCategory: "",
    campaignType: "",
    image: "",
    nsfw: false,
    brandName: "",
    productDescription: "",
    productMedia: [],
    startDate: null,
    endDate: null,
    platform: [],
    createdDate: new Date(),
    deliveryTypes: [],
    deliveryGuidelines: "",
  });
  const [loading, setLoading] = useState(false);

  // Upload product media
  const productMediaUpload = async (file) => {
    try {
      const response = await makeRequest("GET", "/campaigns/product-media-url");
      if (response) {
        const url = response.data.data.putUrl;
        const productURL = response.data.data.getUrl;
        if (url) {
          uploadToS3(url, file);

          setDetail((prev) => ({
            ...prev,
            productMedia: [...prev.productMedia, productURL],
          }));
          notify("info", "Image Uploaded!", 2000);
        }
      }
    } catch (error) {
      console.log(error);
      notify("error", "Image Upload Failure, try again!", 2000);
    }
  };

  // Upload Campaign Image
  const uploadImage = async (file) => {
    try {
      const response = await makeRequest("GET", "/campaigns/image-url");
      if (response) {
        const url = response.data.data.putUrl;
        if (url) {
          // Extracting image url
          const imageURL = response.data.data.getUrl;

          // Uploading the file to server
          uploadToS3(url, file);

          setDetail((prev) => ({
            ...prev,
            image: imageURL,
          }));
          notify("info", "Image Uploaded!", 2000);
        }
      }
    } catch (error) {
      console.log("Error");
      notify("error", "Image Upload Failure, try again!", 2000);
      setSelectedFile(null);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      console.log("Dropped file:", file);
    }
  }, []);

  const { isDragActive } = useDropzone({
    onDrop,
    accept: validFileTypes.join(","),
    maxSize: maxFileSize,
  });

  const handleImageClick = () => {
    fileInputRef?.click();
  };

  const onCampaignImageDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      console.log("Dropped campaign image:", file);
      setSelectedFile(file);
      uploadImage(file);
    }
  }, []);

  const onProductMediaDrop = useCallback((acceptedFiles) => {
    console.log("Dropped product media files:", acceptedFiles);
    for (let file of acceptedFiles) {
      productMediaUpload(file);
    }
    setProductFiles((prev) => [...prev, ...acceptedFiles]);
  }, []);

  const {
    getRootProps: getCampaignImageRootProps,
    getInputProps: getCampaignImageInputProps,
  } = useDropzone({
    onDrop: onCampaignImageDrop,
    accept: validFileTypes
      .filter((type) => type.startsWith("image/"))
      .join(","),
    maxSize: maxFileSize,
  });

  const {
    getRootProps: getProductMediaRootProps,
    getInputProps: getProductMediaInputProps,
  } = useDropzone({
    onDrop: onProductMediaDrop,
    accept: validFileTypes.join(","),
    maxSize: maxFileSize,
    multiple: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File selected:", file);
      setSelectedFile(file);
      uploadImage(file);
    }
  };

  const handleProductMediaRemove = (removedFile) => {
    setProductFiles((prev) => prev.filter((file) => file !== removedFile));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      alert("Please upload campaign image");
      return;
    }
    if (details.startDate === null || details.endDate === null) {
      alert("Please enter start date and end date");
      return;
    }
    setLoading(true);
    details.deliveryGuidelines = [details.deliveryGuidelines];
    console.log(details);
    try {
      const response = await makeRequest("POST", "/campaigns/create", details);
      if (response) {
        console.log(response);
        notify("success", "Campaign created!");
        navigate("/campaignlist");
      }
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data?.message);
      notify("error", "Error while creating campaign, try again!");
    } finally {
      setLoading(false);
    }
  };

  const getTypes = () => {
    if (
      details.platform?.includes("INSTAGRAM") &&
      details.platform?.includes("YOUTUBE")
    ) {
      return delTypes;
    } else if (details.platform?.includes("INSTAGRAM")) {
      const types = delTypes.filter((item) => item.value.includes("INSTAGRAM"));
      return types;
    } else if (details.platform?.includes("YOUTUBE")) {
      const types = delTypes.filter((item) => item.value.includes("YOUTUBE"));
      return types;
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col gap-4 w-11/12 mx-auto">
        <div>
          <button
            className="pt-8 pb-4 flex gap-1 items-center text-[#2DA9B2]"
            onClick={() => navigate("/campaignList")}
          >
            <FaArrowLeft />
            Back
          </button>
        </div>

        <p className=" text-2xl font-medium">Create Campaign</p>

        <div className="flex">
          {/* Links for form */}
          <div className="flex flex-col gap-6 py-6 px-4 shadow-md rounded-md bg-white h-fit w-1/4">
            <ScrollLink
              to="name"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:cursor-pointer"
            >
              BASIC DETAILS
            </ScrollLink>
            <ScrollLink
              to="productDetails"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:cursor-pointer"
            >
              PRODUCT DETAILS
            </ScrollLink>
            <ScrollLink
              to="deliveryReq"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:cursor-pointer"
            >
              DELIVERY REQUIREMENTS
            </ScrollLink>
          </div>

          <ThemeProvider theme={customTheme}>
            <form
              onSubmit={handleSubmit}
              className="w-full flex flex-col gap-4 mx-4 shadow-md rounded-md py-4 px-4 mb-8 bg-white"
            >
              {/* Campaign Image upload */}
              <div className="flex items-center gap-2 mt-2">
                <h2 className="font-medium">Campagin Image</h2>
                <Tooltip title="Select image for your campaign">
                  <span>
                    <InfoIcon
                      fontSize="small"
                      sx={{
                        color: "rgba(0,0,0,0.5)",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </Tooltip>
              </div>
              <div>
                <label htmlFor="campaignImageInput" className="cursor-pointer">
                  {selectedFile ? (
                    <div className="w-full flex flex-col gap-2 my-2">
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Campaign Img"
                        onClick={handleImageClick}
                        className="w-1/3 max-w-[300px] max-h-[300px] rounded-md"
                      />
                      <p className="text-bold mt-2" onClick={handleImageClick}>
                        Click to upload new Campaign Image
                      </p>
                    </div>
                  ) : (
                    <div className="my-4">
                      <img
                        src={FramePic}
                        alt="Upload"
                        onClick={handleImageClick}
                      />
                      <p className="mt-1 font-medium">
                        Allowed *.jpg, *.jpeg, *.png, *.gif (max. 3MB)
                      </p>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="campaignImageInput"
                  name="campaignImage"
                  accept={validFileTypes
                    .filter((type) => type.startsWith("image/"))
                    .join(",")}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </div>

              <TextField
                id="name"
                name="name"
                label="Campaign Name"
                variant="outlined"
                value={details.name}
                onChange={handleChange}
                required
              />

              <TextField
                id="desc"
                name="description"
                label="Campaign Description"
                variant="outlined"
                placeholder="Campaign Description. Eg:- It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                value={details.description}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Provide in-depth details about the purpose and goals of your campaign"
                        placement="top"
                        arrow
                      >
                        <InfoIcon
                          fontSize="small"
                          sx={{
                            marginLeft: "4px",
                            color: "rgba(0,0,0,0.5)",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                required
              />

              {/*Campaign category*/}
              <FormControl required>
                <InputLabel id="categoryLabel">Campaign Category</InputLabel>
                <Select
                  labelId="categoryLabel"
                  id="category"
                  name="campaignCategory"
                  value={details.campaignCategory}
                  label="Campaign Category"
                  onChange={handleChange}
                  IconComponent={(props) => (
                    <ArrowDropDown
                      sx={{ color: "rgba(0,0,0,0.5)", mr: "30px" }}
                      {...props}
                    />
                  )}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip
                        title="Specify the category of influencers best suited for your campaign"
                        placement="top"
                        arrow
                      >
                        <InfoIcon
                          fontSize="small"
                          sx={{
                            marginLeft: "4px",
                            color: "rgba(0,0,0,0.5)",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((item, i) => (
                    <MenuItem value={item.value} key={i}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Campaign Type */}
              <FormControl required>
                <InputLabel id="campaignType">Campaign Type</InputLabel>
                <Select
                  labelId="campaignType"
                  id="type"
                  name="campaignType"
                  label="Campaign Type"
                  value={details.campaignType}
                  onChange={handleChange}
                  IconComponent={(props) => (
                    <ArrowDropDown
                      sx={{ color: "rgba(0,0,0,0.5)", mr: "30px" }}
                      {...props}
                    />
                  )}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip
                        title="Define the nature and objectives of your campaign"
                        placement="top"
                        arrow
                      >
                        <InfoIcon
                          fontSize="small"
                          sx={{
                            marginLeft: "4px",
                            color: "rgba(0,0,0,0.5)",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  <MenuItem value={"PAID"}>Paid</MenuItem>
                  <MenuItem value={"BARTER"}>Barter</MenuItem>
                </Select>
              </FormControl>

              {/* Brand Name */}
              <TextField
                id="brand"
                name="brandName"
                label="Brand Name"
                variant="outlined"
                placeholder="Brand Name"
                value={details.brandName}
                onChange={handleChange}
                required
              />

              {/* Product Media upload */}
              <div className="flex items-center gap-2 mt-2" id="productDetails">
                <h2 className="font-medium">Product images and videos</h2>
                <Tooltip title=" Showcase your product that you're giving to influencers">
                  <span>
                    <InfoIcon
                      fontSize="small"
                      sx={{
                        color: "rgba(0,0,0,0.5)",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </Tooltip>
              </div>
              <div {...getProductMediaRootProps()} style={dropzoneStyle}>
                <input {...getProductMediaInputProps()} />
                <p>
                  {isDragActive
                    ? "Drop the files here ..."
                    : "Click or drag-and-drop to upload SVG, PNG, JPG, GIF, or video (max. 3MB)"}
                </p>
                <p>SVG, PNG, JPG, or GIF (max. 3MB)</p>
              </div>

              {/* Preview for product media */}
              <h2 className="mt-2 font-medium">Product Media Preview</h2>
              <div className="flex gap-2 mb-2">
                {productFile && productFile.length > 0 ? (
                  productFile?.map((file, index) => (
                    <FilePreview
                      key={index}
                      file={file}
                      onRemove={handleProductMediaRemove}
                    />
                  ))
                ) : (
                  <p>No File to preview</p>
                )}
              </div>

              <TextField
                id="productdesc"
                name="productDescription"
                label="Product Description"
                variant="outlined"
                placeholder="Product Description."
                value={details.productDescription}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title="Describe about your product that you're giving to influencers"
                        placement="top"
                        arrow
                      >
                        <InfoIcon
                          fontSize="small"
                          sx={{
                            marginLeft: "4px",
                            color: "rgba(0,0,0,0.5)",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Platform */}
              <FormControl required>
                <InputLabel id="platformLabel">PlatForm</InputLabel>
                <Select
                  labelId="platformLabel"
                  id="platform"
                  multiple
                  name="platform"
                  value={details.platform}
                  label="Platform"
                  onChange={handleChange}
                  IconComponent={(props) => (
                    <ArrowDropDown
                      sx={{ color: "rgba(0,0,0,0.5)", mr: "30px" }}
                      {...props}
                    />
                  )}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip
                        title="Select one or multiple platforms for your campaign outreach"
                        placement="top"
                        arrow
                      >
                        <InfoIcon
                          fontSize="small"
                          sx={{
                            marginLeft: "4px",
                            color: "rgba(0,0,0,0.5)",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  }
                  renderValue={(selected) => selected.join(", ")}
                >
                  {platforms.map((item) => (
                    <MenuItem key={item.name} value={item.value}>
                      <Checkbox
                        checked={details.platform?.indexOf(item.value) > -1}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Delivery Requirement */}
              <h2 className="mt-2 font-medium" id="deliveryReq">
                Delivery Requirements
              </h2>
              <FormControl required>
                <InputLabel id="delitypesLabel">Delivery Type</InputLabel>
                <Select
                  labelId="delitypesLabel"
                  id="deliverytype"
                  multiple
                  name="deliveryTypes"
                  value={details.deliveryTypes}
                  label="Delivery Type"
                  onChange={handleChange}
                  renderValue={(selected) => formatAlias(selected)}
                  disabled={details.platform.length === 0}
                >
                  {getTypes()?.map((item) => (
                    <MenuItem key={item.name} value={item.value}>
                      <Checkbox
                        checked={
                          details.deliveryTypes?.indexOf(item.value) > -1
                        }
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id="delivery guide"
                name="deliveryGuidelines"
                label="Delivery Guidelines"
                variant="outlined"
                placeholder="Delvery Guidelines if any!"
                value={details.deliveryGuidelines}
                onChange={handleChange}
              />

              {/* Campaign Duration */}
              <div className="flex items-center gap-2 mt-2">
                <h2 className="font-medium">Campaign Duration*</h2>
                <Tooltip title="Set the timeframe for running your campaign">
                  <span>
                    <InfoIcon
                      fontSize="small"
                      sx={{
                        color: "rgba(0,0,0,0.5)",
                        cursor: "pointer",
                      }}
                    />
                  </span>
                </Tooltip>
              </div>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className="w-full flex gap-4">
                  <DatePicker
                    label="Start Date"
                    name="startDate"
                    value={details.startDate}
                    onChange={(date) =>
                      setDetail((prev) => ({ ...prev, startDate: date }))
                    }
                    className="w-1/2"
                    required
                  />
                  <DatePicker
                    label="End Date"
                    name="endDate"
                    value={details.endDate}
                    minDate={details.startDate}
                    onChange={(date) =>
                      setDetail((prev) => ({ ...prev, endDate: date }))
                    }
                    className="w-1/2"
                    required
                  />
                </div>
              </LocalizationProvider>

              <FormControl required>
                <InputLabel id="nsfwLabel">Is NSFW?</InputLabel>
                <Select
                  labelId="nsfwLabel"
                  id="nsfw"
                  name="nsfw"
                  label="Is NSFW?"
                  value={details.nsfw}
                  onChange={handleChange}
                  IconComponent={(props) => (
                    <ArrowDropDown
                      sx={{ color: "rgba(0,0,0,0.5)", mr: "30px" }}
                      {...props}
                    />
                  )}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip
                        title="Indicate if your campaign involves explicit or NSFW (Not Safe For Work) content."
                        placement="top"
                        arrow
                      >
                        <InfoIcon
                          fontSize="small"
                          sx={{
                            marginLeft: "4px",
                            color: "rgba(0,0,0,0.5)",
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </InputAdornment>
                  }
                >
                  <MenuItem value={true}>Yes</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-[#2DA9B2] text-white rounded-md px-4 m-3 h-[35px]"
                  disabled={loading}
                >
                  {!loading ? "CREATE" : "Loading..."}
                </button>
              </div>
            </form>
          </ThemeProvider>
        </div>
      </div>
      <Footer />
    </>
  );
};

const dropzoneStyle = {
  border: "2px dashed #aaaaaa",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default CreateCampaign;
