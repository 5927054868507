import React, { useEffect, useState } from "react";
import Navbar from "./common/Navbar";
import { FaSearch } from "react-icons/fa";
import {
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  PaginationItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { debounce } from "lodash";
import Footer from "./common/Footer";
import useApi from "../hooks/useApi";
import notify from "../utils/notification";
import Loader from "./common/Loader";
import { customTheme } from "../utils/theme";

const UserRole = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [email, setEmail] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    name: "",
    role: ["Admin"],
  });
  const { makeRequest } = useApi();

  const inviteUser = async () => {
    if (!email) {
      alert("Please Enter Email!");
      return;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = emailRegex.test(email);
    if (!valid) {
      alert("Invalid Email!");
      return;
    }
    setLoading(true);
    try {
      const res = await makeRequest("POST", "/agency/invite", {
        role: "Admin",
        email,
      });
      if (res) {
        notify("success", "Invite sent to Agent's mail!");
        setEmail("");
        handleDialogClose();
      }
    } catch (error) {
      console.log("Error: ", error);
      notify(
        "error",
        error?.response?.data?.message || "Error while inviting, try again!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const fetchAgents = async (pageNumber = 1) => {
    if (pageNumber === 1) {
      setRows([]);
    }
    setLoading(true);

    const request = {
      filters: filters,
      page: pageNumber,
      pageSize: rowsPerPage,
    };

    try {
      const res = await makeRequest("POST", "/agents/list-agents", request);
      if (res) {
        const result = res.data?.data?.results;
        const total = Math.ceil(res.data?.data?.total / rowsPerPage);

        setTotalPages(total);
        setRows((prev) => [...prev, ...result]);
      }
    } catch (error) {
      console.log("Error, ", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchAgents(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Call fetchAgents to fetch new data whenever there is a change in filters
  useEffect(() => {
    setLoading(true);

    const debouncedFetchAgents = debounce(() => {
      setRows([]);
      setPage(1);
      fetchAgents();
    }, 1000);

    debouncedFetchAgents();

    // Cleanup
    return () => {
      debouncedFetchAgents.cancel();
    };
  }, [filters, rowsPerPage]);

  return (
    <>
      <Navbar />
      <ThemeProvider theme={customTheme}>
        <div className="w-11/12 mx-auto my-8">
          <div className="flex justify-between gap-4 items-center">
            <p className=" text-2xl font-medium">User Role</p>
            <div className="flex border gap-3 items-center px-4 py-2 rounded-md w-1/3 text-gray-500 bg-white">
              <FaSearch />
              <input
                placeholder="Search"
                className="border-none outline-none text-black w-full"
                value={filters.name}
                onChange={(e) =>
                  setFilters((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </div>
            <div className="flex gap-3">
              <button
                className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
                onClick={handleDialogOpen}
              >
                ADD USER
              </button>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  Invite agent to join agency using email
                </DialogTitle>
                <DialogContent className="flex gap-3 flex-col">
                  <DialogContentText>
                    Enter the email for the agent
                  </DialogContentText>
                  <TextField
                    id="email"
                    type="email"
                    name="email"
                    label="Email"
                    variant="outlined"
                    placeholder="agent@echio.in"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                    size="small"
                  />
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setEmail("");
                      handleDialogClose();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button onClick={inviteUser} autoFocus disabled={loading}>
                    Invite
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <div className="h-[520px]">
            <TableContainer component={Paper} style={{ marginTop: "2rem" }}>
              <Table sx={{ minWidth: 500 }} aria-label="user role table">
                <TableHead style={{ background: "lightgray" }}>
                  <TableRow key={0}>
                    <TableCell key={1} style={{ paddingLeft: "5%" }}>
                      Name
                    </TableCell>
                    <TableCell key={2} align="center">
                      Email
                    </TableCell>
                    {/* <TableCell key={3} align="center">
                      Password
                    </TableCell>
                    <TableCell key={4} align="center">
                      Gender
                    </TableCell> */}
                    <TableCell key={5} align="center">
                      Role
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {rows.length > 0
                    ? rows.map((row) => (
                        <>
                          <TableRow key={row._id}>
                            <TableCell align="center">
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={"10px"}
                                paddingLeft={"5%"}
                              >
                                <Avatar
                                  alt={row.name}
                                  src={row.image}
                                  variant="circle"
                                />
                                <Typography>{row.name}</Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="center">{row.email}</TableCell>
                            {/* <TableCell align="center">{row.password}</TableCell>
                            <TableCell align="center">{row.gender}</TableCell> */}
                            <TableCell align="center">
                              {row.role === "Admin" ? (
                                <Chip
                                  label="Admin"
                                  size="small"
                                  style={{
                                    background: "#14BC10",
                                    color: "#fff",
                                  }}
                                />
                              ) : row.role === "BrandManager" ? (
                                <Chip
                                  label="Brand Manager"
                                  size="small"
                                  style={{
                                    background: "#6B8DE6",
                                    color: "#fff",
                                  }}
                                />
                              ) : row.role === "InfluencerManager" ? (
                                <Chip
                                  label="Influencer Marketing Manager"
                                  size="small"
                                  style={{
                                    background: "#0288D1",
                                    color: "#fff",
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    : !loading && (
                        <TableCell colSpan={5} height={200} align="center">
                          No users to display!
                        </TableCell>
                      )}

                  {loading && (
                    <TableCell colSpan={8} align="center">
                      <Loader />
                    </TableCell>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {rows?.length > 0 && (
            <div className="flex justify-center">
              <Stack spacing={2} direction="row" alignItems="center">
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  renderItem={(item) => (
                    <PaginationItem
                      slots={{
                        previous: ArrowBackIcon,
                        next: ArrowForwardIcon,
                      }}
                      {...item}
                    />
                  )}
                />
                <TablePagination
                  component="div"
                  count={totalPages}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelDisplayedRows={() => null}
                  rowsPerPageOptions={[5, 10, 15]}
                  slotProps={{
                    actions: {
                      nextButton: {
                        style: {
                          display: "none",
                        },
                      },
                      previousButton: {
                        style: {
                          display: "none",
                        },
                      },
                    },
                  }}
                />
              </Stack>
            </div>
          )}
        </div>
      </ThemeProvider>
      <Footer />
    </>
  );
};

export default UserRole;
