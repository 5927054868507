import React from "react";
import { Line } from "react-chartjs-2";

const lineChartData = {
  labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
  datasets: [
    {
      data: [10000, 15000, 22000, 18000, 23000, 20000, 9000],
      fill: false,
      borderColor: "rgba(0, 0, 0, 0.8)",
      borderWidth: 2,
      backgroundColor: "rgba(0, 0, 0, 0)",
      pointBackgroundColor: "rgba(0, 0, 0, 0)",
      pointRadius: 0,
    },
  ],
};

const lineChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
  },
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        maxRotation: 0,
        minRotation: 0,
      },
    },
    y: {
      grid: {
        display: false,
      },
      beginAtZero: true,
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
  },
  interaction: {
    mode: "index",
  },
};

const LineChart = () => {
  return (
    <>
      <Line data={lineChartData} options={lineChartOptions} className="!h-44" />
    </>
  );
};

export default LineChart;
