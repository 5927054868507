import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";
import { Box, Button, Chip, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import useApi from "../../hooks/useApi";
import { SidebarLoader } from "./SidebarLoader";
import { ThemeProvider } from "styled-components";
import { customTheme } from "../../utils/theme";

const deliveryMapper = {
  INSTAGRAM_REELS: "reel",
  INSTAGRAM_STORIES: "stories",
  INSTAGRAM_POSTS: "post",
  YOUTUBE_SHORTS: "shorts",
  YOUTUBE_INTEGRATED_VIDEOS: "integratedVideo",
  YOUTUBE_DEDICATED_REELS: "dedicatedReel",
  YOUTUBE_STREAMS: "streaming",
};

function BrandDeliverySubPanel({
  workId,
  campaignId,
  influencerId,
  deliveryType,
  step,
  platform,
  token,
}) {
  const { makeRequest } = useApi();
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [denyBox, setDenyBox] = React.useState(false);
  const [reviewMessage, setReviewMessage] = React.useState("");

  const fetchWork = async () => {
    let endpoint = "/works/list";
    let filters = {
      filters: {
        campaignId: campaignId,
        influencerId: [influencerId],
      },
      expand: {
        influencer: {},
        campaign: {},
      },
      pageSize: 1,
      page: 1,
    };
    try {
      setLoading(true);
      const res = await makeRequest("POST", endpoint, filters);
      setData(res.data.data.results[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchWork();
  }, []);

  const toggleDenyBox = () => {
    setDenyBox(!denyBox);
  };

  const handleApproveWork = async () => {
    try {
      setLoading(true);
      let endpoint = "/form/brand/review-work";
      let body = {
        campaignId: campaignId,
        influencerId: influencerId,
        approve: {
          [platform]: {
            [deliveryMapper[deliveryType]]: true,
          },
        },
        reviewMessage: "",
      };

      const res = await makeRequest("POST", endpoint, body, token);
      await fetchWork();
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDenyBoxCancel = () => {
    setDenyBox(false);
    setReviewMessage("");
  };

  const handleDenyBoxSend = async () => {
    try {
      setLoading(true);
      let endpoint = "/form/brand/review-work";
      let body = {
        campaignId: campaignId,
        influencerId: influencerId,
        approve: {
          [platform]: {
            [deliveryMapper[deliveryType]]: false,
          },
        },
        reviewMessage: reviewMessage,
      };

      const res = await makeRequest("POST", endpoint, body, token);
      await fetchWork();
      setDenyBox(false);
      setReviewMessage("");
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  function ScriptPanel() {
    let delType = deliveryMapper[deliveryType];

    return (
      <>
        {platform === "instagram" && (
          <>
            {data.scripts.status.instagram[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Script not submitted yet
                </Typography>
                <Typography variant="body1" sx={{ pt: 2, color: "#5A5D66" }}>
                  Please wait for the agency to submit the script.
                </Typography>
              </div>
            )}
            {data.scripts.status.instagram[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.instagram[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>

                {!denyBox && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginLeft: "0",
                      }}
                      onClick={toggleDenyBox}
                    >
                      Deny &nbsp; <CloseOutlinedIcon />
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginRight: "0",
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF"
                      }}
                      onClick={handleApproveWork}
                    >
                      Approve &nbsp; <CheckOutlinedIcon />
                    </Button>
                  </div>
                )}
                {denyBox && (
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ fontSize: "small" }}>
                      <TextField
                        id="outlined-textarea"
                        label="Enter revision details"
                        placeholder="Enter revision details"
                        value={reviewMessage}
                        multiline
                        rows={4}
                        style={{ width: "100%" }}
                        onChange={(e) => setReviewMessage(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginLeft: "0",
                        }}
                        onClick={handleDenyBoxCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginRight: "0",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF"
                        }}
                        onClick={handleDenyBoxSend}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
            {data.scripts.status.instagram[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.instagram[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <Chip
                  label={"Approved by you"}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.scripts.status.instagram[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.instagram[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by you"}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                    Revision Details
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.scripts.reviewMessage.instagram[delType]}
                  </Typography>
                </div>
              </>
            )}
          </>
        )}
        {platform === "youtube" && (
          <>
            {data.scripts.status.youtube[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Script not submitted yet
                </Typography>
                <Typography variant="body1" sx={{ pt: 2, color: "#5A5D66" }}>
                  Please wait for the agency to submit the script.
                </Typography>
              </div>
            )}
            {data.scripts.status.youtube[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.youtube[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>

                {!denyBox && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginLeft: "0",
                      }}
                      onClick={toggleDenyBox}
                    >
                      Deny &nbsp; <CloseOutlinedIcon />
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginRight: "0",
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF"
                      }}
                      onClick={handleApproveWork}
                    >
                      Approve &nbsp; <CheckOutlinedIcon />
                    </Button>
                  </div>
                )}
                {denyBox && (
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ fontSize: "small" }}>
                      <TextField
                        id="outlined-textarea"
                        label="Enter revision details"
                        placeholder="Enter revision details"
                        value={reviewMessage}
                        multiline
                        rows={4}
                        style={{ width: "100%" }}
                        onChange={(e) => setReviewMessage(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginLeft: "0",
                        }}
                        onClick={handleDenyBoxCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginRight: "0",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF"
                        }}
                        onClick={handleDenyBoxSend}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
            {data.scripts.status.youtube[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.youtube[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <Chip
                  label={"Approved by you"}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.scripts.status.youtube[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.youtube[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by you"}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                    Revision Details
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.scripts.reviewMessage.youtube[delType]}
                  </Typography>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  function DeliverablePanel() {
    let delType = deliveryMapper[deliveryType];

    return (
      <>
        {platform === "instagram" && (
          <>
            {data.deliverables.status.instagram[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Deliverables not submitted yet
                </Typography>
                <Typography variant="body1" sx={{ pt: 2, color: "#5A5D66" }}>
                  Please wait for the agency to submit the deliverables.
                </Typography>
              </div>
            )}
            {data.deliverables.status.instagram[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.instagram[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.instagram[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                {!denyBox && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginLeft: "0",
                      }}
                      onClick={toggleDenyBox}
                    >
                      Deny &nbsp; <CloseOutlinedIcon />
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginRight: "0",
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF"
                      }}
                      onClick={handleApproveWork}
                    >
                      Approve &nbsp; <CheckOutlinedIcon />
                    </Button>
                  </div>
                )}
                {denyBox && (
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ fontSize: "small" }}>
                      <TextField
                        id="outlined-textarea"
                        label="Enter revision details"
                        placeholder="Enter revision details"
                        value={reviewMessage}
                        multiline
                        rows={4}
                        style={{ width: "100%" }}
                        onChange={(e) => setReviewMessage(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginLeft: "0",
                        }}
                        onClick={handleDenyBoxCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginRight: "0",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF"
                        }}
                        onClick={handleDenyBoxSend}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
            {data.deliverables.status.instagram[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.instagram[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.instagram[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <Chip
                  label="Approved by you"
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.deliverables.status.instagram[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.instagram[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.instagram[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by you"}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                    Revision Details
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.deliverables.reviewMessage.instagram[delType]}
                  </Typography>
                </div>
              </>
            )}
          </>
        )}
        {platform === "youtube" && (
          <>
            {data.deliverables.status.youtube[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Deliverables not submitted yet
                </Typography>
                <Typography variant="body1" sx={{ pt: 2, color: "#5A5D66" }}>
                  Please wait for the agency to submit the deliverables.
                </Typography>
              </div>
            )}
            {data.deliverables.status.youtube[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.youtube[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.youtube[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                {!denyBox && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginLeft: "0",
                      }}
                      onClick={toggleDenyBox}
                    >
                      Deny &nbsp; <CloseOutlinedIcon />
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginRight: "0",
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF"
                      }}
                      onClick={handleApproveWork}
                    >
                      Approve &nbsp; <CheckOutlinedIcon />
                    </Button>
                  </div>
                )}
                {denyBox && (
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ fontSize: "small" }}>
                      <TextField
                        id="outlined-textarea"
                        label="Enter revision details"
                        placeholder="Enter revision details"
                        value={reviewMessage}
                        multiline
                        rows={4}
                        style={{ width: "100%" }}
                        onChange={(e) => setReviewMessage(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginLeft: "0",
                        }}
                        onClick={handleDenyBoxCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginRight: "0",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF"
                        }}
                        onClick={handleDenyBoxSend}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
            {data.deliverables.status.youtube[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.youtube[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.youtube[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <Chip
                  label={"Approved by you"}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.deliverables.status.youtube[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.youtube[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.youtube[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by you"}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                    Revision Details
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.deliverables.reviewMessage.youtube[delType]}
                  </Typography>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  function LiveLinkPanel() {
    let delType = deliveryMapper[deliveryType];

    return (
      <>
        {platform === "instagram" && (
          <>
            {data.liveLinks.status.instagram[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Live Links not submitted yet
                </Typography>
                <Typography variant="body1" sx={{ pt: 2, color: "#5A5D66" }}>
                  Please wait for the agency to submit the live links.
                </Typography>
              </div>
            )}
            {data.liveLinks.status.instagram[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <InstagramIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.instagram[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.instagram[delType]?.length > 100 ? data.liveLinks.content.instagram[delType]?.substring(0,100)+"..." : data.liveLinks.content.instagram[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                {!denyBox && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginLeft: "0",
                      }}
                      onClick={toggleDenyBox}
                    >
                      Deny &nbsp; <CloseOutlinedIcon />
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginRight: "0",
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF"
                      }}
                      onClick={handleApproveWork}
                    >
                      Approve &nbsp; <CheckOutlinedIcon />
                    </Button>
                  </div>
                )}
                {denyBox && (
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ fontSize: "small" }}>
                      <TextField
                        id="outlined-textarea"
                        label="Enter revision details"
                        placeholder="Enter revision details"
                        value={reviewMessage}
                        multiline
                        rows={4}
                        style={{ width: "100%" }}
                        onChange={(e) => setReviewMessage(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginLeft: "0",
                        }}
                        onClick={handleDenyBoxCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginRight: "0",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF"
                        }}
                        onClick={handleDenyBoxSend}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
            {data.liveLinks.status.instagram[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <InstagramIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.instagram[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.instagram[delType]?.length > 100 ? data.liveLinks.content.instagram[delType]?.substring(0,100)+"..." : data.liveLinks.content.instagram[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <Chip
                  label={"Approved by you"}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.liveLinks.status.instagram[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <InstagramIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.instagram[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.instagram[delType]?.length > 100 ? data.liveLinks.content.instagram[delType]?.substring(0,100)+"..." : data.liveLinks.content.instagram[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by you"}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                    Revision
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.liveLinks.reviewMessage.instagram[delType]}
                  </Typography>
                </div>
              </>
            )}
          </>
        )}
        {platform === "youtube" && (
          <>
            {data.liveLinks.status.youtube[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Live Links not submitted yet
                </Typography>
                <Typography variant="body1" sx={{ pt: 2, color: "#5A5D66" }}>
                  Please wait for the agency to submit the live links.
                </Typography>
              </div>
            )}
            {data.liveLinks.status.youtube[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <YoutubeIcon style={{ color: "gray" }} fontSize="small" />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.youtube[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.youtube[delType]?.length > 100 ? data.liveLinks.content.youtube[delType]?.substring(0,100)+"..." : data.liveLinks.content.youtube[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                {!denyBox && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="error"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginLeft: "0",
                      }}
                      onClick={toggleDenyBox}
                    >
                      Deny &nbsp; <CloseOutlinedIcon />
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        width: "100%",
                        margin: "5px",
                        marginRight: "0",
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF"
                      }}
                      onClick={handleApproveWork}
                    >
                      Approve &nbsp; <CheckOutlinedIcon />
                    </Button>
                  </div>
                )}
                {denyBox && (
                  <div style={{ marginTop: "15px" }}>
                    <div style={{ fontSize: "small" }}>
                      <TextField
                        id="outlined-textarea"
                        label="Enter revision details"
                        placeholder="Enter revision details"
                        value={reviewMessage}
                        multiline
                        rows={4}
                        style={{ width: "100%" }}
                        onChange={(e) => setReviewMessage(e.target.value)}
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginLeft: "0",
                        }}
                        onClick={handleDenyBoxCancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          margin: "5px",
                          marginRight: "0",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF"
                        }}
                        onClick={handleDenyBoxSend}
                      >
                        Send
                      </Button>
                    </div>
                  </div>
                )}
              </>
            )}
            {data.liveLinks.status.youtube[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <YoutubeIcon style={{ color: "gray" }} fontSize="small" />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.youtube[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.youtube[delType]?.length > 100 ? data.liveLinks.content.youtube[delType]?.substring(0,100)+"..." : data.liveLinks.content.youtube[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <Chip
                  label={"Approved by you"}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.liveLinks.status.youtube[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <YoutubeIcon style={{ color: "gray" }} fontSize="small" />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.youtube[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.youtube[delType]?.length > 100 ? data.liveLinks.content.youtube[delType]?.substring(0,100)+"..." : data.liveLinks.content.youtube[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by you"}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                    Revision
                  </Typography>
                  <Typography variant="body1" sx={{ color: "#5A5D66" }}>
                    {data.liveLinks.reviewMessage.youtube[delType]}
                  </Typography>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <ThemeProvider theme={customTheme}>
      <div>
        {loading ? (
          <div style={{ marginTop: "30%" }}>
            <SidebarLoader />
          </div>
        ) : data ? (
          <Box sx={{ pl: 3, pr: 3 }}>
            {step === "script" && <ScriptPanel />}
            {step === "deliverables" && <DeliverablePanel />}
            {step === "livelinks" && <LiveLinkPanel />}
          </Box>
        ) : (
          <div style={{ marginTop: "30%" }}>
            <SidebarLoader />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default BrandDeliverySubPanel;
