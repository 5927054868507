import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  campaigns: null,
  currentCampaign: null,
  ongoingWorks: null,
  sharedWorks: null,
  shortlistedWorks: null,
  sidebarData: null,
  brandSidebarData: null,
  campaignsLoading: false,
  currentCampaignLoading: false,
  worksLoading: false,
  error: null
}

const campaignSlice = createSlice({
  name: "campaign",
  initialState: initialState,
  reducers: {
    setCampaignsLoading: (state, action) => {
      return {
        ...state,
        campaignsLoading: true,
        error: null
      }
    },
    setCurrentCampaignLoading: (state, action) => {
      return {
        ...state,
        currentCampaignLoading: true,
        error: null
      }
    },
    setCampaigns: (state, action) => {
      return {
        ...state,
        campaigns: action.payload,
        currentCampaign: null,
        ongoingWorks: null,
        sharedWorks: null,
        shortlistedWorks: null,
        sidebarData: null,
        brandSidebarData: null,
        campaignsLoading: false,
        currentCampaignLoading: false,
        worksLoading: false,
        error: null
      }
    },
    setCurrentCampaign: (state, action) => {
      return {
        ...state,
        currentCampaign: action.payload,        
        sidebarData: null,
        brandSidebarData: null,
        currentCampaignLoading: false,
        error: null
      }
    },
    setOngoingWorks: (state, action) => {
      return {
        ...state,
        ongoingWorks: action.payload,
        sidebarData: null,
        brandSidebarData: null,
        worksLoading: false,
        error: null
      }
    },
    setSharedWorks: (state, action) => {
        return {
            ...state,
            sharedWorks: action.payload,
            sidebarData: null,
            brandSidebarData: null,
            worksLoading: false,
            error: null
        }
    },
    setShortlistedWorks: (state, action) => {
      return {
        ...state,
        shortlistedWorks: action.payload,
        sidebarData: null,
        brandSidebarData: null,
        worksLoading: false,
        error: null
      }
    },
    setSidebarData: (state, action) => {
      return {
        ...state,
        sidebarData: action.payload,
        error: null
      }
    },
    setBrandSidebarData: (state, action) => {
      return {
        ...state,
        brandSidebarData: action.payload,
        error: null
      }
    },
    removeCampaigns: (state, action) => {
      return {
        ...state,
        campaigns: null,
        currentCampaign: null,
        ongoingWorks: null,
        sharedWorks: null,
        shortlistedWorks: null,
        sidebarData: null,
        brandSidebarData: null,
        campaignsLoading: false,
        currentCampaignLoading: false,
        worksLoading: false,
        error: null
      }
    }
  }
})

export const { setCampaigns, setCurrentCampaign, setOngoingWorks, setSidebarData, setBrandSidebarData, setSharedWorks, setShortlistedWorks, removeCampaigns, setCampaignsLoading, setCurrentCampaignLoading } = campaignSlice.actions

export default campaignSlice.reducer