import DescriptionIcon from "@mui/icons-material/Description";
import DownloadForOfflineOutlinedIcon from "@mui/icons-material/DownloadForOfflineOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkIcon from "@mui/icons-material/Link";
import YoutubeIcon from "@mui/icons-material/YouTube";
import {
  Box,
  Button,
  Chip,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useApi from "../../hooks/useApi";
import useFileUpload from "../../hooks/useFileUpload";
import { SidebarLoader } from "./SidebarLoader";
import notify from "../../utils/notification";
import { customTheme } from "../../utils/theme";

const deliveryMapper = {
  INSTAGRAM_REELS: "reel",
  INSTAGRAM_STORIES: "stories",
  INSTAGRAM_POSTS: "post",
  YOUTUBE_SHORTS: "shorts",
  YOUTUBE_INTEGRATED_VIDEOS: "integratedVideo",
  YOUTUBE_DEDICATED_REELS: "dedicatedReel",
  YOUTUBE_STREAMS: "streaming",
};

function DeliverySubPanel({
  workId,
  campaignId,
  influencerId,
  deliveryType,
  step,
  platform,
}) {
  const { makeRequest } = useApi();
  const { uploadToS3 } = useFileUpload();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [scriptFile, setScriptFile] = useState(null);
  const [deliverableFile, setDeliverableFile] = useState(null);
  const [showUploader, setShowUploader] = useState(false);

  const fetchWork = async () => {
    let endpoint = "/works/list";
    let filters = {
      filters: {
        campaignId: campaignId,
        influencerId: [influencerId],
      },
      expand: {
        influencer: {},
        campaign: {},
      },
      pageSize: 1,
      page: 1,
    };
    try {
      setLoading(true);
      const res = await makeRequest("POST", endpoint, filters);
      setData(res.data.data.results[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchWork();
    setShowUploader(false);
  }, []);

  const handleShowUpload = () => {
    setShowUploader(!showUploader);
  };

  const handleCancelUpload = () => {
    setShowUploader(false);
    setScriptFile(null);
    setDeliverableFile(null);
  };

  const handleScriptFileChange = (file) => {
    if (!file) return;
    setScriptFile(file);
  };

  const handleDeliverableFileChange = (file) => {
    if (!file) return;
    setDeliverableFile(file);
  };

  const uploadTOAWS = async (url, file) => {
    uploadToS3(url, file);
    return true;
  };

  const handleScriptUpload = async () => {
    if (!scriptFile) return;
    try {
      setLoading(true);
      const awsres = await makeRequest("GET", "/works/deliverable-url");
      await uploadTOAWS(awsres.data.data.putUrl, scriptFile);

      let endpoint = "/works/submit";
      let body = {
        campaignId: campaignId,
        influencerId: influencerId,
        scripts: {
          [platform]: {
            [deliveryMapper[deliveryType]]: awsres.data.data.getUrl,
          },
        },
      };

      const res = await makeRequest("POST", endpoint, body);
      await fetchWork();
      notify("success", "Script uploaded!");
      setShowUploader(false);
      setScriptFile(null);
      setLoading(false);
    } catch (error) {
      console.log(error);
      notify("error", "Error uploading script, try again!");
      setLoading(false);
    }
  };

  const handleDeliverableUpload = async (deliverableCaption) => {
    if (!deliverableFile) return;
    try {
      setLoading(true);
      const awsres = await makeRequest("GET", "/works/deliverable-url");
      await uploadTOAWS(awsres.data.data.putUrl, deliverableFile);

      let endpoint = "/works/submit";
      let body = {
        campaignId: campaignId,
        influencerId: influencerId,
        deliverables: {
          [platform]: {
            [deliveryMapper[deliveryType]]: awsres.data.data.getUrl,
          },
        },
        deliverablesCaptions: {
          [platform]: {
            [deliveryMapper[deliveryType]]: deliverableCaption,
          },
        },
      };

      const res = await makeRequest("POST", endpoint, body);
      await fetchWork();
      notify("success", "Deliverables uploaded!");
      setShowUploader(false);
      setScriptFile(null);
      setLoading(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      notify("error", "Error uploading deliverables, try again!");
      setLoading(false);
    }
  };

  const handleLiveLinkUpload = async (livelinkurl) => {
    if (!livelinkurl) return;
    try {
      setLoading(true);

      let endpoint = "/works/submit";
      let body = {
        campaignId: campaignId,
        influencerId: influencerId,
        liveLinks: {
          [platform]: {
            [deliveryMapper[deliveryType]]: livelinkurl,
          },
        },
      };

      const res = await makeRequest("POST", endpoint, body);
      await fetchWork();
      notify("success", "Link uploaded!");
      setShowUploader(false);
      setScriptFile(null);
      setLoading(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      notify("error", "Error uploading link, try again!");
      setLoading(false);
    }
  };

  function ScriptPanel() {
    let delType = deliveryMapper[deliveryType];

    return (
      <>
        {platform === "instagram" && (
          <>
            {data.scripts.status.instagram[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Submit script
                </Typography>
                <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                  Supported file formats PDF only (max. 3MB)
                </Typography>
                {!showUploader && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      color: "#2DA9B2",
                      "&:hover": {
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleShowUpload}
                  >
                    Upload file <FileUploadOutlinedIcon sx={{ pl: 1 }} />
                  </Button>
                )}

                {showUploader && (
                  <div>
                    <input
                      accept=".pdf"
                      type="file"
                      id="scriptfile"
                      style={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#FFFFFF",
                        zIndex: 2,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        handleScriptFileChange(e.target.files[0]);
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        pb: 2,
                        color: "#2DA9B2",
                        textAlign: "center",
                        mt: -4,
                        zIndex: 0,
                      }}
                    >
                      {scriptFile ? scriptFile.name : "Click to select file"}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          width: "100%",
                          height: "40px",
                          mt: 2,
                        }}
                        onClick={handleCancelUpload}
                      >
                        Cancel
                      </Button>
                      <div>&emsp;</div>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#2da9b2d8",
                            color: "#FFFFFF",
                          },
                          mt: 2,
                        }}
                        onClick={handleScriptUpload}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}

            {data.scripts.status.instagram[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.instagram[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <Chip
                  label="Submitted"
                  sx={{ mt: 2, backgroundColor: "#2DA9B2", color: "#FFFFFF" }}
                />
              </>
            )}

            {data.scripts.status.instagram[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.instagram[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <Chip
                  label={"Approved by brand " + data.campaign.brandName}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.scripts.status.instagram[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.instagram[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by " + data.campaign.brandName}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "red" }}
                  >
                    Revision
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.scripts.reviewMessage.instagram[delType]}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                  >
                    Submit script (Revised)
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    Supported file formats PDF only (max. 3MB)
                  </Typography>
                  {!showUploader && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#2DA9B2",
                        "&:hover": {
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                        },
                      }}
                      onClick={handleShowUpload}
                    >
                      Upload file <FileUploadOutlinedIcon sx={{ pl: 1 }} />
                    </Button>
                  )}

                  {showUploader && (
                    <div>
                      <input
                        accept=".pdf"
                        type="file"
                        id="scriptfile"
                        style={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#FFFFFF",
                          color: "#FFFFFF",
                          zIndex: 2,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        onChange={(e) => {
                          console.log(e.target.files[0]);
                          handleScriptFileChange(e.target.files[0]);
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          pb: 2,
                          color: "#2DA9B2",
                          textAlign: "center",
                          mt: -4,
                          zIndex: 0,
                        }}
                      >
                        {scriptFile ? scriptFile.name : "Click to select file"}
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            width: "100%",
                            height: "40px",
                            mt: 2,
                          }}
                          onClick={handleCancelUpload}
                        >
                          Cancel
                        </Button>
                        <div>&emsp;</div>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: "#2DA9B2",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#2da9b2d8",
                              color: "#FFFFFF",
                            },
                            mt: 2,
                          }}
                          onClick={handleScriptUpload}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {platform === "youtube" && (
          <>
            {data.scripts.status.youtube[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Submit script
                </Typography>
                <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                  Supported file formats PDF only (max. 3MB)
                </Typography>
                {!showUploader && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      color: "#2DA9B2",
                      "&:hover": {
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleShowUpload}
                  >
                    Upload file <FileUploadOutlinedIcon sx={{ pl: 1 }} />
                  </Button>
                )}

                {showUploader && (
                  <div>
                    <input
                      accept=".pdf"
                      type="file"
                      id="scriptfile"
                      style={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#FFFFFF",
                        zIndex: 2,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        handleScriptFileChange(e.target.files[0]);
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        pb: 2,
                        color: "#2DA9B2",
                        textAlign: "center",
                        mt: -4,
                        zIndex: 0,
                      }}
                    >
                      {scriptFile ? scriptFile.name : "Click to select file"}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          width: "100%",
                          height: "40px",
                          mt: 2,
                        }}
                        onClick={handleCancelUpload}
                      >
                        Cancel
                      </Button>
                      <div>&emsp;</div>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#2da9b2d8",
                            color: "#FFFFFF",
                          },
                          mt: 2,
                        }}
                        onClick={handleScriptUpload}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {data.scripts.status.youtube[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.youtube[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <Chip
                  label="Submitted"
                  sx={{ mt: 2, backgroundColor: "#2DA9B2", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.scripts.status.youtube[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.youtube[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <Chip
                  label={"Approved by brand " + data.campaign.brandName}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.scripts.status.youtube[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography variant="body2">
                      <DescriptionIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;Script.pdf
                    </Typography>
                    <DownloadForOfflineOutlinedIcon
                      style={{ color: "#005b8f", cursor: "pointer" }}
                      onClick={() => {
                        window.open(
                          data.scripts.content.youtube[delType],
                          "_blank"
                        );
                      }}
                    />
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by " + data.campaign.brandName}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "red" }}
                  >
                    Revision
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.scripts.reviewMessage.youtube[delType]}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                  >
                    Submit script (Revised)
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    Supported file formats PDF only (max. 3MB)
                  </Typography>
                  {!showUploader && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#2DA9B2",
                        "&:hover": {
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                        },
                      }}
                      onClick={handleShowUpload}
                    >
                      Upload file <FileUploadOutlinedIcon sx={{ pl: 1 }} />
                    </Button>
                  )}

                  {showUploader && (
                    <div>
                      <input
                        accept=".pdf"
                        type="file"
                        id="scriptfile"
                        style={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#FFFFFF",
                          color: "#FFFFFF",
                          zIndex: 2,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        onChange={(e) => {
                          console.log(e.target.files[0]);
                          handleScriptFileChange(e.target.files[0]);
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          pb: 2,
                          color: "#2DA9B2",
                          textAlign: "center",
                          mt: -4,
                          zIndex: 0,
                        }}
                      >
                        {scriptFile ? scriptFile.name : "Click to select file"}
                      </Typography>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            width: "100%",
                            height: "40px",
                            mt: 2,
                          }}
                          onClick={handleCancelUpload}
                        >
                          Cancel
                        </Button>
                        <div>&emsp;</div>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: "#2DA9B2",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#2da9b2d8",
                              color: "#FFFFFF",
                            },
                            mt: 2,
                          }}
                          onClick={handleScriptUpload}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  function DeliverablePanel() {
    let delType = deliveryMapper[deliveryType];
    const [deliverableCaption, setDeliverableCaption] = useState("");
    const handleDeliverableCaptionChange = (e) => {
      setDeliverableCaption(e.target.value);
    };

    return (
      <>
        {platform === "instagram" && (
          <>
            {data.deliverables.status.instagram[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Submit deliverable
                </Typography>
                <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                  Supported file formats MP4 only (max. 20MB)
                </Typography>
                {!showUploader && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      color: "#2DA9B2",
                      "&:hover": {
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleShowUpload}
                  >
                    Upload file <FileUploadOutlinedIcon sx={{ pl: 1 }} />
                  </Button>
                )}

                {showUploader && (
                  <div>
                    <input
                      accept=".mp4"
                      type="file"
                      id="scriptfile"
                      style={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#FFFFFF",
                        zIndex: 2,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        handleDeliverableFileChange(e.target.files[0]);
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        pb: 2,
                        color: "#2DA9B2",
                        textAlign: "center",
                        mt: -4,
                        zIndex: 0,
                      }}
                    >
                      {deliverableFile
                        ? deliverableFile.name
                        : "Click to select file"}
                    </Typography>

                    <TextField
                      id="outlined-multiline-static"
                      label="Caption"
                      multiline
                      rows={4}
                      value={deliverableCaption}
                      onChange={handleDeliverableCaptionChange}
                      required
                      sx={{ width: "100%", mt: 2 }}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          width: "100%",
                          height: "40px",
                          mt: 2,
                        }}
                        onClick={handleCancelUpload}
                      >
                        Cancel
                      </Button>
                      <div>&emsp;</div>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#2da9b2d8",
                            color: "#FFFFFF",
                          },
                          mt: 2,
                        }}
                        onClick={() =>
                          handleDeliverableUpload(deliverableCaption)
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {data.deliverables.status.instagram[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.instagram[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.instagram[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <Chip
                  label="Submitted"
                  sx={{ mt: 2, backgroundColor: "#2DA9B2", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.deliverables.status.instagram[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.instagram[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.instagram[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <Chip
                  label={"Approved by brand " + data.campaign.brandName}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.deliverables.status.instagram[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.instagram[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.instagram[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by " + data.campaign.brandName}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "red" }}
                  >
                    Revision
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.deliverables.reviewMessage.instagram[delType]}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                  >
                    Submit deliverable (Revised)
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    Supported file formats MP4 only (max. 20MB)
                  </Typography>
                  {!showUploader && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#2DA9B2",
                        "&:hover": {
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                        },
                      }}
                      onClick={handleShowUpload}
                    >
                      Upload file <FileUploadOutlinedIcon sx={{ pl: 1 }} />
                    </Button>
                  )}

                  {showUploader && (
                    <div>
                      <input
                        accept=".mp4"
                        type="file"
                        id="scriptfile"
                        style={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#FFFFFF",
                          color: "#FFFFFF",
                          zIndex: 2,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        onChange={(e) => {
                          console.log(e.target.files[0]);
                          handleDeliverableFileChange(e.target.files[0]);
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          pb: 2,
                          color: "#2DA9B2",
                          textAlign: "center",
                          mt: -4,
                          zIndex: 0,
                        }}
                      >
                        {deliverableFile
                          ? deliverableFile.name
                          : "Click to select file"}
                      </Typography>

                      <TextField
                        id="outlined-multiline-static"
                        label="Caption"
                        multiline
                        rows={4}
                        // defaultValue={deliverableCaption}
                        value={deliverableCaption}
                        onChange={handleDeliverableCaptionChange}
                        required
                        sx={{ width: "100%", mt: 2 }}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            width: "100%",
                            height: "40px",
                            mt: 2,
                          }}
                          onClick={handleCancelUpload}
                        >
                          Cancel
                        </Button>
                        <div>&emsp;</div>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: "#2DA9B2",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#2DA9B2",
                              color: "#FFFFFF",
                            },
                            mt: 2,
                          }}
                          onClick={() =>
                            handleDeliverableUpload(deliverableCaption)
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {platform === "youtube" && (
          <>
            {data.deliverables.status.youtube[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Submit deliverable
                </Typography>
                <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                  Supported file formats MP4 only (max. 20MB)
                </Typography>
                {!showUploader && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      color: "#2DA9B2",
                      "&:hover": {
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleShowUpload}
                  >
                    Upload file <FileUploadOutlinedIcon sx={{ pl: 1 }} />
                  </Button>
                )}

                {showUploader && (
                  <div>
                    <input
                      accept=".mp4"
                      type="file"
                      id="scriptfile"
                      style={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#FFFFFF",
                        zIndex: 2,
                        opacity: 0,
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        console.log(e.target.files[0]);
                        handleDeliverableFileChange(e.target.files[0]);
                      }}
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        pb: 2,
                        color: "#2DA9B2",
                        textAlign: "center",
                        mt: -4,
                        zIndex: 0,
                      }}
                    >
                      {deliverableFile
                        ? deliverableFile.name
                        : "Click to select file"}
                    </Typography>

                    <TextField
                      id="outlined-multiline-static"
                      label="Caption"
                      multiline
                      rows={4}
                      // defaultValue={deliverableCaption}
                      value={deliverableCaption}
                      onChange={handleDeliverableCaptionChange}
                      required
                      sx={{ width: "100%", mt: 2 }}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          width: "100%",
                          height: "40px",
                          mt: 2,
                        }}
                        onClick={handleCancelUpload}
                      >
                        Cancel
                      </Button>
                      <div>&emsp;</div>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#2da9b2d8",
                            color: "#FFFFFF",
                          },
                          mt: 2,
                        }}
                        onClick={() =>
                          handleDeliverableUpload(deliverableCaption)
                        }
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {data.deliverables.status.youtube[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.youtube[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.youtube[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <Chip
                  label="Submitted"
                  sx={{ mt: 2, backgroundColor: "#2DA9B2", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.deliverables.status.youtube[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.youtube[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.youtube[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <Chip
                  label={"Approved by brand " + data.campaign.brandName}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.deliverables.status.youtube[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <video
                      src={data.deliverables.content.youtube[delType]}
                      width="auto"
                      // height="40"
                      controls
                      style={{
                        borderRadius: "5px",
                        width: "auto",
                        height: "300px",
                      }}
                    />

                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: "5px",
                        padding: "5px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                        Caption (or) Description
                      </Typography>
                      <Typography variant="body2" sx={{ mt: "15px" }}>
                        {data.deliverables.captions.youtube[delType]}
                      </Typography>
                    </div>
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by " + data.campaign.brandName}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "red" }}
                  >
                    Revision
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.deliverables.reviewMessage.youtube[delType]}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                  >
                    Submit deliverable (Revised)
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    Supported file formats MP4 only (max. 20MB)
                  </Typography>
                  {!showUploader && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#2DA9B2",
                        "&:hover": {
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                        },
                      }}
                      onClick={handleShowUpload}
                    >
                      Upload file <FileUploadOutlinedIcon sx={{ pl: 1 }} />
                    </Button>
                  )}

                  {showUploader && (
                    <div>
                      <input
                        accept=".mp4"
                        type="file"
                        id="scriptfile"
                        style={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#FFFFFF",
                          color: "#FFFFFF",
                          zIndex: 2,
                          opacity: 0,
                          cursor: "pointer",
                        }}
                        onChange={(e) => {
                          console.log(e.target.files[0]);
                          handleDeliverableFileChange(e.target.files[0]);
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          pb: 2,
                          color: "#2DA9B2",
                          textAlign: "center",
                          mt: -4,
                          zIndex: 0,
                        }}
                      >
                        {deliverableFile
                          ? deliverableFile.name
                          : "Click to select file"}
                      </Typography>

                      <TextField
                        id="outlined-multiline-static"
                        label="Caption"
                        multiline
                        rows={4}
                        // defaultValue={deliverableCaption}
                        value={deliverableCaption}
                        onChange={handleDeliverableCaptionChange}
                        required
                        sx={{ width: "100%", mt: 2 }}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            width: "100%",
                            height: "40px",
                            mt: 2,
                          }}
                          onClick={handleCancelUpload}
                        >
                          Cancel
                        </Button>
                        <div>&emsp;</div>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: "#2DA9B2",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#2DA9B2",
                              color: "#FFFFFF",
                            },
                            mt: 2,
                          }}
                          onClick={() =>
                            handleDeliverableUpload(deliverableCaption)
                          }
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  function LiveLinkPanel() {
    let delType = deliveryMapper[deliveryType];
    const [livelinkurl, setLivelinkurl] = useState("");

    const handleLiveLinkUrlChange = (e) => {
      setLivelinkurl(e.target.value);
    };

    return (
      <>
        {platform === "instagram" && (
          <>
            {data.liveLinks.status.instagram[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Submit Live Link
                </Typography>

                {!showUploader && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      color: "#2DA9B2",
                      marginTop: "5px",
                      "&:hover": {
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleShowUpload}
                  >
                    Add URL <LinkIcon sx={{ pl: 1 }} />
                  </Button>
                )}

                {showUploader && (
                  <div>
                    <TextField
                      id="outlined-multiline-static"
                      label="Paste link here"
                      value={livelinkurl}
                      onChange={handleLiveLinkUrlChange}
                      required
                      sx={{ width: "100%", mt: 2 }}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          width: "100%",
                          height: "40px",
                          mt: 2,
                        }}
                        onClick={handleCancelUpload}
                      >
                        Cancel
                      </Button>
                      <div>&emsp;</div>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#2da9b2d8",
                            color: "#FFFFFF",
                          },
                          mt: 2,
                        }}
                        onClick={() => handleLiveLinkUpload(livelinkurl)}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {data.liveLinks.status.instagram[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <InstagramIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.instagram[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.instagram[delType]?.length > 100 ? data.liveLinks.content.instagram[delType]?.substring(0,100)+"..." : data.liveLinks.content.instagram[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <Chip
                  label="Submitted"
                  sx={{ mt: 2, backgroundColor: "#2DA9B2", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.liveLinks.status.instagram[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <InstagramIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.instagram[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.instagram[delType]?.length > 100 ? data.liveLinks.content.instagram[delType]?.substring(0,100)+"..." : data.liveLinks.content.instagram[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <Chip
                  label={"Approved by brand " + data.campaign.brandName}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.liveLinks.status.instagram[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <InstagramIcon
                        style={{ color: "gray" }}
                        fontSize="small"
                      />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.instagram[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.instagram[delType]?.length > 100 ? data.liveLinks.content.instagram[delType]?.substring(0,100)+"..." : data.liveLinks.content.instagram[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by " + data.campaign.brandName}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "red" }}
                  >
                    Revision
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.liveLinks.reviewMessage.instagram[delType]}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                  >
                    Submit Live Link (Revised)
                  </Typography>
                  {!showUploader && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#2DA9B2",
                        marginTop: "5px",
                        "&:hover": {
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                        },
                      }}
                      onClick={handleShowUpload}
                    >
                      Add URL <LinkIcon sx={{ pl: 1 }} />
                    </Button>
                  )}

                  {showUploader && (
                    <div>
                      <TextField
                        id="outlined-multiline-static"
                        label="Paste link here"
                        value={livelinkurl}
                        onChange={handleLiveLinkUrlChange}
                        required
                        sx={{ width: "100%", mt: 2 }}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            width: "100%",
                            height: "40px",
                            mt: 2,
                          }}
                          onClick={handleCancelUpload}
                        >
                          Cancel
                        </Button>
                        <div>&emsp;</div>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: "#2DA9B2",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#2DA9B2",
                              color: "#FFFFFF",
                            },
                            mt: 2,
                          }}
                          onClick={() => handleLiveLinkUpload(livelinkurl)}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
        {platform === "youtube" && (
          <>
            {data.liveLinks.status.youtube[delType] === "UNSUBMITTED" && (
              <div
                style={{
                  backgroundColor: "#F2F3F5",
                  width: "100%",
                  borderRadius: "7px",
                  padding: "15px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                >
                  Submit Live Link
                </Typography>

                {!showUploader && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      height: "40px",
                      backgroundColor: "#FFFFFF",
                      color: "#2DA9B2",
                      marginTop: "5px",
                      "&:hover": {
                        backgroundColor: "#2DA9B2",
                        color: "#FFFFFF",
                      },
                    }}
                    onClick={handleShowUpload}
                  >
                    Add URL <LinkIcon sx={{ pl: 1 }} />
                  </Button>
                )}

                {showUploader && (
                  <div>
                    <TextField
                      id="outlined-multiline-static"
                      label="Paste link here"
                      value={livelinkurl}
                      onChange={handleLiveLinkUrlChange}
                      required
                      sx={{ width: "100%", mt: 2 }}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        sx={{
                          width: "100%",
                          height: "40px",
                          mt: 2,
                        }}
                        onClick={handleCancelUpload}
                      >
                        Cancel
                      </Button>
                      <div>&emsp;</div>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          height: "40px",
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                          "&:hover": {
                            backgroundColor: "#2da9b2d8",
                            color: "#FFFFFF",
                          },
                          mt: 2,
                        }}
                        onClick={() => handleLiveLinkUpload(livelinkurl)}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {data.liveLinks.status.youtube[delType] === "SUBMITTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <YoutubeIcon style={{ color: "gray" }} fontSize="small" />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.youtube[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.youtube[delType]?.length > 100 ? data.liveLinks.content.youtube[delType]?.substring(0,100)+"..." : data.liveLinks.content.youtube[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <Chip
                  label="Submitted"
                  sx={{ mt: 2, backgroundColor: "#2DA9B2", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.liveLinks.status.youtube[delType] === "ACCEPTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <YoutubeIcon style={{ color: "gray" }} fontSize="small" />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.youtube[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.youtube[delType]?.length > 100 ? data.liveLinks.content.youtube[delType]?.substring(0,100)+"..." : data.liveLinks.content.youtube[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <Chip
                  label={"Approved by brand " + data.campaign.brandName}
                  sx={{ mt: 2, backgroundColor: "green", color: "#FFFFFF" }}
                />
              </>
            )}
            {data.liveLinks.status.youtube[delType] === "REJECTED" && (
              <>
                <div
                  style={{
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "40px",
                      width: "100%",
                      backgroundColor: "white",
                      borderRadius: "5px",
                      padding: "5px",
                      pl: "10px",
                      pr: "10px",
                    }}
                  >
                    <Typography
                      variant="body2"
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                      }}
                    >
                      <YoutubeIcon style={{ color: "gray" }} fontSize="small" />
                      &emsp;
                      <Typography
                        variant="link"
                        sx={{ cursor: "pointer", color: "#2DA9B2" }}
                        onClick={() => {
                          window.open(
                            data.liveLinks.content.youtube[delType],
                            "_blank"
                          );
                        }}
                      >
                        {data.liveLinks.content.youtube[delType]?.length > 100 ? data.liveLinks.content.youtube[delType]?.substring(0,100)+"..." : data.liveLinks.content.youtube[delType]}
                      </Typography>
                    </Typography>
                  </Box>
                </div>
                <div>
                  <Chip
                    label={"Rejected by " + data.campaign.brandName}
                    sx={{ mt: 2, backgroundColor: "red", color: "#FFFFFF" }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", color: "red" }}
                  >
                    Revision
                  </Typography>
                  <Typography variant="body1" sx={{ pb: 2, color: "#5A5D66" }}>
                    {data.liveLinks.reviewMessage.youtube[delType]}
                  </Typography>
                </div>

                <div
                  style={{
                    marginTop: "15px",
                    backgroundColor: "#F2F3F5",
                    width: "100%",
                    borderRadius: "7px",
                    padding: "15px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", fontSize: "1.25rem" }}
                  >
                    Submit Live Link (Revised)
                  </Typography>
                  {!showUploader && (
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        height: "40px",
                        backgroundColor: "#FFFFFF",
                        color: "#2DA9B2",
                        marginTop: "5px",
                        "&:hover": {
                          backgroundColor: "#2DA9B2",
                          color: "#FFFFFF",
                        },
                      }}
                      onClick={handleShowUpload}
                    >
                      Add URL <LinkIcon sx={{ pl: 1 }} />
                    </Button>
                  )}

                  {showUploader && (
                    <div>
                      <TextField
                        id="outlined-multiline-static"
                        label="Paste link here"
                        value={livelinkurl}
                        onChange={handleLiveLinkUrlChange}
                        required
                        sx={{ width: "100%", mt: 2 }}
                      />

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          variant="outlined"
                          color="error"
                          sx={{
                            width: "100%",
                            height: "40px",
                            mt: 2,
                          }}
                          onClick={handleCancelUpload}
                        >
                          Cancel
                        </Button>
                        <div>&emsp;</div>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            height: "40px",
                            backgroundColor: "#2DA9B2",
                            color: "#FFFFFF",
                            "&:hover": {
                              backgroundColor: "#2DA9B2",
                              color: "#FFFFFF",
                            },
                            mt: 2,
                          }}
                          onClick={() => handleLiveLinkUpload(livelinkurl)}
                        >
                          Submit
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }

  return (
    <ThemeProvider theme={customTheme}>
      <div>
        {loading ? (
          <div style={{ marginTop: "30%" }}>
            <SidebarLoader />
          </div>
        ) : data ? (
          <Box sx={{ pl: 3, pr: 3 }}>
            {step === "script" && <ScriptPanel />}
            {step === "deliverables" && <DeliverablePanel />}
            {step === "livelinks" && <LiveLinkPanel />}
          </Box>
        ) : (
          <div style={{ marginTop: "30%" }}>
            <SidebarLoader />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
}

export default DeliverySubPanel;
