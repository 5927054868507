import { createSlice } from "@reduxjs/toolkit";

const  initialState = {
    jwt: null,
    user: null,
    loading: false,
    error: null,
}

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      return{
        ...state,
        jwt: action.payload.jwt,
        user: action.payload.user,
        loading: false,
        error: null
      }
    },
    removeUser: (state, action) => {
      return{
        ...state,
        jwt: null,
        user: null,
        loading: false,
        error: null
      }
    }
  }
})

export const { setUser, removeUser } = authSlice.actions
export default authSlice.reducer