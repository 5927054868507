import axios from "axios";

const useFileUpload = () => {
  const uploadToS3 = (url, file) => {
    // Send the POST request to AWS S3
    axios
      .put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .then((awsResponse) => {
        console.log("File upload success!", awsResponse);
        return true;
      })
      .catch((error) => {
        console.error("Error uploading file to S3:", error);
        return false;
      });
  };
  return { uploadToS3 };
};

export default useFileUpload;
