import "noty/lib/noty.css";
import "noty/lib/themes/metroui.css";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AgencyPool from "./component/AgencyPool";
import BrandCampaignDetail from "./component/Campaign/BrandCampaignDetail";
import CampaignDetail from "./component/Campaign/CampaignDetail";
import CampaignInfluencerList from "./component/Campaign/CampaignInfluencerList";
import CampaignList from "./component/Campaign/CampaignList";
import Createcampaign from "./component/Campaign/Createcampaign";
import Home from "./component/Dashboard/Home";
import AddBulkInfluencer from "./component/Influencer/AddBulkInfluencer";
import AddInfluencer from "./component/Influencer/AddInfluencer";
import CreatePlan from "./component/Influencer/CreatePlan";
import Influencer from "./component/Influencer/Influencer";
import InfluencerPlanDetail from "./component/Influencer/InfluencerPlanDetail";
import InfluencerList from "./component/Influencer/InfluencerList";
import InfluencerOnboard from "./component/Influencer/InfluencerOnboard";
import Onboarding from "./component/Onboarding/Onboarding";
import Payment from "./component/Payment/Payment";
import SignIn from "./component/Auth/SignIn";
import SignUp from "./component/Auth/SignUp";
import BrandDeliverySidebar from "./component/common/BrandDeliverySidebar";
import DeliverySidebar from "./component/common/DeliverySidebar";
import UserRole from "./component/UserRole";
import EditProfile from "./component/EditProfile";
import SuccessPage from "./component/common/SuccessPage";
import AgentSignUp from "./component/Auth/AgentSignUp";

const App = () => {
  return (
    <>
      <DeliverySidebar />
      <BrandDeliverySidebar />
      <Routes>
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/agent/signup" element={<AgentSignUp />} />
        <Route path="/" element={<Home />} />
        <Route path="/influencerPlan" element={<Influencer />} />
        <Route path="/addInfluencer" element={<AddInfluencer />} />
        <Route path="/bulkInfluencer" element={<AddBulkInfluencer />} />
        <Route path="/campaignlist" element={<CampaignList />} />
        <Route path="/influencerList" element={<InfluencerList />} />
        <Route
          path="/campaignInfluencerList"
          element={<CampaignInfluencerList />}
        />
        <Route path="/influencerDetail" element={<InfluencerPlanDetail />} />
        <Route path="/createcampaign" element={<Createcampaign />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/createplan" element={<CreatePlan />} />
        <Route path="/brand/campaignDetail" element={<BrandCampaignDetail />} />
        <Route path="/campaignDetail" element={<CampaignDetail />} />
        <Route path="/onboard" element={<InfluencerOnboard />} />
        <Route path="/Onboarding" element={<Onboarding />} />
        <Route path="/agencyPool" element={<AgencyPool />} />
        <Route path="/userRole" element={<UserRole />} />
        <Route path="/editProfile" element={<EditProfile />} />
        <Route path="/onboard/success" element={<SuccessPage />} />
      </Routes>
    </>
  );
};

export default App;
