import React from "react";
import Navbar from "../common/Navbar";
import { FaSearch } from "react-icons/fa";
import PayTable from "./PayTable";
import { Link, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import Footer from "../common/Footer";

const Payment = () => {
  const location = useLocation();
  const campaignId = new URLSearchParams(location.search).get("id");

  return (
    <>
      <Navbar />
      <div className="w-11/12 my-10 mx-auto">
        <div className="flex justify-between items-center">
          <p className="text-2xl font-medium">Campaign Payment Detail</p>
          <div className="flex border gap-3 items-center px-4 py-2 rounded-md w-1/3 text-gray-500 bg-white">
            <FaSearch />
            <input
              placeholder="Search"
              className="border-none outline-none text-black w-full"
            />
          </div>
          <div></div>
        </div>

        <div className="my-4">
          <Link
            to={"/campaignDetail?id=" + campaignId}
            className=" text-[#2DA9B2] flex gap-1 items-center"
          >
            <IoMdArrowRoundBack /> Back
          </Link>
        </div>

        <div className="flex flex-col gap-4 justify-between min-h-[70vh]">
          <PayTable campaignId={campaignId} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Payment;
