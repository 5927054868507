import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Checkbox, TextField, ThemeProvider } from "@mui/material";
import signlogoImage from "../../asset/signlogo.png";
import authImage from "../../Assets/auth.jpg";
import { setUser } from "../../redux/slices/authSlice";
import notification from "../../utils/notification";
import { customTheme } from "../../utils/theme";
import Design from "./Design";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignIn = (e) => {
    e.preventDefault();
    // Check if email and password are provided
    if (!email || !password) {
      notification("error", "Email and password are required");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = emailRegex.test(email);
    if (!valid) {
      notification("error", "Invalid email format!");
      return;
    }
    setLoading(true);

    axios
      .post("https://agencyapi.getmentore.com/agent/auth/login", {
        email,
        password,
      })
      .then((res) => {
        notification("success", "Login Successful!");
        console.log(res.data.data.jwt);
        localStorage.setItem("token", res.data.data.jwt);
        dispatch(setUser(res.data.data));
        navigate("/");
        setLoading(false);
      })
      .catch((error) => {
        notification(
          "error",
          error?.response?.data?.message || "Incorrect Email or Password!"
        );
        setLoading(false);
        console.error("Error:", error.message);
      });
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <div className="flex justify-center items-center h-screen">
        <form
          className="flex w-5/6 rounded-md overflow-hidden"
          onSubmit={handleSignIn}
        >
          <Design />

          <div className="w-1/2 flex flex-col justify-center lg:px-8 px-4 py-2 gap-4 bg-white">
            <div className="flex gap-1 items-center">
              <img src={signlogoImage} alt="Echio Logo" className="w-8 h-8" />
              <span className="text-2xl font-medium text-gray-600">Echio</span>
            </div>

            <div className="my-2">
              <p className="text-xl">Sign In</p>
              <p className="text-sm text-gray-600">to access Echio Home</p>
            </div>

            <TextField
              id="email"
              type="email"
              label="Email"
              variant="outlined"
              placeholder="john@gmail.com"
              value={email}
              required
              fullWidth
              size="small"
              onChange={(e) => setEmail(e.target.value)}
            />

            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              type="password"
              placeholder="**************"
              value={password}
              required
              fullWidth
              size="small"
              onChange={(e) => setPassword(e.target.value)}
            />

            <div className="text-sm">
              <label className="flex items-center">
                <Checkbox
                  inputProps={{ "aria-label": "controlled" }}
                  required
                />
                Yes, I understand and agree to the ECHIO Service.
              </label>
            </div>

            <button
              type="submit"
              className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white mt-2 py-2 rounded-md"
              disabled={loading}
            >
              {!loading ? "SIGN IN" : "Loading..."}
            </button>

            <div className="text-sm flex items-center flex-col gap-2">
              <p>
                New to ECHIO?{" "}
                <a
                  href="/signup"
                  className="text-[#2DA9B2] hover:text-[#2da9b2d8] font-bold"
                >
                  Create Your account
                </a>
              </p>
              <p>
                Already a member of agency?{" "}
                <a
                  href="/agent/signup"
                  className="text-[#2DA9B2] hover:text-[#2da9b2d8] font-bold"
                >
                  Create Your Agent account
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default SignIn;
