import React from "react";
import Instagram from "../../Assets/instagram.png";
import Model from "../../Assets/model.png";
import Smile from "../../Assets/smile.png";
import Wow from "../../Assets/wow.png";
import Heart from "../../Assets/heart.png";
import Youtube from "../../Assets/Youtube.svg";

const Design = () => {
  return (
    <>
      <div className="w-[618px] h-[578px] bg-[#2DA9B2] rounded-l-xl overflow-hidden">
        <div className="relative top-[4.5rem] left-[26.5rem] bg-white w-max rounded-lg z-40">
          <img
            src={Model}
            className="rounded-xl shadow-md p-1.5 w-[120px] h-[120px] object-cover"
            width={140}
            height={130}
            alt="model"
          />
        </div>
        <div className="rounded-lg shadow-lg relative bottom-[2.2rem] left-[22.5rem] w-[237px] h-[249px] z-30 bg-gradient">
          <p className="flex items-center justify-center text-white mt-[60px] pt-[80px] font-semibold leading-[25px] w-[600] h-[25px]">
            Selena Thomas
          </p>

          <div className="absolute flex justify-center items-center flex-col gap-2 h-[114px] w-[186px] xl:top-[105px] xl:left-7 rounded-xl bg-white">
            <p className="flex items-center justify-center font-medium text-sm text-[#404040] ">
              ENGAGEMENT
            </p>
            <p className="flex items-center justify-center xl:text-[30px] lg:text-[24px] text-[#2DA9B2] font-bold">
              100%
            </p>
          </div>
        </div>
        <div className="rounded-lg relative bottom-[20.8rem] left-[6rem] w-[204px] h-[48px] z-20 bg-gradient shadow-lg">
          <p className="text-white font-medium flex justify-center items-center h-full">
            MAKES YOU VIRAL
          </p>
        </div>
        <div className="bg-white rounded-2xl relative bottom-[20.5rem] left-[2rem] max-w-[346px] h-[115px] z-40">
          <div className="absolute">
            <img
              src={Instagram}
              className="absolute top-[1.5rem] left-[8.5rem] w-[28px] h-[28px]"
              width={28}
              height={28}
              alt="instagram"
            />
            <p className="font-semibold text-[#282828] relative top-[1.5rem] left-[10.5rem]">
              Instagram
            </p>
          </div>
          <div className="absolute top-[3.55rem] left-[8.5rem] h-[8px] w-[10rem] bg-[rgba(243,245,250,1)] rounded-lg "></div>
          <div className="absolute top-[4.5rem] left-[8.5rem] w-[8rem] h-[8px] bg-[rgba(243,245,250,1)] rounded-lg "></div>
          <p className="text-[#333333] relative text-sm top-[1.6rem] left-[17rem] font-medium">
            Follow
          </p>

          <div className="absolute flex justify-center items-center top-[1.5rem] left-[1.25rem] w-[105px] h-[71px] bg-[#F3F5FA] rounded-lg xl:text-[40px] lg:text-[34px] font-semibold text-[#191124]">
            1M
          </div>
        </div>
        <div className="bg-white rounded-xl relative  bottom-[19rem] left-[8rem] w-[139px] h-[65px] z-20 flex justify-center items-center">
          <div className="flex gap-1 ">
            <img
              src={Smile}
              className="order-0 w-[30px] h-[30px] mt-[1px]"
              width={30}
              height={28}
              alt=""
            />
            <img
              src={Wow}
              className="order-1 w-[30px] h-[30px] ml-[2px]"
              width={30}
              height={25}
              alt=""
            />
            <img
              src={Heart}
              className="order-2 w-[33px] h-[33px]"
              width={35}
              height={34}
              alt=""
            />
          </div>
        </div>

        <div className="bg-white rounded-xl relative bottom-[17rem] left-[2.5rem] w-[380px] h-[113px] z-20">
          <div className="absolute">
            <img
              src={Youtube}
              className="absolute xl:top-[1.25rem] xl:left-[9.5rem] top-[1rem] left-[8rem]"
              alt="youtube"
              width={24}
              height={30}
            />

            <p className="text-[#282828] font-bold tracking-tighter text-base relative xl:top-[20px] xl:left-[11.25rem] top-[16px] left-[9.75rem] ">
              YouTube
            </p>
          </div>
          <div className="absolute xl:top-[3.25rem] xl:left-[9.5rem] top-[3rem] left-[8rem] xl:h-[8px] xl:w-[190px] h-[6px] w-[170px] bg-[rgba(243,245,250,1)] rounded-lg "></div>
          <div className="absolute xl:top-[4.25rem] xl:left-[9.5rem] top-[4rem] left-[8rem] xl:w-[130px] xl:h-[8px] w-[120px] h-[6px] bg-[rgba(243,245,250,1)] rounded-lg "></div>
          <p className="font-medium text-[#333333] absolute text-sm xl:top-[1.35rem] xl:left-[16rem] top-[1rem] left-[14rem]">
            SUBSCRIBERS
          </p>

          <div className="absolute flex justify-center items-center xl:top-[1.25rem] xl:left-[1rem] xl:w-[125px] xl:h-[75px] top-[1rem] left-[1rem] w-[105px] h-[63px] bg-[#F3F5FA] rounded-lg xl:text-[40px] lg:text-[34px] font-semibold">
            800K
          </div>
        </div>
      </div>
    </>
  );
};

export default Design;
