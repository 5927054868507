import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../common/Navbar";
import { useDropzone } from "react-dropzone";
import FramePic from "../../asset/FramePic.png";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import useApi from "../../hooks/useApi";
import useFileUpload from "../../hooks/useFileUpload";
import notify from "../../utils/notification";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MuiTelInput } from "mui-tel-input";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import reduce from "../../utils/reduce";
import { StarOutlineSharp } from "@mui/icons-material";
import Footer from "../common/Footer";
import { customTheme } from "../../utils/theme";

const validFileTypes = [
  "image/svg+xml",
  "image/png",
  "image/jpeg",
  "image/gif",
];
const maxFileSize = 3 * 1024 * 1024; // 3MB

const categories = [
  { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
  { name: "Educational", value: "EDUCATIONAL" },
  { name: "Entertainment", value: "ENTERTAINMENT" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Lifestyle", value: "LIFESTYLE" },
  { name: "Fashion", value: "FASHION" },
  { name: "Gaming", value: "GAMING" },
  { name: "Auto", value: "AUTO" },
  { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
  { name: "Other", value: "OTHER" },
];

const languages = [
  { name: "English", value: "English" },
  { name: "Hindi", value: "Hindi" },
];

const states = [
  { name: "Andhra Pradesh", value: "ANDHRA_PRADESH" },
  { name: "Arunachal Pradesh", value: "ARUNACHAL_PRADESH" },
  { name: "Assam", value: "ASSAM" },
  { name: "Bihar", value: "BIHAR" },
  { name: "Chhattisgarh", value: "CHHATTISGARH" },
  { name: "Goa", value: "GOA" },
  { name: "Gujarat", value: "GUJARAT" },
  { name: "Haryana", value: "HARYANA" },
  { name: "Himachal Pradesh", value: "HIMACHAL_PRADESH" },
  { name: "Jharkhand", value: "JHARKHAND" },
  { name: "Karnataka", value: "KARNATAKA" },
  { name: "Kerala", value: "KERALA" },
  { name: "Madhya Pradesh", value: "MADHYA_PRADESH" },
  { name: "Maharashtra", value: "MAHARASHTRA" },
  { name: "Manipur", value: "MANIPUR" },
  { name: "Meghalaya", value: "MEGHALAYA" },
  { name: "Mizoram", value: "MIZORAM" },
  { name: "Nagaland", value: "NAGALAND" },
  { name: "Odisha", value: "ODISHA" },
  { name: "Punjab", value: "PUNJAB" },
  { name: "Rajasthan", value: "RAJASTHAN" },
  { name: "Sikkim", value: "SIKKIM" },
  { name: "Tamil Nadu", value: "TAMIL_NADU" },
  { name: "Telangana", value: "TELANGANA" },
  { name: "Tripura", value: "TRIPURA" },
  { name: "Uttarakhand", value: "UTTARAKHAND" },
  { name: "Uttar Pradesh", value: "UTTAR_PRADESH" },
  { name: "West Bengal", value: "WEST_BENGAL" },
  { name: "Andaman and Nicobar Islands", value: "ANDAMAN_AND_NICOBAR_ISLANDS" },
  { name: "Chandigarh", value: "CHANDIGARH" },
  {
    name: "Dadra and Nagar Haveli and Daman and Diu",
    value: "DADRA_AND_NAGAR_HAVELI_AND_DAMAN_AND_DIU",
  },
  {
    name: "The Government of NCT of Delhi",
    value: "THE_GOVERNMENT_OF_NCT_OF_DELHI",
  },
  { name: "Jammu and Kashmir", value: "JAMMU_AND_KASHMIR" },
  { name: "Ladakh", value: "LADAKH" },
  { name: "Lakshadweep", value: "LAKSHADWEEP" },
  { name: "Puducherry", value: "PUDUCHERRY" },
];

const AddInfluencer = () => {
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const { makeRequest } = useApi();
  const { uploadToS3 } = useFileUpload();
  const navigate = useNavigate();
  const [fileInputRef, setFileInputRef] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  let [details, setDetail] = useState({
    name: "",
    email: "",
    phoneNo: "",
    image: "",
    bio: "",
    category: "",
    gender: "",
    language: [],
    gst: "",
    pan: "",
    state: "",
    city: "",
    country: "",
    instagram: {
      profileLink: "",
      audience: 0,
      reach: 0,
      engagementRate: 0,
      avgLikes: 0,
      avgViews: 0,
      price: {
        reel: 0,
        post: 0,
        stories: 0,
      },
    },
    youtube: {
      profileLink: "",
      audience: 0,
      reach: 0,
      engagementRate: 0,
      avgLikes: 0,
      avgViews: 0,
      price: {
        dedicatedReel: 0,
        integratedVideo: 0,
        streaming: 0,
        shorts: 0,
      },
    },
    workHistory: [],
    testimonials: [],
  });

  // Upload influencer Image
  const uploadImage = async (file) => {
    try {
      const response = await makeRequest("GET", "/influencers/image-url");
      if (response) {
        const url = response.data.data.putUrl;
        if (url) {
          // Extracting image url
          const imageURL = response.data.data.getUrl;

          // Uploading the file to server
          uploadToS3(url, file);

          setDetail((prev) => ({
            ...prev,
            image: imageURL,
          }));
          notify("info", "Image Uploaded!", 2000);
        }
      }
    } catch (error) {
      console.log("Error");
      notify("error", "Image Upload Failure, try again!", 2000);
      setSelectedFile(null);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      console.log("Dropped file:", file);
    }
  }, []);

  const { isDragActive } = useDropzone({
    onDrop,
    accept: validFileTypes.join(","),
    maxSize: maxFileSize,
  });

  const handleImageClick = () => {
    fileInputRef?.click();
  };

  const onInfluencerImageDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      console.log("Dropped campaign image:", file);
      setSelectedFile(file);
      uploadImage(file);
    }
  }, []);

  const {
    getRootProps: getInfluencerImageRootProps,
    getInputProps: getInfluencerImageInputProps,
  } = useDropzone({
    onDrop: onInfluencerImageDrop,
    accept: validFileTypes
      .filter((type) => type.startsWith("image/"))
      .join(","),
    maxSize: maxFileSize,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail((prev) => ({ ...prev, [name]: value }));
  };

  const handleObjectChange = (e) => {
    let { name, value } = e.target;
    const [section, property] = name.split(".");

    if (
      property === "audience" ||
      property === "avgLikes" ||
      property === "avgViews" ||
      property === "reach"
    ) {
      value = value === "" ? 0 : parseInt(value);
    } else if (property === "engagementRate") {
      value =
        value === "" ? 0 : value > 100 ? parseFloat(100) : parseFloat(value);
    }

    setDetail((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [property]: value,
      },
    }));
  };

  const handlePriceChange = (e) => {
    let { name, value } = e.target;
    const [platform, section, prop] = name.split(".");
    value = value === "" ? 0 : parseInt(value);

    console.log("Price change: ", name, value, platform, section, prop);
    setDetail((prev) => ({
      ...prev,
      [platform]: {
        ...prev[platform],
        [section]: {
          ...prev[platform][section],
          [prop]: value,
        },
      },
    }));
  };

  useEffect(() => {
    console.log(details);
  }, [details]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File selected:", file);
      setSelectedFile(file);
      uploadImage(file);
    }
  };

  const handlePhoneChange = (value) => {
    setDetail((prev) => ({ ...prev, phoneNo: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!details.phoneNo) {
    } else if (details.phoneNo === "") {
      const updatedDetails = { ...details };

      delete updatedDetails.phoneNo;

      details = updatedDetails;
    } else {
      const newPhoneNo = details.phoneNo.replace(/\s/g, "");
      details.phoneNo = newPhoneNo;
    }

    let response;
    const reqData = { ...reduce(details), workHistory: [], testimonials: [] };
    console.log(reqData);

    try {
      if (!id) {
        response = await makeRequest("POST", "/influencers/create", reqData);
        if (response) {
          console.log(response);
          notify("success", "Influencers created!");
          navigate("/agencyPool");
        }
      } else {
        response = await makeRequest("PATCH", "/influencers/update", {
          filters: {
            _id: [details._id],
          },
          update: details,
        });
        if (response) {
          notify("success", "Influencer details updated!");
          navigate("/agencyPool");
        }
      }
    } catch (error) {
      console.log(error);
      console.log(error?.response?.data?.message);
      notify("error", "Error while creating/updating influencer, try again!");
    }
  };

  const getDetails = async () => {
    const filters = {
      filters: {
        _id: [id],
      },
      page: 1,
      pageSize: 1,
    };

    try {
      const res = await makeRequest("POST", "/influencers/list", filters);

      if (res && res.data) {
        console.log(res.data.data.results);
        setDetail(res.data.data.results[0]);
        if (!res.data.data.results[0].language) {
          setDetail((prev) => ({ ...prev, language: [] }));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      getDetails();
    }
  }, []);

  return (
    <>
      <Navbar />
      <div className="flex flex-col gap-4 w-11/12 mx-auto">
        <div className="flex justify-between my-10 relative gap-2 items-center">
          <p className="text-2xl font-medium">Agency Influencers</p>

          <Link
            to="/bulkInfluencer"
            className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
          >
            ADD INFLUENCER IN BULK
          </Link>
        </div>

        <div className="flex gap-4">
          {/* Links for form */}
          <div className="flex flex-col gap-4 px-4 py-6 shadow-md rounded-md bg-white h-fit w-1/5">
            <ScrollLink
              to="name"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:cursor-pointer"
            >
              BASIC DETAILS
            </ScrollLink>
            <ScrollLink
              to="socialProfile"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:cursor-pointer"
            >
              SOCIAL PROFILES
            </ScrollLink>
            <ScrollLink
              to="gst"
              spy={true}
              smooth={true}
              duration={500}
              className="hover:cursor-pointer"
            >
              CONTACT DETAILS
            </ScrollLink>
          </div>

          <ThemeProvider theme={customTheme}>
            <form
              onSubmit={handleSubmit}
              className="w-3/4 flex flex-col gap-4 mx-4 shadow-md rounded-md p-3 px-4 mb-6 bg-white"
            >
              {/* Influencer Image upload */}
              <h2 className="mt-2 font-medium">Influencer Image</h2>
              <div>
                <label
                  htmlFor="InfluencerImageInput"
                  className="cursor-pointer"
                >
                  {selectedFile ? (
                    <div className="w-full flex flex-col gap-1 my-1">
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="influencer Img"
                        onClick={handleImageClick}
                        className="w-1/3 max-w-[250px] max-h-[250px] rounded-md"
                      />
                      <p
                        className="text-bold mt-1 text-[#2DA9B2]"
                        onClick={handleImageClick}
                      >
                        Click to upload new image
                      </p>
                    </div>
                  ) : id && details.image && details.image !== "" ? (
                    <div className="w-full flex flex-col gap-1 my-1">
                      <img
                        src={details.image}
                        alt="influencer"
                        className="w-1/3 max-w-[250px] max-h-[250px] rounded-md"
                      />
                      <p
                        className="text-bold mt-1 text-[#2DA9B2]"
                        onClick={handleImageClick}
                      >
                        Click to upload new image
                      </p>
                    </div>
                  ) : (
                    <div className="mb-2">
                      <img
                        src={FramePic}
                        alt="Upload"
                        onClick={handleImageClick}
                      />
                      <p className="mt-1 font-medium">
                        Allowed *.jpg, *.jpeg, *.png, *.gif (max. 3MB)
                      </p>
                    </div>
                  )}
                </label>
                <input
                  type="file"
                  id="InfluencerImageInput"
                  name="InfluencerImage"
                  accept={validFileTypes
                    .filter((type) => type.startsWith("image/"))
                    .join(",")}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </div>

              <TextField
                id="name"
                name="name"
                label="Influencer Name"
                variant="outlined"
                value={details.name}
                onChange={handleChange}
                required
              />
              <TextField
                id="email"
                name="email"
                label="Email"
                variant="outlined"
                placeholder=""
                value={details.email}
                onChange={handleChange}
              />
              <TextField
                id="desc"
                name="bio"
                label="Bio"
                variant="outlined"
                placeholder=""
                value={details.bio}
                onChange={handleChange}
              />

              <FormControl>
                <InputLabel id="categoryLabel">Category</InputLabel>
                <Select
                  labelId="categoryLabel"
                  id="category"
                  name="category"
                  value={details.category}
                  label="Category"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categories.map((item, i) => (
                    <MenuItem value={item.value} key={i}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="genderlabel">Gender</InputLabel>
                <Select
                  labelId="genderlabel"
                  id="gender"
                  name="gender"
                  label="Gender"
                  value={details.gender}
                  onChange={handleChange}
                >
                  <MenuItem value={"MALE"}>Male</MenuItem>
                  <MenuItem value={"FEMALE"}>Female</MenuItem>
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id="platformLabel">Language</InputLabel>
                <Select
                  labelId="platformLabel"
                  id="language"
                  multiple
                  name="language"
                  value={details.language}
                  label="Language"
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {languages.map((item) => (
                    <MenuItem key={item.name} value={item.value}>
                      <Checkbox
                        checked={details.language?.indexOf(item.value) > -1}
                      />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id="country"
                name="country"
                label="Country"
                variant="outlined"
                value={details.country}
                onChange={handleChange}
              />

              <FormControl>
                <InputLabel id="stateLabel">State</InputLabel>
                <Select
                  labelId="stateLabel"
                  id="state"
                  name="state"
                  value={details.state}
                  label="State"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {states.map((item, i) => (
                    <MenuItem value={item.value} key={i}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                id="city"
                name="city"
                label="City"
                variant="outlined"
                value={details.city}
                onChange={handleChange}
              />

              <TextField
                id="gst"
                name="gst"
                label="GST No"
                variant="outlined"
                value={details.gst}
                onChange={handleChange}
              />

              <TextField
                id="pan"
                name="pan"
                label="Pan No"
                variant="outlined"
                value={details.pan}
                onChange={handleChange}
              />

              <h2 className="mt-2 font-medium">Phone Number</h2>
              <MuiTelInput
                id="phone"
                value={details.phoneNo}
                onChange={handlePhoneChange}
                placeholder="Your Phone Number with country code like +91"
              />

              {/* insta details */}
              <h2 className="mt-2 font-medium" id="socialProfile">
                Instagram Profile
              </h2>
              <TextField
                id="profile"
                name="instagram.profileLink"
                label="Instgram Profile Link"
                variant="outlined"
                placeholder="Insta Profile Link"
                value={details.instagram?.profileLink}
                onChange={handleObjectChange}
                required
              />

              <TextField
                id="followers"
                name="instagram.audience"
                label="Followers"
                variant="outlined"
                placeholder="Instagram Followers"
                value={details.instagram?.audience}
                onChange={handleObjectChange}
                required
              />

              <TextField
                id="reach"
                name="instagram.reach"
                label="Reach"
                variant="outlined"
                placeholder="Instagram Reach"
                value={details.instagram?.reach}
                onChange={handleObjectChange}
              />

              <TextField
                id="engagementRate"
                type="number"
                name="instagram.engagementRate"
                label="Engagement Rate"
                variant="outlined"
                placeholder="Instagram Engagement Rate"
                value={details.instagram?.engagementRate}
                onChange={handleObjectChange}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 0.01,
                }}
              />

              <TextField
                id="avgLikes"
                name="instagram.avgLikes"
                label="Average Likes"
                variant="outlined"
                placeholder="Instagram Average Likes"
                value={details.instagram?.avgLikes}
                onChange={handleObjectChange}
              />

              <TextField
                id="avgViews"
                name="instagram.avgViews"
                label="Average Views"
                variant="outlined"
                placeholder="Instagram Average Views"
                value={details.instagram?.avgViews}
                onChange={handleObjectChange}
              />

              <TextField
                id="reelPrice"
                name="instagram.price.reel"
                label="Insta Reel Price"
                variant="outlined"
                placeholder="Instagram Reel Price"
                value={details.instagram?.price?.reel}
                onChange={handlePriceChange}
              />
              <TextField
                id="postPrice"
                name="instagram.price.post"
                label="Insta Post Price"
                variant="outlined"
                placeholder="Instagram Post Price"
                value={details.instagram?.price?.post}
                onChange={handlePriceChange}
              />
              <TextField
                id="storiesPrice"
                name="instagram.price.stories"
                label="Insta Stories Price"
                variant="outlined"
                placeholder="Instagram Stories Price"
                value={details.instagram?.price?.stories}
                onChange={handlePriceChange}
              />

              {/* Youtube Details */}
              <h2 className="mt-2 font-medium">Youtube Profile</h2>
              <TextField
                id="profile"
                name="youtube.profileLink"
                label="Youtube Profile Link"
                variant="outlined"
                placeholder="Youtube Profile Link"
                value={details.youtube?.profileLink}
                onChange={handleObjectChange}
                required
              />

              <TextField
                id="followers"
                name="youtube.audience"
                label="Subscribers"
                variant="outlined"
                placeholder="Youtube Subscribers"
                value={details.youtube?.audience}
                onChange={handleObjectChange}
                required
              />

              <TextField
                id="reach"
                name="youtube.reach"
                label="Reach"
                variant="outlined"
                placeholder="Youtube Reach"
                value={details.youtube?.reach}
                onChange={handleObjectChange}
              />

              <TextField
                id="engagementRate"
                type="number"
                name="youtube.engagementRate"
                label="Engagement Rate"
                variant="outlined"
                placeholder="Youtube Engagement Rate"
                value={details.youtube?.engagementRate}
                onChange={handleObjectChange}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 0.01,
                }}
              />

              <TextField
                id="avgLikes"
                name="youtube.avgLikes"
                label="Average Likes"
                variant="outlined"
                placeholder="Youtube Average Likes"
                value={details.youtube?.avgLikes}
                onChange={handleObjectChange}
              />

              <TextField
                id="avgViews"
                name="youtube.avgViews"
                label="Average Views"
                variant="outlined"
                placeholder="Youtube Average Views"
                value={details.youtube?.avgViews}
                onChange={handleObjectChange}
              />
              <TextField
                id="shortsPrice"
                name="youtube.price.shorts"
                label="Yt Shorts Price"
                variant="outlined"
                placeholder="Youtube Shorts Price"
                value={details.youtube?.price?.shorts}
                onChange={handlePriceChange}
              />
              <TextField
                id="streamingPrice"
                name="youtube.price.streaming"
                label="Yt Streaming Price"
                variant="outlined"
                placeholder="Youtube Streaming Price"
                value={details.youtube?.price?.streaming}
                onChange={handlePriceChange}
              />
              <TextField
                id="shortsPrice"
                name="youtube.price.integratedVideo"
                label="Yt Video Price"
                variant="outlined"
                placeholder="Youtube Integrated Video Price"
                value={details.youtube?.price?.integratedVideo}
                onChange={handlePriceChange}
              />
              <TextField
                id="dedicatedReelPrice"
                name="youtube.price.dedicatedReel"
                label="Yt Reel Price"
                variant="outlined"
                placeholder="Youtube Dedicated Reel Price"
                value={details.youtube?.price?.dedicatedReel}
                onChange={handlePriceChange}
              />

              <div className="flex justify-end">
                <button
                  type="submit"
                  className="bg-[#2DA9B2] text-white rounded-md px-4 m-3 h-[35px] uppercase"
                >
                  {id ? "Update" : "Add"}
                </button>
              </div>
            </form>
          </ThemeProvider>
        </div>
      </div>
      <Footer />
    </>
  );
};

const dropzoneStyle = {
  border: "2px dashed #aaaaaa",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default AddInfluencer;
