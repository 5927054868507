import React from "react";
const year = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="bg-[#637381] h-[45px] flex justify-center items-center text-[#FFFFFF] text-sm z-10">
      &copy; {year} Copyright ECHIO.com
    </div>
  );
};

export default Footer;
