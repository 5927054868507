import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/authSlice";
import campaignReducer from "./slices/campaignSlice";
import componentReducer from "./slices/componentSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  campaign: campaignReducer,
  component: componentReducer
});

export default rootReducer;
