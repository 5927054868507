import { CircularProgress } from "@mui/material";
import React from "react";

const Loader = () => {
  return (
    <div className="text-center w-full flex items-center justify-center">
      <CircularProgress size={16} sx={{ color: "#2DA9B2" }} />
      &nbsp; Loading...
    </div>
  );
};

export default Loader;
