import React from "react";

function FullscreenLoader(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "white",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1000,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
          width: "100px",
          backgroundColor: "white",
          borderRadius: "50%",
          border: "10px solid #f3f3f3",
          borderTop: "10px solid #3498db",
          animation: "spin 2s linear infinite",
        }}
      ></div>
    </div>
  );
}

export default FullscreenLoader;
