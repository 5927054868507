import React, { useEffect, useState } from "react";
import { FaYoutube } from "react-icons/fa";
import Navbar from "../common/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import "./Influencer.css";
import useApi from "../../hooks/useApi";
import DataTable from "../common/DataTable";
import { Box, Tab, Tabs, ThemeProvider, Tooltip } from "@mui/material";
import Loader from "../common/Loader";
import insta from "../../Assets/instagram.png";
import Footer from "../common/Footer";
import { customTheme } from "../../utils/theme";

const categories = [
  { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
  { name: "Educational", value: "EDUCATIONAL" },
  { name: "Entertainment", value: "ENTERTAINMENT" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Lifestyle", value: "LIFESTYLE" },
  { name: "Fashion", value: "FASHION" },
  { name: "Gaming", value: "GAMING" },
  { name: "Auto", value: "AUTO" },
  { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
  { name: "Other", value: "OTHER" },
];

const InfluencerDetail = () => {
  const [clickout, setClickout] = useState(0);
  const [item, setItem] = useState();
  const { makeRequest } = useApi();
  const [total, setTotal] = useState(0);
  const [tabValue, setTabValue] = useState("shortlist");
  const navigate = useNavigate();
  const location = useLocation();
  const groupId = new URLSearchParams(location.search).get("id");

  const handleChange = (event, value) => {
    setTimeout(() => setTabValue(value), 150);

    if (value === "shortlist") setClickout(0);
    else if (value === "reachOut") setClickout(1);
  };

  const getResponses = async () => {
    const requestData = {
      filters: {
        groupId: [groupId],
      },
      page: 1,
      pageSize: 1,
    };

    const response = await makeRequest("POST", "/responses/list", requestData);
    setTotal(response.data.data.total);
  };

  const getDetails = async () => {
    const requestData = {
      filters: {
        _id: [groupId],
      },
    };
    const res = await makeRequest("POST", "/groups/list", requestData);
    setItem(res.data.data.results[0]);
  };

  const getCategoryName = (value) => {
    const category = categories.find((category) => category.value === value);
    return category ? category.name : value;
  };

  useEffect(() => {
    getDetails();
    getResponses();
  }, []);

  return (
    <>
      <Navbar />
      <div className="w-11/12 min-h-[77vh] mx-auto my-8">
        <div className="flex justify-between relative gap-2 items-center">
          <p className="text-2xl font-medium">Influencer Plan</p>
          <button
            type="button"
            className="rounded-md text-white bg-[#2DA9B2] hover:bg-[#2da9b2d8] px-3 h-[35px] flex items-center text-sm font-medium"
            onClick={() => navigate("/influencerList?type=plan&id=" + item._id)}
          >
            ADD INFLUENCER
          </button>
        </div>

        {item ? (
          <div className="mt-8 w-11/12 mx-auto shadow-md px-5 py-3 rounded-t-md bg-white">
            <div className="flex justify-between gap-2">
              <div className="w-[70%]">
                <h1 className="text-black text-xl font-medium">{item.name}</h1>
                <p className="text-[#00000099]">{item.description}</p>
                <button className="mt-4 bg-[#2DA9B21A] rounded-md px-2 py-1 text-[#2DA9B2] text-sm font-medium h-[28px]">
                  {total} Influencer
                </button>
              </div>
              <div className="text-right text-[#0000008C] text-sm font-medium w-[30%]">
                <p>{getCategoryName(item.category) || "N/A"}</p>
                <p>{item.language.map((lang) => lang).join(", ")}</p>
              </div>
            </div>

            <div className=" flex justify-end gap-5 mt-10">
              <div className="flex gap-4 py-5">
                {item.platforms.map((platform, index) => (
                  <button
                    key={index}
                    className="flex items-center gap-1 px-2 bg-[#2DA9B21A] text-[#000000CC] rounded-md text-sm font-medium h-[30px]"
                  >
                    {platform === "INSTAGRAM" && (
                      <>
                        <img src={insta} className="w-[20px] h-[20px]" />
                        Instagram
                      </>
                    )}
                    {platform === "YOUTUBE" && (
                      <>
                        <FaYoutube className="text-2xl text-[#FF0000]" />
                        Youtube
                      </>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}

        {/* Table */}
        <div className="w-11/12 mx-auto">
          <ThemeProvider theme={customTheme}>
            <Box
              sx={{
                background: "#2DA9B21A",
                borderBottom: 1,
                borderColor: "divider",
                borderRadius: "0px 0px 6px 6px",
                px: 2,
              }}
            >
              <Tabs
                value={tabValue}
                onChange={handleChange}
                aria-label="basic tabs"
              >
                <Tab
                  label={
                    <Tooltip title="View influencers shortlisted in plans">
                      <span style={{ fontSize: "14px", fontWeight: 500 }}>
                        SHORTLISTED INFLUENCERS
                      </span>
                    </Tooltip>
                  }
                  value="shortlist"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                />
                <Tab
                  label={
                    <Tooltip title="Explore influencers which are reached out/responded">
                      <span style={{ fontSize: "14px", fontWeight: 500 }}>
                        REACHED OUT INFLUENCERS
                      </span>
                    </Tooltip>
                  }
                  value="reachOut"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                />
              </Tabs>
            </Box>
          </ThemeProvider>
          <DataTable
            status={
              clickout === 0
                ? "shortlisted"
                : clickout === 1
                ? "reachOut"
                : "responded"
            }
            type="plan"
            id={groupId}
          />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default InfluencerDetail;
