export default function rec(obj) {
  for (let key of Object.keys(obj)) {
    if (obj[key] === "") {
      delete obj[key];
    } else if (Array.isArray(obj[key])) {
      obj[key] = obj[key].filter((val) => val !== "");
      if (obj[key].length === 0) {
        delete obj[key];
      }
    } else if (typeof obj[key] === "object") {
      obj[key] = rec(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    }
  }
  return obj;
}
