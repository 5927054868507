import { Circle } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Step,
  Stepper,
  SwipeableDrawer,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
} from "@mui/material";
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import useApi from "../../hooks/useApi";
import { toggleBrandSidebar } from "../../redux/slices/componentSlice";
import BrandDeliverySubPanel from "./BrandDeliverySubPanel";
import { SidebarLoader } from "./SidebarLoader";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { formatAlias } from "../../utils/formatNumber";
import { customTheme } from "../../utils/theme";

const steps = [
  {
    index: 1,
    name: "Script",
    value: "script",
  },
  {
    index: 2,
    name: "Deliverables",
    value: "deliverables",
  },
  {
    index: 3,
    name: "Live Links",
    value: "livelinks",
  },
];

const BrandDeliverySidebar = ({ sidebarState, sidebarData }) => {
  const dispatch = useDispatch();
  const { makeRequest } = useApi();
  const [tabValue, setTabValue] = useState(0);
  const [data, setData] = useState(null);
  const [subTabValue, setSubTabValue] = useState("script");
  const [activeStep, setActiveStep] = useState(0);
  const [work, setWork] = useState({
    script: "",
    deliverables: "",
    livelinks: "",
  });

  const fetchWork = async () => {
    let endpoint = "/works/list";
    let filters = {
      filters: {
        campaignId: sidebarData.campaignId,
        influencerId: [sidebarData.influencerId],
      },
      expand: {
        influencer: {},
        campaign: {},
      },
      pageSize: 1,
      page: 1,
    };
    try {
      const token = sidebarData.token;
      const res = await makeRequest("POST", endpoint, filters, token);
      setData(res.data.data.results[0]);
      setData((prev) => ({
        ...prev,
        campaign: {
          ...(prev?.campaign ?? {}),
          deliverySteps: ["SCRIPTS", "DELIVERABLES", "LIVE_VIDEOS"],
        },
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getPlatform = (item) => {
    if (item.includes("INSTAGRAM")) {
      return "instagram";
    } else {
      return "youtube";
    }
  };

  const getType = (item) => {
    switch (true) {
      case item.includes("DEDICATED_REELS"):
        return "dedicatedReel";
      case item.includes("POSTS"):
        return "post";
      case item.includes("STORIES"):
        return "stories";
      case item.includes("REELS"):
        return "reel";
      case item.includes("SHORTS"):
        return "shorts";
      case item.includes("YOUTUBE_INTEGRATED_VIDEOS"):
        return "integratedVideo";
      case item.includes("STREAMS"):
        return "streaming";
      default:
        return "";
    }
  };

  const getActiveStep = () => {
    console.log("work: ", work);
    if (work.livelinks !== "UNSUBMITTED") {
      setActiveStep(2);
      setSubTabValue("livelinks");
    } else if (work.deliverables !== "UNSUBMITTED") {
      setActiveStep(1);
      setSubTabValue("deliverables");
    } else if (work.script !== "UNSUBMITTED") {
      setActiveStep(1);
      setSubTabValue("deliverables");
    } else {
      setActiveStep(0);
      setSubTabValue("script");
    }
  };

  const getWorkStatus = () => {
    const platform = getPlatform(tabValue);
    const type = getType(tabValue);
    const script = data.scripts?.status?.[platform]?.[type];
    const deliverables = data.deliverables?.status?.[platform]?.[type];
    const livelinks = data.liveLinks?.status?.[platform]?.[type];
    setWork({ script, deliverables, livelinks });
  };

  useEffect(() => {
    if (sidebarData) {
      if (sidebarState === true) {
        fetchWork();
      }
    }
  }, [sidebarState]);

  useEffect(() => {
    if (data) {
      console.log("Data: ", data);
      setSubTabValue("script");
      setTabValue(data.campaign?.deliveryTypes?.[0]);
    }
  }, [data]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSubTabChange = (e, newValue) => {
    e.preventDefault();
    setSubTabValue(newValue);
  };

  const handleSidebarClose = () => {
    setData(null);
    setTabValue(0);
    dispatch(toggleBrandSidebar());
  };

  useEffect(() => {
    console.log(tabValue, subTabValue);
    if (tabValue !== 0) {
      getWorkStatus();
    }
  }, [tabValue]);

  useEffect(() => {
    getActiveStep();
  }, [work]);

  return (
    <>
      <ThemeProvider theme={customTheme}>
        {sidebarData && (
          <SwipeableDrawer
            anchor="right"
            open={sidebarState}
            onClose={handleSidebarClose}
            onOpen={handleSidebarClose}
            PaperProps={{ style: { width: "600px", zIndex: 1000 } }}
          >
            {data ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "10px 20px",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "medium", fontSize: "1.5rem" }}
                  >
                    Status
                  </Typography>
                  <CloseIcon
                    onClick={handleSidebarClose}
                    className="cursor-pointer"
                  />
                </div>

                <Box
                  sx={{
                    maxWidth: { xs: "100%", sm: "100%" },
                    marginTop: "10px",
                    bgcolor: "background.paper",
                  }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs"
                  >
                    {data.campaign.deliveryTypes.map((item, index) => (
                      <Tab label={formatAlias(item)} key={index} value={item} />
                    ))}
                  </Tabs>
                  <hr />

                  <Box sx={{ p: 3 }}>
                    <Stepper
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                      activeStep={activeStep}
                      connector={<QontoConnector />}
                    >
                      {steps.map((step, i) => (
                        <Step
                          key={i}
                          style={{
                            display: "grid",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          className="cursor-pointer"
                          onClick={(e) => handleSubTabChange(e, step.value)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Circle
                              style={{
                                color:
                                  subTabValue === step.value
                                    ? "#2DA9B2"
                                    : work[step.value] === "ACCEPTED"
                                    ? "#2DA9B2"
                                    : "#FFFFFF",
                                width: "50px",
                                height: "50px",
                                border: "2px solid #c5ddf4",
                                borderRadius: "100%",
                                background:
                                  subTabValue !== step.value &&
                                  work[step.value] === "ACCEPTED" &&
                                  "#2DA9B2",
                              }}
                            />
                          </div>
                          <Typography
                            fontSize={"16px"}
                            style={{
                              marginTop: "-50px",
                              textAlign: "center",
                              color:
                                subTabValue === step.value
                                  ? "#FFFFFF"
                                  : work[step.value] === "ACCEPTED"
                                  ? "#FFFFFF"
                                  : "#2DA9B2",
                            }}
                          >
                            {step.index}
                          </Typography>
                          <Typography
                            fontSize={"14px"}
                            sx={{
                              mt: "5px",
                              textAlign: "center",
                              color: "#868686",
                            }}
                          >
                            {step.name}
                          </Typography>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>

                  {data.campaign.deliveryTypes.map((item, index) => (
                    <div key={index}>
                      {tabValue === item && (
                        <BrandDeliverySubPanel
                          campaignId={data.campaignId}
                          influencerId={data.influencerId}
                          workId={data._id}
                          deliveryType={item}
                          step={subTabValue}
                          platform={getPlatform(item)}
                          token={sidebarData.token}
                        />
                      )}
                    </div>
                  ))}
                </Box>
              </div>
            ) : (
              <SidebarLoader />
            )}
          </SwipeableDrawer>
        )}
      </ThemeProvider>
    </>
  );
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2DA9B2",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#2DA9B2",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const mapStateToProps = (state) => ({
  sidebarState: state.component.brandDeliverySidebar,
  sidebarData: state.campaign.brandSidebarData,
});

export default connect(mapStateToProps, {})(BrandDeliverySidebar);
