// File: redux/store.js
// import { createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for the web
import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage: storage,
  version: 1,
  whitelist: ["auth","campaign"],
  blacklist: ["component"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = createStore(persistedReducer);
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      immutableCheck: true,
      serializableCheck: false,
      actionCreatorCheck: true,
    }),
});
export const persistor = persistStore(store);

// export { store, persistor };
