import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import useApi from "../../hooks/useApi";
import {
  Avatar,
  Box,
  Pagination,
  PaginationItem,
  Stack,
  TablePagination,
  ThemeProvider,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { formatCurrency } from "../../utils/formatNumber";
import { FaYoutube } from "react-icons/fa";
import insta from "../../Assets/instagram.png";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import { customTheme } from "../../utils/theme";
import PaymentPopup from "./PaymentPopup";
import Invoice from "./Invoice";
import html2pdf from "html2pdf.js";

const PayTable = ({ campaignId }) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [paymentRow, setPaymentRow] = useState([]);
  const [totalPages, setTotal] = useState(1);
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const { makeRequest } = useApi();

  const fetchInfluencers = async (pageNumber = 1) => {
    setRows([]);

    setLoading(true);
    let filters = {
      filters: {
        campaignId: campaignId,
        status: ["ACTIVE", "COMPLETED"],
      },
      expand: {
        influencer: {
          expand: {
            agency: {},
          },
        },
      },
      pageSize: rowsPerPage,
      page: pageNumber,
    };

    try {
      const response = await makeRequest("POST", "/works/list", filters);
      if (response) {
        const totalItems = response?.data?.data?.total || 0;

        setTotal(Math.ceil(totalItems / rowsPerPage));
        const results = response.data.data.results;
        const newData = results.map((item) => ({
          campaignId: item.campaignId,
          influencerId: item.influencerId,
          paymentStatus: item.influencerPaymentStatus,
          paymentHistory: item.paymentHistory,
          paidAmount: item.paidAmount,
          totalAmount: item.price,
          agency: item.agency,
          ...item.influencer,
        }));

        setRows((prev) => [...prev, ...newData]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchInfluencers(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleInvoiceDownload = async (invoiceData) => {
    const invoiceContent = getInvoice(invoiceData);
    convertToPdf(invoiceContent);
  };

  const getInvoice = (data) => {
    return <Invoice data={data} />;
  };

  const convertToPdf = (content) => {
    const element = document.createElement("div");
    createRoot(element).render(content);
    html2pdf().from(element).set({ filename: "invoice.pdf" }).save();
  };

  // Call fetchInfluencers to fetch new data whenever there is a change in filters
  useEffect(() => {
    setLoading(true);

    const debouncedFetchInfluencers = debounce(() => {
      setRows([]);
      setPage(1);
      fetchInfluencers();
    }, 1000);
    debouncedFetchInfluencers();

    // Cleanup
    return () => {
      // debouncedSearch.cancel();
      debouncedFetchInfluencers.cancel();
    };
  }, [rowsPerPage]);

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ width: "100%" }}>
        <TableContainer
          component={Paper}
          style={{ marginTop: "2rem", marginBottom: "0.5rem" }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ background: "lightgray" }}>
              <TableRow key={0}>
                <TableCell key={1}>Name</TableCell>
                <TableCell key={2}>Platform</TableCell>
                <TableCell key={3}>Price</TableCell>
                <TableCell key={4}>Total Price</TableCell>
                <TableCell key={5}>Amount Paid till now</TableCell>
                <TableCell key={6} align="center">
                  Status
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {!loading ? (
                rows.length > 0 ? (
                  rows.map((row) => (
                    <>
                      <TableRow key={row._id}>
                        <TableCell>
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            spacing={"10px"}
                          >
                            <Avatar
                              alt={row.name}
                              src={row.image}
                              variant="circle"
                            />
                            <Typography sx={{ alignItems: "center" }}>
                              {row.name}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          {row.instagram && (
                            <button className="flex items-center rounded-lg p-1 gap-1">
                              <img src={insta} className="w-[20px] h-[20px]" />
                              Instagram
                            </button>
                          )}
                          {row.youtube && (
                            <button className="flex items-center rounded-lg p-1 gap-1">
                              <FaYoutube className="text-2xl text-[#FF0000]" />
                              Youtube
                            </button>
                          )}
                        </TableCell>
                        <TableCell>
                          <p>
                            Reel {formatCurrency(row.instagram?.price?.reel)}
                          </p>
                          <p>
                            Posts {formatCurrency(row.instagram?.price?.post)}
                          </p>
                          <p>
                            Stories
                            {formatCurrency(row.instagram?.price?.stories)}
                          </p>
                        </TableCell>
                        <TableCell>{formatCurrency(row.totalAmount)}</TableCell>
                        <TableCell>{formatCurrency(row.paidAmount)}</TableCell>
                        <TableCell align="center">
                          <div className="flex gap-2 justify-center">
                            {row.totalAmount === row.paidAmount &&
                            row.totalAmount !== 0 ? (
                              <p className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                Completed
                              </p>
                            ) : (
                              <button
                                onClick={() => {
                                  setPopup(true);
                                  setPaymentRow(row);
                                }}
                                className="rounded-md text-[#2DA9B2] border border-[#2DA9B2] text-md px-4 py-1"
                              >
                                Record Payment
                              </button>
                            )}

                            <button
                              onClick={() => handleInvoiceDownload(row)}
                              className="text-white bg-[#2DA9B2] hover:bg-[#2da9b2d8] rounded-md text-md px-4 py-1"
                            >
                              Download Invoice
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                      {popup && (
                        <PaymentPopup
                          setPopup={setPopup}
                          open={popup}
                          row={paymentRow}
                          fetchInfluencers={fetchInfluencers}
                        />
                      )}
                    </>
                  ))
                ) : (
                  <TableCell colSpan={8} height={200} align="center">
                    No influencers to display!
                  </TableCell>
                )
              ) : (
                <TableCell colSpan={8} height={200} align="center">
                  <Loader />
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {rows?.length > 0 && (
        <div className="flex justify-center">
          <Stack spacing={2} direction="row" alignItems="center">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                />
              )}
            />
            <TablePagination
              component="div"
              count={totalPages}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={() => null}
              rowsPerPageOptions={[5, 10, 15]}
              slotProps={{
                actions: {
                  nextButton: {
                    style: {
                      display: "none",
                    },
                  },
                  previousButton: {
                    style: {
                      display: "none",
                    },
                  },
                },
              }}
            />
          </Stack>
        </div>
      )}
    </ThemeProvider>
  );
};

export default PayTable;
