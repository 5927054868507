import React, { useState } from "react";
import useApi from "../../hooks/useApi";
import notify from "../../utils/notification";
import { Box, Modal, TextField } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "7px",
  boxShadow: 20,
  p: 4,
};

const PaymentPopup = ({ setPopup, row, fetchInfluencers, open }) => {
  const { makeRequest } = useApi();
  const [amount, setAmount] = useState(0);

  const recordPayment = async (e) => {
    e.preventDefault();

    if (row.paidAmount + Number(amount) > row.totalAmount) {
      notify("warning", "Can't pay more than total amount");
      return;
    }

    try {
      const res = await makeRequest("POST", "/influencers/pay", {
        campaignId: row.campaignId,
        influencerId: row.influencerId,
        paidAmount: parseInt(amount),
        transactionId:
          row.campaignId?.substring(0, 6) + row.influencerId?.substring(0, 6),
        transactionDate: new Date(),
      });
      if (res) {
        notify("success", "Payment Recorded!");
        setPopup(false);
        fetchInfluencers();
      }
    } catch (error) {
      console.log(error);
      notify("error", "Error recording payment, try again!");
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={() => setPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form className="w-full flex gap-3 flex-col" onSubmit={recordPayment}>
            <p className="font-medium text-lg">Record Payment</p>
            <TextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              value={row.name}
              disabled
              required
            />
            <TextField
              id="totalAmount"
              name="totalAmount"
              label="Total Amount to be paid"
              variant="outlined"
              value={row.totalAmount}
              required
            />
            <TextField
              id="paidAmount"
              name="paidAmount"
              label="Amount paid till now"
              variant="outlined"
              value={row.paidAmount}
              disabled
              required
            />
            <TextField
              id="payment"
              name="payment"
              label="Amount Paying"
              variant="outlined"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
            <div className="flex justify-end">
              <button
                type="submit"
                className="bg-[#2DA9B2] text-white rounded-md px-3 h-[35px] flex items-center text-sm   font-medium"
              >
                CONFIRM
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default PaymentPopup;
