import React, { useEffect, useState } from "react";
import { FaYoutube } from "react-icons/fa";
import placeholder from "../../asset/placeholder.webp";
import {
  formatNumber,
  formatCurrency,
  formatString,
} from "../../utils/formatNumber";
import useApi from "../../hooks/useApi";
import notify from "../../utils/notification";
import { useNavigate } from "react-router-dom";
import insta from "../../Assets/instagram.png";

const ProfileCard = ({ data, type, id, showAdd }) => {
  const [currentPlatform, setCurrentPlatform] = useState("");
  const { makeRequest } = useApi();
  const navigate = useNavigate();
  const [added, setAdded] = useState(false);
  const [loading, setLoading] = useState(false);

  const addInfluencer = async (influencerId) => {
    let endpoint = "";
    let data = {};

    if (!type || !id) {
      notify("warning", "Error, try again!");
      return;
    }

    if (type === "plan") {
      endpoint = "/groups/insert";
      data = {
        groupId: id,
        influencerIds: [influencerId],
      };
    } else if (type === "campaign") {
      endpoint = "/responses/shortlist";
      data = {
        campaignId: id,
        responseIds: [influencerId],
      };
    } else {
      notify("warning", "Error, try again!");
      return;
    }
    setLoading(true);
    try {
      const res = await makeRequest("POST", endpoint, data);
      if (res) {
        notify("success", "Successfully added the Influencer!");
        setAdded(true);
      }
    } catch (error) {
      console.log(error);
      notify("error", "Error while adding, try again!");
    } finally {
      setLoading(false);
    }
  };

  const removeInfluencer = async (influencerId) => {
    let endpoint = "";
    let data = {};

    if (!type || !id) {
      notify("warning", "Error, try again!");
      return;
    }

    if (type === "plan") {
      endpoint = "/groups/exclude";
      data = {
        groupId: id,
        influencerIds: [influencerId],
      };
    } else if (type === "campaign") {
      endpoint = "/influencers/delist";
      data = {
        campaignId: id,
        influencerIds: [influencerId],
      };
    } else {
      notify("warning", "Error, try again!");
      return;
    }

    setLoading(true);
    try {
      const res = await makeRequest("POST", endpoint, data);
      if (res) {
        notify("success", "Successfully removed the Influencer!");
        setAdded(false);
      }
    } catch (error) {
      console.log(error);
      notify("error", "Error while removing, try again!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (data.instagram && data.youtube) {
      setCurrentPlatform("Instagram");
    } else if (data.instagram && !data.youtube) {
      setCurrentPlatform("Instagram");
    } else if (!data.instagram && data.youtube) {
      setCurrentPlatform("Youtube");
    }
  }, [data]);

  return (
    <div className="flex gap-3 w-full h-fit min-h-[150px] mx-auto shadow-md p-4 rounded-md bg-white">
      <div className="flex items-center">
        <img
          src={data.image || placeholder}
          alt={data.name}
          className="w-[170px] h-full max-h-[150px] rounded-md"
        />
      </div>
      <div className="flex gap-3 flex-col justify-between w-full">
        <div className="flex justify-between">
          <div className="max-w-[80%] flex gap-3 flex-col">
            <p className="text-xl font-medium text-black">
              {data.name?.length > 100
                ? data.name?.substring(0, 100) + "..."
                : data.name}
              <span className="text-gray-500 text-sm pl-2">{data.city}</span>
            </p>
            {currentPlatform === "Instagram"
              ? data.instagram && (
                  <div className="flex gap-2">
                    <p className="flex flex-col bg-[#2DA9B21A] p-2 rounded-md text-center w-[7rem]">
                      <span className="text-gray-500">
                        {data.instagram.engagementRate
                          ? data.instagram.engagementRate + "%"
                          : "N/A"}
                      </span>
                      <span className="font-medium text-[#000000CC]">
                        Engagement
                      </span>
                    </p>
                    <p className="flex flex-col bg-[#2DA9B21A] p-2 rounded-md text-center w-[7rem]">
                      <span className="text-gray-500">
                        {formatNumber(data.instagram.avgLikes)}
                      </span>
                      <span className="font-medium text-[#000000CC]">
                        Avg. Likes
                      </span>
                    </p>
                    <p className="flex flex-col bg-[#2DA9B21A] p-2 rounded-md text-center w-[7rem]">
                      <span className="text-gray-500">
                        {formatNumber(data.instagram.avgViews)}
                      </span>
                      <span className="font-medium text-[#000000CC]">
                        Avg. Views
                      </span>
                    </p>
                  </div>
                )
              : data.youtube && (
                  <div className="flex gap-2">
                    <p className="flex flex-col bg-[#2DA9B21A] p-2 rounded-md text-center w-[7rem]">
                      <span className="text-gray-500">
                        {data.youtube.engagementRate
                          ? data.youtube.engagementRate + "%"
                          : "N/A"}
                      </span>
                      <span className="font-medium text-[#000000CC]">
                        Engagement
                      </span>
                    </p>
                    <p className="flex flex-col bg-[#2DA9B21A] p-2 rounded-md text-center w-[7rem]">
                      <span className="text-gray-500">
                        {formatNumber(data.youtube.avgLikes)}
                      </span>
                      <span className="font-medium text-[#000000CC]">
                        Avg. Likes
                      </span>
                    </p>
                    <p className="flex flex-col bg-[#2DA9B21A] p-2 rounded-md text-center w-[7rem]">
                      <span className="text-gray-500">
                        {formatNumber(data.youtube.avgViews)}
                      </span>
                      <span className="font-medium text-[#000000CC]">
                        Avg. Views
                      </span>
                    </p>
                  </div>
                )}
          </div>

          {/* Price info */}
          <div className="flex flex-col items-end gap-1">
            {type === "campaign" ? (
              <>
                {currentPlatform === "Instagram" &&
                  data.responseDetails &&
                  data.responseDetails.instagramPrice && (
                    <>
                      {Object.entries(data.responseDetails.instagramPrice).map(
                        ([name, price]) => (
                          <p className="text-center" key={name}>
                            <span className="text-gray-500">
                              {formatString(name) + " Price "}
                            </span>
                            <span>{formatCurrency(price)}</span>
                          </p>
                        )
                      )}
                    </>
                  )}

                {currentPlatform === "Youtube" &&
                  data.responseDetails &&
                  data.responseDetails.youtubePrice && (
                    <>
                      {Object.entries(data.responseDetails.youtubePrice).map(
                        ([name, price]) => (
                          <p className="text-center" key={name}>
                            <span className="text-gray-500">
                              {formatString(name) + " Price "}
                            </span>
                            <span>{formatCurrency(price)}</span>
                          </p>
                        )
                      )}
                    </>
                  )}
              </>
            ) : (
              <>
                {currentPlatform === "Instagram" &&
                  data.instagram &&
                  data.instagram.price && (
                    <>
                      {Object.entries(data.instagram.price).map(
                        ([name, price]) => (
                          <p className="text-center" key={name}>
                            <span className="text-gray-500">
                              {formatString(name) + " Price "}
                            </span>
                            <span>{formatCurrency(price)}</span>
                          </p>
                        )
                      )}
                    </>
                  )}

                {currentPlatform === "Youtube" &&
                  data.youtube &&
                  data.youtube.price && (
                    <>
                      {Object.entries(data.youtube.price).map(
                        ([name, price]) => (
                          <p className="text-center" key={name}>
                            <span className="text-gray-500">
                              {formatString(name) + " Price "}
                            </span>
                            <span>{formatCurrency(price)}</span>
                          </p>
                        )
                      )}
                    </>
                  )}
              </>
            )}
          </div>
        </div>

        {/* Platform buttons */}
        <div className="flex justify-between items-center">
          <div className="flex gap-4">
            {type === "campaign" ? (
              <>
                {data.responseDetails?.instagramPrice && (
                  <button
                    className={
                      "flex items-center rounded-md text-sm gap-1 border-2 h-[34px] border-[#2DA9B2] px-3 " +
                      (currentPlatform === "Instagram"
                        ? "bg-[#2DA9B2] text-white"
                        : "bg-[#2DA9B21A] text-[#2DA9B2]")
                    }
                    onClick={() => setCurrentPlatform("Instagram")}
                  >
                    <img src={insta} className="w-[20px] h-[20px]" />
                    Instagram {formatNumber(data.instagram?.audience)}
                  </button>
                )}
                {data.responseDetails?.youtubePrice && (
                  <button
                    className={
                      "flex items-center rounded-md text-sm gap-1 border-2 h-[34px] border-[#2DA9B2] px-3 " +
                      (currentPlatform === "Youtube"
                        ? "bg-[#2DA9B2] text-white"
                        : "bg-[#2DA9B21A] text-[#2DA9B2]")
                    }
                    onClick={() => setCurrentPlatform("Youtube")}
                  >
                    <FaYoutube className="text-xl text-[#FF0000]" />
                    Youtube {formatNumber(data.youtube?.audience)}
                  </button>
                )}
              </>
            ) : (
              <>
                {data.instagram && (
                  <button
                    className={
                      "flex items-center rounded-md text-sm gap-1 border-2 h-[34px] border-[#2DA9B2] px-3 " +
                      (currentPlatform === "Instagram"
                        ? "bg-[#2DA9B2] text-white"
                        : "bg-[#2DA9B21A] text-[#2DA9B2]")
                    }
                    onClick={() => setCurrentPlatform("Instagram")}
                  >
                    <img src={insta} className="w-[20px] h-[20px]" />
                    Instagram {formatNumber(data.instagram.audience)}
                  </button>
                )}
                {data.youtube && (
                  <button
                    className={
                      "flex items-center rounded-md text-sm gap-1 border-2 h-[34px] border-[#2DA9B2] px-3 " +
                      (currentPlatform === "Youtube"
                        ? "bg-[#2DA9B2] text-white"
                        : "bg-[#2DA9B21A] text-[#2DA9B2]")
                    }
                    onClick={() => setCurrentPlatform("Youtube")}
                  >
                    <FaYoutube className="text-xl text-[#FF0000]" />
                    Youtube {formatNumber(data.youtube.audience)}
                  </button>
                )}
              </>
            )}
          </div>
          {showAdd && (
            <div className="flex">
              {loading ? (
                <button
                  className="bg-[#2DA9B2] rounded-md px-2 text-white text-sm font-medium h-[30px]"
                  disabled={true}
                >
                  Loading...
                </button>
              ) : !added ? (
                <button
                  disabled={loading}
                  className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] rounded-md px-2 text-white text-sm font-medium h-[30px]"
                  onClick={() => {
                    type === "agencyPool"
                      ? navigate("/addInfluencer?id=" + data._id)
                      : addInfluencer(
                          type === "plan" ? data._id : data.responseId
                        );
                  }}
                >
                  {type === "agencyPool"
                    ? "EDIT DETAILS"
                    : type === "plan"
                    ? "ADD TO PLAN"
                    : "ADD TO CAMPAIGN"}
                </button>
              ) : (
                <button
                  disabled={loading}
                  className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] rounded-md px-2 text-white text-sm font-medium h-[30px]"
                  onClick={() => {
                    removeInfluencer(data._id);
                  }}
                >
                  {type === "plan"
                    ? "REMOVE FROM PLAN"
                    : "REMOVE FROM CAMPAIGN"}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
