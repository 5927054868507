import React, { useEffect, useState } from "react";
import image1 from "../../Assets/49e58d5922019b8ec4642a2e2b9291c2.png";
import { FaSearch, FaYoutube } from "react-icons/fa";
import Navbar from "../common/Navbar";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Pagination,
  PaginationItem,
  Stack,
  TablePagination,
  ThemeProvider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import reduce from "../../utils/reduce";
import Loader from "../common/Loader";
import insta from "../../Assets/instagram.png";
import Footer from "../common/Footer";
import { customTheme } from "../../utils/theme";

const categories = [
  { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
  { name: "Educational", value: "EDUCATIONAL" },
  { name: "Entertainment", value: "ENTERTAINMENT" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Lifestyle", value: "LIFESTYLE" },
  { name: "Fashion", value: "FASHION" },
  { name: "Gaming", value: "GAMING" },
  { name: "Auto", value: "AUTO" },
  { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
  { name: "Other", value: "OTHER" },
];

const Influencer = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { makeRequest } = useApi();
  const [platformCheck, setPlatformCheck] = useState([false, false]);
  const [languageCheck, setLanguageCheck] = useState([false, false]);
  const [filter, setFilter] = useState({
    category: [],
    name: "",
  });

  const getLanguages = () => {
    if (languageCheck[0] && languageCheck[1]) {
      return ["Hindi", "English"];
    } else if (!languageCheck[0] && languageCheck[1]) {
      return ["English"];
    } else if (languageCheck[0] && !languageCheck[1]) {
      return ["Hindi"];
    } else {
      return [];
    }
  };

  const getPlatforms = () => {
    if (platformCheck[0] && platformCheck[1]) {
      return ["INSTAGRAM", "YOUTUBE"];
    } else if (!platformCheck[0] && platformCheck[1]) {
      return ["YOUTUBE"];
    } else if (platformCheck[0] && !platformCheck[1]) {
      return ["INSTAGRAM"];
    } else {
      return [];
    }
  };

  const filters = {
    category: filter.category,
    name: filter.name,
    language: getLanguages(),
    platforms: getPlatforms(),
  };

  async function getInfluencerPlan(pageNumber = 1) {
    setData([]);

    try {
      setLoading(true);
      const requestData = {
        filters: reduce(filters),
        expandArray: {
          responses: {},
        },
        page: pageNumber,
        pageSize: rowsPerPage,
      };

      const response = await makeRequest("POST", "/groups/list", requestData);

      if (response) {
        console.log(response.data.data);
        const newData = response?.data?.data?.results || [];
        const totalItems = response?.data?.data?.total || 0;

        setData((prevData) => [...prevData, ...newData]);

        const updatedTotalPages = Math.ceil(totalItems / rowsPerPage);
        setTotalPages(updatedTotalPages);
      }
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
    console.log(filter);
  };

  const handleFieldChange = (fieldName, value) => {
    setFilter((prevFormValue) => ({
      ...prevFormValue,
      [fieldName]: value,
    }));
  };

  const getCategoryName = (value) => {
    const category = categories.find((category) => category.value === value);
    return category ? category.name : value;
  };

  // Debounce for search
  const debounce = (func, delay) => {
    let timeoutId;
    const debouncedFunction = (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };

    debouncedFunction.cancel = () => {
      clearTimeout(timeoutId);
    };

    return debouncedFunction;
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    getInfluencerPlan(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // For search query
  useEffect(() => {
    const delayedInfluencerPlan = debounce(getInfluencerPlan, 1000);

    setData([]);
    setPage(1);
    setLoading(true);
    delayedInfluencerPlan();

    return () => {
      // Cleanup
      delayedInfluencerPlan.cancel();
    };
  }, [filter.name, rowsPerPage, platformCheck, languageCheck, filter.category]);

  return (
    <ThemeProvider theme={customTheme}>
      <Navbar />
      <div className="w-11/12 mx-auto mt-8">
        <div className="flex justify-between gap-4 items-center">
          <p className=" text-2xl font-medium">Influencer Plan</p>
          <div className="flex border gap-3 items-center px-4 py-2 rounded-md w-1/3 text-gray-500 bg-white">
            <FaSearch />
            <input
              placeholder="Search"
              className="border-none outline-none text-black w-full"
              name="name"
              value={filter.name}
              onChange={handleChange}
            />
          </div>
          <Link
            className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
            to="/createPlan"
          >
            CREATE PLAN
          </Link>
        </div>

        <div className="flex justify-around gap-8 py-8">
          {/* Filters */}
          <div className="shadow-md bg-white px-3 py-2 rounded-md h-fit w-1/5">
            {/* Platform */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Platform</h2>
              <FormGroup>
                <div className="ml-3 flex flex-col">
                  <FormControlLabel
                    label={<span className="text-[#000000B2]">Instagram</span>}
                    control={
                      <Checkbox
                        size="small"
                        checked={platformCheck[0]}
                        onChange={(event) => {
                          setPlatformCheck([
                            event.target.checked,
                            platformCheck[1],
                          ]);
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={<span className="text-[#000000B2]">Youtube</span>}
                    control={
                      <Checkbox
                        size="small"
                        checked={platformCheck[1]}
                        onChange={(event) => {
                          setPlatformCheck([
                            platformCheck[0],
                            event.target.checked,
                          ]);
                        }}
                      />
                    }
                  />
                </div>
              </FormGroup>
            </div>

            {/* Category */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Categories</h2>
              <div>
                {categories.map((item) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        checked={filter.category.includes(item.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleFieldChange("category", [
                              ...filter.category,
                              item.value,
                            ]);
                          } else {
                            handleFieldChange(
                              "category",
                              filter.category.filter(
                                (category) => category !== item.value
                              )
                            );
                          }
                        }}
                      />
                      <span className="text-[#000000B2]">{item.name}</span>
                    </Stack>
                  </>
                ))}
              </div>
            </div>

            {/* Language */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Language</h2>
              <div className="ml-2 flex flex-col">
                <FormControlLabel
                  label={<span className="text-[#000000B2]">Hindi</span>}
                  control={
                    <Checkbox
                      size="small"
                      checked={languageCheck[0]}
                      onChange={(event) => {
                        setLanguageCheck([
                          event.target.checked,
                          languageCheck[1],
                        ]);
                      }}
                    />
                  }
                />
                <FormControlLabel
                  label={<span className="text-[#000000B2]">English</span>}
                  control={
                    <Checkbox
                      size="small"
                      checked={languageCheck[1]}
                      onChange={(event) => {
                        setLanguageCheck([
                          languageCheck[0],
                          event.target.checked,
                        ]);
                      }}
                    />
                  }
                />
              </div>
            </div>
          </div>

          {/* Data based on filters */}
          <div className="flex flex-col w-4/5 gap-4 justify-between">
            {/* Data Cards */}
            <div className="mb-6 flex flex-col gap-6 w-full">
              {data?.length > 0 ? (
                data.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col gap-4 w-full justify-center mx-auto shadow-md px-4 py-3 rounded-lg bg-white"
                  >
                    <div className="flex justify-between">
                      <div className="w-[80%]">
                        <p className="text-black text-xl font-medium">
                          {item.name?.length > 100
                            ? item.name?.substring(0, 100) + "..."
                            : item.name}
                        </p>
                        <p className=" text-slate-500 max-w-[65%]">
                          {item.description?.length > 100
                            ? item.description?.substring(0, 100) + "..."
                            : item.description}
                        </p>
                        <button className="mt-2 bg-[#2da9b22e] p-1 rounded-md px-2 text-[#2DA9B2] text-sm font-medium">
                          {item.responses?.length || 0} Influencer
                        </button>
                      </div>
                      <div className="text-right text-[#0000008C] text-sm font-medium">
                        <p>{getCategoryName(item.category) || "N/A"}</p>
                        <p>{item.language?.map((lang) => lang).join(", ")}</p>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="flex gap-4">
                        {item.platforms.map((platform, index) => (
                          <button
                            key={index}
                            className="flex items-center gap-1 px-2 bg-[#2DA9B21A] text-[#000000CC] rounded-md text-sm font-medium h-[30px]"
                          >
                            {platform === "INSTAGRAM" && (
                              <>
                                <img
                                  src={insta}
                                  className="w-[20px] h-[20px]"
                                />
                                Instagram
                              </>
                            )}
                            {platform === "YOUTUBE" && (
                              <>
                                <FaYoutube className="text-2xl text-[#FF0000]" />
                                Youtube
                              </>
                            )}
                          </button>
                        ))}
                      </div>

                      <div className="flex gap-4">
                        <button
                          className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] rounded-md px-2 text-white text-sm font-medium h-[30px]"
                          onClick={() => {
                            navigate("/influencerDetail?id=" + item._id);
                          }}
                        >
                          VIEW PLAN DETAILS
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className=" mt-20 flex  justify-center items-center">
                  {!loading && <img src={image1} alt="placeholder" />}
                </div>
              )}
              {loading && <Loader />}
            </div>

            {/* Pagination */}
            {data?.length > 0 && (
              <div className="flex justify-center">
                <Stack spacing={2} direction="row" alignItems="center">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                  <TablePagination
                    component="div"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={() => null}
                    rowsPerPageOptions={[5, 10, 15]}
                    slotProps={{
                      actions: {
                        nextButton: {
                          style: {
                            display: "none",
                          },
                        },
                        previousButton: {
                          style: {
                            display: "none",
                          },
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default Influencer;
