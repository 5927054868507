import React, { useEffect, useState } from "react";
import { FaSearch, FaYoutube } from "react-icons/fa";
import Navbar from "../common/Navbar";
import image1 from "../../Assets/49e58d5922019b8ec4642a2e2b9291c2.png";
import insta from "../../Assets/instagram.png";
import { Link, useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Pagination,
  PaginationItem,
  Stack,
  TablePagination,
  ThemeProvider,
} from "@mui/material";
import reduce from "../../utils/reduce";
import placeholder from "../../asset/placeholder.webp";
import Loader from "../common/Loader";
import Footer from "../common/Footer";
import { customTheme } from "../../utils/theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const categories = [
  { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
  { name: "Educational", value: "EDUCATIONAL" },
  { name: "Entertainment", value: "ENTERTAINMENT" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Lifestyle", value: "LIFESTYLE" },
  { name: "Fashion", value: "FASHION" },
  { name: "Gaming", value: "GAMING" },
  { name: "Auto", value: "AUTO" },
  { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
  { name: "Other", value: "OTHER" },
];

const CampaignList = () => {
  const navigate = useNavigate();
  const { makeRequest } = useApi();
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    name: "",
    campaignCategory: [],
    campaignType: [],
    campaignStatus: [],
    platform: [],
  });
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeChecked, setActiveChecked] = useState(false);
  const [pastChecked, setPastChecked] = useState(false);
  const [paidChecked, setPaidChecked] = useState(false);
  const [barterChecked, setBarterChecked] = useState(false);
  const [platformCheck, setPlatformCheck] = useState([false, false]);

  // Function to get selected campaign types
  const getSelectedCampaignTypes = () => {
    const types = [];
    if (paidChecked) types.push("PAID");
    if (barterChecked) types.push("BARTER");
    return types;
  };

  // Function to get selected campaign status
  const getSelectedCampaignStatus = () => {
    const status = [];
    if (activeChecked) status.push("ACTIVE");
    if (pastChecked) status.push("PAST");
    return status;
  };

  const getSelectedPlatforms = () => {
    const platforms = [];
    if (platformCheck[0]) platforms.push("INSTAGRAM");
    if (platformCheck[1]) platforms.push("YOUTUBE");
    return platforms;
  };

  async function fetchCampaigns(pageNumber = 1) {
    setData([]);

    try {
      setLoading(true);
      const filters = {
        name: filter.name,
        campaignCategory: filter.campaignCategory,
        campaignType: getSelectedCampaignTypes(),
        campaignStatus: getSelectedCampaignStatus(),
        platform: getSelectedPlatforms(),
      };
      const requestData = {
        filters: reduce(filters),
        page: pageNumber,
        pageSize: rowsPerPage,
      };

      const response = await makeRequest(
        "POST",
        "/campaigns/list",
        requestData
      );

      if (response) {
        const newData = response?.data?.data?.results || [];
        const totalItems = response?.data?.data?.total || 0;

        setData((prevData) => [...prevData, ...newData]);

        const updatedTotalPages = Math.ceil(totalItems / rowsPerPage);
        setTotalPages(updatedTotalPages);
      }
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }
  }

  // Debounce for search
  const debounce = (func, delay) => {
    let timeoutId;
    const debouncedFunction = (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };

    debouncedFunction.cancel = () => {
      clearTimeout(timeoutId);
    };

    return debouncedFunction;
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchCampaigns(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
    console.log(filter);
  };

  const handleFieldChange = (fieldName, value) => {
    setFilter((prevFormValue) => ({
      ...prevFormValue,
      [fieldName]: value,
    }));
  };

  const getCategoryName = (value) => {
    const category = categories.find((category) => category.value === value);
    return category ? category.name : value;
  };

  // Call fetchCampaigns to fetch new data whenever there is a change in filters
  useEffect(() => {
    const delayedFetchCampaigns = debounce(fetchCampaigns, 1000);

    setData([]);
    setPage(1);
    setLoading(true);
    delayedFetchCampaigns();

    return () => {
      // Cleanup
      delayedFetchCampaigns.cancel();
    };
  }, [
    filter.name,
    filter.campaignCategory,
    rowsPerPage,
    paidChecked,
    barterChecked,
    activeChecked,
    pastChecked,
    platformCheck,
  ]);

  return (
    <ThemeProvider theme={customTheme}>
      <Navbar />
      <div className="w-11/12 mx-auto mt-8">
        <div className="flex justify-between gap-4 items-center">
          <p className=" text-2xl font-medium">Campaign List</p>
          <div className="flex border gap-3 items-center px-4 py-2 rounded-md w-1/3 text-gray-500 bg-white">
            <FaSearch />
            <input
              placeholder="Search"
              className="border-none outline-none text-black w-full"
              name="name"
              value={filter.name}
              onChange={handleChange}
            />
          </div>
          <div className="flex gap-3">
            <Link
              className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
              to="/createcampaign"
            >
              CREATE CAMPAIGN
            </Link>
          </div>
        </div>

        <div className="flex justify-around gap-8 py-8">
          {/* Filters */}
          <div className="shadow-md bg-white px-3 py-2 rounded-md h-fit w-1/5">
            {/* Campaign Status */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">
                Campaign Status
              </h2>
              <FormGroup className="ml-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={activeChecked}
                      onChange={() => setActiveChecked(!activeChecked)}
                    />
                  }
                  label={
                    <span className="text-[#000000B2]">Active Campaign</span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={pastChecked}
                      onChange={() => setPastChecked(!pastChecked)}
                    />
                  }
                  label={
                    <span className="text-[#000000B2]">Past Campaign</span>
                  }
                />
              </FormGroup>
            </div>

            {/* Campaign Type */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">
                Campaign Type
              </h2>
              <FormGroup className="ml-3">
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={paidChecked}
                      onChange={() => setPaidChecked(!paidChecked)}
                    />
                  }
                  label={<span className="text-[#000000B2]">Paid</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      value={barterChecked}
                      onChange={() => setBarterChecked(!barterChecked)}
                    />
                  }
                  label={<span className="text-[#000000B2]">Barter</span>}
                />
              </FormGroup>
            </div>

            {/* Platform */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Platform</h2>
              <FormGroup>
                <div className="ml-3 flex flex-col">
                  <FormControlLabel
                    label={<span className="text-[#000000B2]">Instagram</span>}
                    control={
                      <Checkbox
                        size="small"
                        checked={platformCheck[0]}
                        onChange={(event) => {
                          setPlatformCheck([
                            event.target.checked,
                            platformCheck[1],
                          ]);
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    label={<span className="text-[#000000B2]">Youtube</span>}
                    control={
                      <Checkbox
                        size="small"
                        checked={platformCheck[1]}
                        onChange={(event) => {
                          setPlatformCheck([
                            platformCheck[0],
                            event.target.checked,
                          ]);
                        }}
                      />
                    }
                  />
                </div>
              </FormGroup>
            </div>

            {/* Campaign Category */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">
                Campaign Categories
              </h2>
              <div>
                {categories.map((item) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        checked={filter.campaignCategory.includes(item.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleFieldChange("campaignCategory", [
                              ...filter.campaignCategory,
                              item.value,
                            ]);
                          } else {
                            handleFieldChange(
                              "campaignCategory",
                              filter.campaignCategory.filter(
                                (category) => category !== item.value
                              )
                            );
                          }
                        }}
                      />
                      <span className="text-[#000000B2]">{item.name}</span>
                    </Stack>
                  </>
                ))}
              </div>
            </div>
          </div>

          {/* Data based on filters */}
          <div className="flex flex-col w-4/5 gap-4 justify-between">
            {/* Data cards */}
            <div className="flex flex-col w-full gap-6 mb-6">
              {data?.length > 0 ? (
                data.map((data, index) => (
                  <div
                    key={index}
                    className="flex w-full h-[150px] mx-auto shadow-md px-4 py-3 gap-4 rounded-md bg-white"
                  >
                    <div className="flex items-center">
                      <img
                        src={data.image || placeholder}
                        alt={data.name}
                        className="w-[220px] h-full rounded-md"
                      />
                    </div>
                    <div className="flex gap-2 flex-col justify-between w-full">
                      <div className="flex justify-between">
                        <div className="max-w-[80%] text-slate-500 flex flex-col gap-1">
                          <p className="flex gap-2 items-center">
                            <span className="text-black text-xl font-medium">
                              {data.name?.length > 100
                                ? data.name?.substring(0, 100) + "..."
                                : data.name}
                            </span>
                            <span className="text-[#0000008C] text-sm">
                              {new Date(data.startDate)
                                .toLocaleDateString("en-IN", {
                                  day: "numeric",
                                  month: "long",
                                })
                                .replace(/ /g, "/")}
                              {" - "}
                              {new Date(data.endDate)
                                .toLocaleDateString("en-IN", {
                                  day: "numeric",
                                  month: "long",
                                })
                                .replace(/ /g, "/")}
                            </span>
                          </p>
                          <p className="text-[#00000099] text-sm">
                            {data.description?.length > 100
                              ? data.description?.substring(0, 100) + "..."
                              : data.description}
                          </p>
                        </div>
                        <div className="flex flex-col items-end gap-1">
                          <p
                            className={
                              "text-center py-1 px-4 text-[0.8rem] font-medium rounded-lg " +
                              (data.campaignStatus === "PAST"
                                ? "bg-[#00000010] text-slate-600"
                                : "bg-[#36B37E1A] text-[#36B37E]")
                            }
                          >
                            {data.campaignStatus}
                          </p>
                          <p className="text-[#0000008C] text-sm font-medium">
                            {getCategoryName(data.campaignCategory)}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex gap-4">
                          {data.platform.map((platform, index) => (
                            <button
                              key={index}
                              className="flex items-center gap-1 px-2 bg-[#2DA9B21A] text-[#000000CC] rounded-md text-sm   font-medium h-[30px]"
                            >
                              {platform === "INSTAGRAM" && (
                                <>
                                  <img
                                    src={insta}
                                    className="w-[20px] h-[20px]"
                                  />
                                  Instagram
                                </>
                              )}
                              {platform === "YOUTUBE" && (
                                <>
                                  <FaYoutube className="text-2xl text-[#FF0000]" />
                                  Youtube
                                </>
                              )}
                            </button>
                          ))}
                        </div>
                        <div className="flex">
                          <button
                            className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] rounded-md px-2 text-white text-sm font-medium h-[30px]"
                            onClick={() =>
                              navigate("/campaignDetail?id=" + data._id)
                            }
                          >
                            VIEW DETAILS
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className=" mt-20 flex  justify-center items-center">
                  {!loading && <img src={image1} alt="Placeholder" />}
                </div>
              )}
              {loading && <Loader />}
            </div>

            {/* Pagination */}
            {data?.length > 0 && (
              <div className="flex justify-center">
                <Stack spacing={2} direction="row" alignItems="center">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                  <TablePagination
                    component="div"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={() => null}
                    rowsPerPageOptions={[5, 10, 15]}
                    slotProps={{
                      actions: {
                        nextButton: {
                          style: {
                            display: "none",
                          },
                        },
                        previousButton: {
                          style: {
                            display: "none",
                          },
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default CampaignList;
