import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import signlogoImage from "../../asset/signlogo.png";
import authImage from "../../Assets/auth.jpg";
import notify from "../../utils/notification";
import { TextField, ThemeProvider } from "@mui/material";
import { customTheme } from "../../utils/theme";
import Design from "./Design";

const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [detail, setDetails] = useState({
    name: "",
    email: "",
    otp: "",
    password: "",
    agency: {
      name: "",
    },
  });

  const sendotp = async () => {
    if (!detail.email) {
      notify("error", "Email can't be empty!");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const valid = emailRegex.test(detail.email);

    if (!valid) {
      notify("error", "Invalid email format!");
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(
        "https://agencyapi.getmentore.com/agent/auth/send-email",
        { email: detail.email }
      );
      if (response) {
        notify(
          "success",
          "Mail Send! Don't forget to check spam folder for otp"
        );
      }
    } catch (error) {
      console.log("error", error);
      notify(
        "error",
        error.response.data.message || "Error sending mail, try again!"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        "https://agencyapi.getmentore.com/agent/auth/signup",
        detail
      );
      if (response) {
        notify("success", "Signup Successful!");
        navigate("/signin");
      }
    } catch (error) {
      console.log("error", error);
      notify("error", error.response.data.message || "Error, try again!");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);

  return (
    <ThemeProvider theme={customTheme}>
      <div className="flex justify-center items-center h-screen">
        <form
          className="flex w-5/6 rounded-md overflow-hidden"
          onSubmit={handleSignUp}
        >
          <Design />

          <div className="w-1/2 flex flex-col justify-center lg:px-8 px-4 py-4 gap-4 bg-white">
            <div className="flex gap-1 items-center">
              <img src={signlogoImage} alt="Echio Logo" className="w-8 h-8" />
              <span className="text-2xl font-medium text-gray-600">Echio</span>
            </div>

            <div className="my-2">
              <p className="text-xl">Sign Up</p>
              <p className="text-sm text-gray-600">to access Echio Home</p>
            </div>

            <TextField
              id="name"
              name="name"
              label="Name"
              variant="outlined"
              placeholder="Name"
              value={detail.name}
              onChange={handleChange}
              required
              fullWidth
              size="small"
            />

            <div className="flex gap-2 w-full">
              <TextField
                id="email"
                type="email"
                name="email"
                label="Email"
                variant="outlined"
                placeholder="support@echio.in"
                value={detail.email}
                onChange={handleChange}
                className="w-3/5"
                required
                size="small"
              />
              <TextField
                id="otp"
                label="Otp"
                variant="outlined"
                name="otp"
                placeholder="otp"
                className="w-1/5"
                value={detail.otp}
                onChange={handleChange}
                required
                size="small"
              />
              <button
                type="button"
                onClick={sendotp}
                className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-2 py-2 w-1/5"
                disabled={loading}
              >
                Send otp
              </button>
            </div>

            <TextField
              id="agencyName"
              label="Agency Name"
              variant="outlined"
              name="agency"
              value={detail.agency.name}
              onChange={(e) =>
                setDetails((prev) => ({
                  ...prev,
                  agency: { ...prev.agency, name: e.target.value },
                }))
              }
              required
              fullWidth
              size="small"
            />

            <TextField
              id="password"
              name="password"
              label="Password"
              variant="outlined"
              type="password"
              placeholder="**************"
              value={detail.password}
              onChange={handleChange}
              required
              fullWidth
              size="small"
            />

            <button
              type="submit"
              className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white mt-2 py-2 rounded-md"
              disabled={loading}
            >
              {!loading ? "CREATE YOUR ACCOUNT" : "Loading..."}
            </button>

            <div className="text-sm flex items-center flex-col gap-2">
              <p>
                Already a User?{" "}
                <a
                  href="/signin"
                  className="text-[#2DA9B2] hover:text-[#2da9b2d8] font-bold"
                >
                  Login into your account
                </a>
              </p>
              <p>
                Already a member of agency?{" "}
                <a
                  href="/agent/signup"
                  className="text-[#2DA9B2] hover:text-[#2da9b2d8] font-bold"
                >
                  Create Your Agent account
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
    </ThemeProvider>
  );
};

export default SignUp;
