import axios from "axios";

const backend = process.env.REACT_APP_BACKEND;

const useApi = () => {
  // Func for making request to the backend server
  const makeRequest = async (
    method = "GET",
    endpoint = "/",
    data = null,
    token = null
  ) => {
    if (token === null) {
      token = localStorage.getItem("token");
    }

    if (!token) {
        window.location = "/signIn"
    }

    try {
      const config = {
        method: method,
        url: backend + endpoint,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        data: data,
      };
      const result = await axios(config);

      return result;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return { makeRequest };
};

export default useApi;
