import Mms from '@mui/icons-material/Mms';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { Box, ThemeProvider } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { capitalize, formatCurrency, formatNumber, formatString } from '../../utils/formatNumber';
import { FaYoutube } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import useApi from '../../hooks/useApi';
import { setBrandSidebarData } from '../../redux/slices/campaignSlice';
import { toggleBrandSidebar } from '../../redux/slices/componentSlice';
import notify from '../../utils/notification';
import Loader from '../common/Loader';
import insta from "../../Assets/instagram.png";
import { customTheme } from '../../utils/theme';

const headCells = [
    {
        id: "name",
        numeric: false,
        disablePadding: true,
        label: "Name",
    },
    {
        id: "platform",
        numeric: false,
        disablePadding: false,
        label: "Platform",
    },
    {
        id: "followers",
        numeric: false,
        disablePadding: false,
        label: "Reach",
    },
    {
        id: "gender",
        numeric: false,
        disablePadding: false,
        label: "Gender",
    },
    {
        id: "price",
        numeric: false,
        disablePadding: false,
        label: "Price",
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "Status",
    },
];

const deliveryPrice = [
    { type: "insta", name: "stories", value: "INSTAGRAM_STORIES" },
    { type: "insta", name: "post", value: "INSTAGRAM_POSTS" },
    { type: "insta", name: "reel", value: "INSTAGRAM_REELS" },
    { type: "yt", name: "shorts", value: "YOUTUBE_SHORTS" },
    { type: "yt", name: "dedicatedReel", value: "YOUTUBE_DEDICATED_REELS" },
    { type: "yt", name: "integratedVideo", value: "YOUTUBE_INTEGRATED_VIDEOS" },
    { type: "yt", name: "streaming", value: "YOUTUBE_STREAMS" },
];

function EnhancedTableHead(props) {
    const {
        numSelected,
        onSelectAllClick,
        status
    } = props;

    return (
        <>
            {numSelected > 0 ? (
                <TableHead style={{ background: "#2DA9B23b" }}>
                    <TableRow>
                        <TableCell padding="checkbox">
                            {/* <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0}
                                checked={numSelected > 0}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    "aria-label": "select all desserts",
                                }}
                            /> */}
                        </TableCell>
                        <TableCell>{numSelected} selected</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell align="right">
                        </TableCell>
                    </TableRow>
                </TableHead>
            ) : (
                <TableHead style={{ background: "lightgrey" }}>
                    <TableRow>
                        <TableCell padding="checkbox">
                            {/* <Checkbox
                                color="primary"
                                indeterminate={numSelected > 0}
                                checked={numSelected > 0}
                                onChange={onSelectAllClick}
                                inputProps={{
                                    "aria-label": "select all desserts",
                                }}
                            /> */}
                        </TableCell>
                        {headCells.map((headCell) => (
                            <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? "right" : "left"}
                                padding={headCell.disablePadding ? "none" : "normal"}
                            >
                                {headCell.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
            )}
        </>
    );
}

export default function BrandCampaignTable({ token, status, id, delTypes }) {
    const dispatch = useDispatch();
    const { makeRequest } = useApi();
    const [selected, setSelected] = useState([]);
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(-1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openDialog, setOpenDialog] = useState(false);
    const [approve, setApprove] = useState(false);
    const [influencer, setInfluencer] = useState("");

    const fetchInfluencers = async () => {
        if (!id) return;
        setLoading(true);
        let endpoint = "/works/list";
        let filters = {
            filters: {
                status: [],
                campaignId: id
            },
            expand: {
                influencer: {},
            },
            pageSize: rowsPerPage,
            page: page + 1,
        };
        if (status === "shared") {
            filters.filters.status.push("SUGGESTED");
        } else if (status === "ongoing") {
            filters.filters.status.push("ACTIVE");
        }

        try {
            const res = await makeRequest("POST", endpoint, filters, token);
            if (res) {
                setTotal(res.data.data?.total);
                const results = res.data.data.results;
                setRows(
                    results.map((item) => ({
                        responseId: item._id,
                        status: item.status,
                        campaignId: item.campaignId,
                        influencerId: item.influencerId,
                        workId: item._id,
                        token: token,
                        ...item.influencer,
                        agencyPrice: item.agencyPrice,
                        instagramPrice: item.instagramPrice,
                        youtubePrice: item.youtubePrice,
                    }))
                );
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, item) => {
        const selectedIndex = selected.indexOf(item);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChatButtonClick = (e, row) => {
        dispatch(setBrandSidebarData(row));
        dispatch(toggleBrandSidebar())
    }

    const handleDialogOpen = (row, approve) => {
        setOpenDialog(true);
        setInfluencer(row)
        setApprove(approve);
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const priceObj = (type, instagramPrice, youtubePrice) => {
        const item = deliveryPrice.find((item) => item.value === type);

        if (type.includes("INSTAGRAM") && instagramPrice) {
            const priceItem = Object.entries(instagramPrice).find(
                ([key, value]) => key === item.name
            );
            if (priceItem) return { name: item.name, value: priceItem[1] };
        } else if (type.includes("YOUTUBE") && youtubePrice) {
            const priceItem = Object.entries(youtubePrice).find(
                ([key, value]) => key === item.name
            );
            if (priceItem) return { name: item.name, value: priceItem[1] };
        }
        return { name: item.name };
    };

    const isSelected = (item) => selected.indexOf(item) !== -1;

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;


    const selectInfluencer = async (row, approve) => {
        const reqData = {
            influencerIds: [row._id],
            approve: approve,
        }

        try {
            const res = await makeRequest("POST", "/form/brand/review", reqData, token);
            if (res) {
                notify("success", approve ? "Influencer is selected!" : "Influencer is rejected!");
                fetchInfluencers();
                setInfluencer("");
                setOpenDialog(false);
            }
        } catch (error) {
            console.log(error);
            notify("error", "Error, try again!")
        }
        setSelected([]);
    }

    useEffect(() => {
        setSelected([]);
        fetchInfluencers();
    }, [page, rowsPerPage, status, id]);

    return (
        <ThemeProvider theme={customTheme}>
            <Box sx={{ width: "100%", mt: 2 }} className="shadow-md rounded-md">
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size="medium"
                        >
                            <EnhancedTableHead
                                setSelected={setSelected}
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                status={status}
                                id={id}
                                fetchInfluencers={fetchInfluencers}
                            />
                            <TableBody>
                                {!loading ? rows.length > 0 ? (
                                    rows.map((row, index) => {
                                        const isItemSelected = isSelected(row);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                selected={isItemSelected}
                                                sx={{ cursor: "pointer" }}
                                            >
                                                <TableCell padding="checkbox">
                                                    {/* <Checkbox
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            "aria-labelledby": labelId,
                                                        }}
                                                    /> */}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems={"center"}
                                                        spacing={"10px"}
                                                    >
                                                        <Avatar
                                                            alt={row.name}
                                                            src={row.image}
                                                            variant="circle"
                                                        />
                                                        <Typography sx={{ alignItems: "center" }}>
                                                            {row.name}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    {row.instagram && (
                                                        <button className="flex items-center rounded-lg p-1 gap-1">
                                                            <img src={insta} className="w-[20px] h-[20px]" />
                                                            Instagram
                                                        </button>
                                                    )}
                                                    {row.youtube && (
                                                        <button className="flex items-center rounded-lg p-1 gap-1">
                                                            <FaYoutube className="text-2xl text-[#FF0000]" />
                                                            Youtube
                                                        </button>
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <p>
                                                        Follower {formatNumber(row.instagram?.audience)}
                                                    </p>
                                                    <p>
                                                        Subscriber {formatNumber(row.youtube?.audience)}
                                                    </p>
                                                </TableCell>
                                                <TableCell>{capitalize(row.gender)}</TableCell>
                                                <TableCell>
                                                    <div className="flex gap-2 flex-col">
                                                        {delTypes?.map((item, i) => {
                                                            const obj = priceObj(
                                                                item,
                                                                row.instagramPrice,
                                                                row.youtubePrice
                                                            );
                                                            return (
                                                                <p key={i}>
                                                                    <span>{formatString(obj.name)}</span>{" "}
                                                                    <span>{formatCurrency(obj.value)}</span>
                                                                </p>
                                                            );
                                                        })}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {status === "shared" ? <div className="flex gap-3">
                                                        <button className="px-4 py-2 border border-red-700 text-red-700 rounded-md" onClick={() => handleDialogOpen(row, false)}>Reject</button>
                                                        <button className="px-4 py-2 bg-[#36bd36] text-white rounded-md" onClick={() => handleDialogOpen(row, true)}>Select</button>
                                                        <Dialog
                                                            open={openDialog}
                                                            onClose={handleDialogClose}
                                                            aria-labelledby="responsive-dialog-title"
                                                        >
                                                            <DialogContent>
                                                                <DialogContentText>
                                                                    Are you sure you want to {approve ? "select" : "reject"} the influencer?
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button onClick={handleDialogClose}>No</Button>
                                                                <Button onClick={() => selectInfluencer(influencer, approve)} autoFocus>
                                                                    Yes
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </div> : (() => {
                                                        switch (row.status) {
                                                            case "SHORTLISTED":
                                                                return (
                                                                    <p className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                                                        Shortlisted
                                                                    </p>
                                                                );
                                                            case "RESPONDED":
                                                                return (
                                                                    <p className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                                                        Responded
                                                                    </p>
                                                                );
                                                            case "REACHED_OUT":
                                                                return (
                                                                    <p className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                                                        Reached Out
                                                                    </p>
                                                                );
                                                            case "SUGGESTED":
                                                                return (
                                                                    <p className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                                                        Suggested
                                                                    </p>
                                                                );
                                                            case "ACTIVE":
                                                                return (
                                                                    <>
                                                                        <span className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                                                            Active
                                                                        </span>&emsp;
                                                                        {<Mms size="small" color="success"
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                zIndex: 100,
                                                                            }}
                                                                            onClick={(e) => {
                                                                                handleChatButtonClick(e, row)
                                                                            }}
                                                                        />}

                                                                    </>
                                                                );
                                                            case "COMPLETED":
                                                                return (
                                                                    <>
                                                                        <span className="bg-[lightgray] rounded-full py-1 px-2 w-fit text-white">
                                                                            Completed
                                                                        </span>&emsp;
                                                                        {<Mms size="small" color="success"
                                                                            style={{
                                                                                cursor: "pointer",
                                                                                zIndex: 100,
                                                                            }}
                                                                            onClick={(e) => {
                                                                                handleChatButtonClick(e, row)
                                                                            }}
                                                                        />}

                                                                    </>
                                                                );
                                                            case "DECLINED":
                                                                return (
                                                                    <p className="bg-[red] rounded-full py-1 px-2 w-fit text-white">
                                                                        Rejected
                                                                    </p>
                                                                );
                                                        }
                                                    })()}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                ) : (
                                    <TableCell colSpan={8} height={200} align="center">
                                        No influencers to display!
                                    </TableCell>
                                ) : (
                                    <TableCell colSpan={8} height={200} align="center">
                                        <Loader />
                                    </TableCell>
                                )}
                                {emptyRows > 0 && (
                                    <TableRow
                                        style={{
                                            height: 10 * emptyRows,
                                        }}
                                    >
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </ThemeProvider>
    );
}
