import React from "react";
import { Pie } from "react-chartjs-2";

const pieChartData = {
  labels: ["India", "Mexico", "Canada", "Other"],
  datasets: [
    {
      data: [60, 25, 15, 10],
      backgroundColor: ["rgba(0, 0, 0, 0.8)", "#B1E3FF", "#A1E3CB", "#A8C5DA"],
    },
  ],
};

const pieChartOptions = {
  cutout: "50%",
  plugins: {
    legend: {
      position: "right",
    },
  },
  maintainAspectRatio: false,
  align: "center",
};

const PieChart = () => {
  return (
    <>
      <Pie data={pieChartData} options={pieChartOptions} className="!h-44" />
    </>
  );
};

export default PieChart;
