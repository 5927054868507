import React from "react";
import Carousel from "react-material-ui-carousel";
import Frame19 from "../../asset/welcomeFrame.jpeg";
import Frame20 from "../../asset/frame20.jpg";
import frame40 from "../../asset/frame40.jpg";

const images = [Frame19, Frame20, frame40, Frame20];
const indicatorContainerStyle = {
  position: "absolute",
  top: "1%",
  left: "11%",
  transform: "translateX(-50%)",
  zIndex: 1,
};

const HomeCarousel = () => {
  return (
    <>
      <Carousel
        animation="slide"
        swipe={true}
        navButtonsAlwaysVisible={true}
        indicators={true}
        indicatorContainerProps={{
          style: indicatorContainerStyle,
        }}
        className="w-1/3 h-[15rem]"
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`image ${index}`}
            className="h-[15rem]"
          />
        ))}
      </Carousel>
    </>
  );
};

export default HomeCarousel;
