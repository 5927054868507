import React, { useEffect, useState } from "react";
import Navbar from "../component/common/Navbar";
import {
  Avatar,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";
import useApi from "../hooks/useApi";
import useFileUpload from "../hooks/useFileUpload";
import notify from "../utils/notification";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { MuiTelInput } from "mui-tel-input";
import Footer from "./common/Footer";
import { customTheme } from "../utils/theme";

const validFileTypes = [
  "image/svg+xml",
  "image/png",
  "image/jpeg",
  "image/gif",
  "video/mp4",
  "video/webm",
  "video/ogg",
];

const categories = [
  { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
  { name: "Educational", value: "EDUCATIONAL" },
  { name: "Entertainment", value: "ENTERTAINMENT" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Lifestyle", value: "LIFESTYLE" },
  { name: "Fashion", value: "FASHION" },
  { name: "Gaming", value: "GAMING" },
  { name: "Auto", value: "AUTO" },
  { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
  { name: "Other", value: "OTHER" },
];

const EditProfile = () => {
  const [details, setDetail] = useState({
    name: "",
    email: "",
    description: "",
    category: "",
    gst: "",
    pan: "",
    website: "",
    logo: "",
    phoneNo: "",
    address: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileInputRef, setFileInputRef] = useState(null);
  const { makeRequest } = useApi();
  const { uploadToS3 } = useFileUpload();
  const navigate = useNavigate();

  const handleImageClick = () => {
    fileInputRef?.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDetail((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("File selected:", file);
      setSelectedFile(file);
      uploadImage(file);
    }
  };

  const handlePhoneChange = (value) => {
    setDetail((prev) => ({ ...prev, phoneNo: value }));
  };

  // Upload Campaign Image
  const uploadImage = async (file) => {
    try {
      const response = await makeRequest("GET", "/agency/logo-url");
      if (response) {
        const url = response.data.data.putUrl;
        if (url) {
          // Extracting image url
          const imageURL = response.data.data.getUrl;

          // Uploading the file to server
          uploadToS3(url, file);

          setDetail((prev) => ({
            ...prev,
            logo: imageURL,
          }));
          notify("info", "Logo Uploaded!", 2000);
        }
      }
    } catch (error) {
      console.log("Error");
      notify("error", "Image Upload Failure, try again!", 2000);
      setSelectedFile(null);
    }
  };

  const fetchDetails = async () => {
    try {
      const res = await makeRequest("GET", "/agency/profile");
      console.log(res.data.data);
      setDetail(res.data.data);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await makeRequest("POST", "/agency/update", details);
      if (res) {
        notify("success", "Profile Details updated!");
        fetchDetails();
      }
    } catch (error) {
      console.log(error);
      notify("error", "Error updating details!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <>
      <Navbar />
      <div className="flex flex-col gap-4 w-11/12 mx-auto">
        <div>
          <button
            className="pt-8 pb-4 text-[#2DA9B2] flex items-center"
            onClick={() => navigate(-1)}
          >
            <FaArrowLeft />
            Back
          </button>
        </div>
        <p className=" text-2xl ml-4 font-medium">Edit Profile</p>
        <div className="flex justify-center">
          <ThemeProvider theme={customTheme}>
            <form
              onSubmit={handleSubmit}
              className="w-5/6 flex flex-col gap-4 mx-4 shadow-md p-3 px-4 mb-6 bg-white rounded-md"
            >
              <h1 className="mt-2 text-lg" id="basicDetails">
                Basic Details
              </h1>

              {/* Logo upload */}
              <div className="w-full flex flex-col gap-2">
                <Avatar
                  variant="soft"
                  src={
                    selectedFile
                      ? URL.createObjectURL(selectedFile)
                      : details.logo
                  }
                  onClick={handleImageClick}
                  alt="Logo"
                  sx={{ width: "150px", height: "150px" }}
                />

                <div>
                  <Button
                    component="label"
                    variant="outlined"
                    className="text-white rounded-md"
                  >
                    UPLOAD LOGO
                    <input
                      type="file"
                      className="hidden"
                      onChange={handleFileChange}
                      accept={validFileTypes
                        .filter((type) => type.startsWith("image/"))
                        .join(",")}
                    />
                  </Button>
                </div>
              </div>

              <div className="w-full flex gap-2">
                <TextField
                  id="name"
                  name="name"
                  label="Agency Name"
                  variant="outlined"
                  placeholder="Enter Agency Name"
                  value={details.name}
                  onChange={handleChange}
                  className="w-1/2"
                />

                <TextField
                  id="email"
                  type="email"
                  name="email"
                  label="Agency Email"
                  variant="outlined"
                  placeholder="Enter Agency Email"
                  value={details.email}
                  onChange={handleChange}
                  className="w-1/2"
                />
              </div>

              <TextField
                id="desc"
                name="description"
                label="Agency Description"
                variant="outlined"
                placeholder="Agency Description"
                value={details.description}
                onChange={handleChange}
              />

              <MuiTelInput
                id="phone"
                name="phoneNo"
                label="Agency Phone No"
                value={details.phoneNo}
                onChange={handlePhoneChange}
                placeholder="Your Phone Number with country code like +91"
              />

              <TextField
                id="address"
                name="address"
                label="Agency Address"
                variant="outlined"
                placeholder="Enter Agency Address"
                value={details.address}
                onChange={handleChange}
              />

              <div className="w-full flex gap-2">
                <TextField
                  id="website"
                  name="website"
                  label="Agency Website"
                  variant="outlined"
                  placeholder="Agency Website"
                  value={details.website}
                  onChange={handleChange}
                  className="w-1/2"
                />
                <TextField
                  id="gst"
                  name="gst"
                  label="Agency GST No"
                  variant="outlined"
                  placeholder="Agency GST No"
                  value={details.gst}
                  onChange={handleChange}
                  className="w-1/2"
                />
              </div>

              <div className="w-full flex gap-2">
                {/* Comp Category */}
                <FormControl className="w-1/2">
                  <InputLabel id="categoryLabel">Agency Category</InputLabel>
                  <Select
                    labelId="categoryLabel"
                    id="category"
                    name="category"
                    value={details.category}
                    label="Agency Category"
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {categories.map((item, i) => (
                      <MenuItem value={item.name} key={i}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  id="pan"
                  name="pan"
                  label="Agency PAN No"
                  variant="outlined"
                  placeholder="Agency PAN"
                  value={details.pan}
                  onChange={handleChange}
                  className="w-1/2"
                />
              </div>

              <div className="text-right">
                <button
                  type="submit"
                  className="bg-[#2DA9B2] text-white rounded-md py-2 px-4 m-3"
                  disabled={loading}
                >
                  {!loading ? "SAVE" : "Loading..."}
                </button>
              </div>
            </form>
          </ThemeProvider>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditProfile;
