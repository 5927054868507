import { Box, Popover, Tab, Tabs, ThemeProvider, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaCheck, FaCopy, FaYoutube } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import placeholder from "../../asset/placeholder.webp";
import useApi from '../../hooks/useApi';
import { setCurrentCampaign } from '../../redux/slices/campaignSlice';
import notify from '../../utils/notification';
import DataTable from '../common/DataTable';
import Navbar from '../common/Navbar';
import Loader from '../common/Loader';
import insta from "../../Assets/instagram.png";
import Footer from '../common/Footer';
import { customTheme } from '../../utils/theme';

const frontend = process.env.REACT_APP_CLIENT;

const CopyClipboard = ({ text }) => {
    const [copy, setCopy] = useState(false);
    const copyText = async () => {
        try {
            await navigator.clipboard.writeText(text);
            setCopy(true);
            setTimeout(() => setCopy(false), 3000);
            notify("info", "Link Copied!", 1500);
        } catch (error) {
            console.log(error);
            notify("warning", "Error copying link, try again!", 1500);
        }
    }

    return (
        <span className="cursor-pointer ml-2">
            {!copy ? <FaCopy className="text-[#2DA9B2] hover:text-[#2da9b2d8]" onClick={copyText} /> : <FaCheck className='text-green-500' />}
        </span>
    )
}

const CampaignDetail = () => {
    const dispatch = useDispatch();
    const [clickout, setClickout] = useState(0);
    const [tabValue, setTabValue] = useState("shortlist");
    const navigate = useNavigate();
    const location = useLocation();
    const { makeRequest } = useApi();
    const campaignId = new URLSearchParams(location.search).get("id");
    const [item, setItem] = useState();
    const [token, setToken] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);

    const handleChange = (event, value) => {
        setTimeout(() => setTabValue(value), 150)

        if (value === "shortlist") setClickout(0);
        else if (value === "shared") setClickout(1);
        else if (value === "ongoing") setClickout(2);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }

    const getDetails = async () => {
        const requestData = {
            filters: {
                _id: [campaignId]
            },
            page: 1
        }
        const response = await makeRequest("POST", "/campaigns/list", requestData);
        setItem(response.data.data.results[0]);
        dispatch(setCurrentCampaign(response.data.data.results[0]));
        console.log(response.data.data.results[0]);
    }

    const shareLink = async (event) => {
        setAnchorEl(event.currentTarget);
        try {
            const res = await makeRequest("GET", "/influencers/suggested-token?campaignId=" + campaignId)
            if (res) {
                setToken(res.data.data.token);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDetails();
    }, [])

    return (
        <>
            <Navbar />
            <div className="mt-10">
                <div className='flex w-11/12 mx-auto justify-between'>
                    <p className='text-2xl font-medium'>Campaign Detail</p>
                    <div className='flex gap-3 items-center'>
                        <Link to={"/payment?id=" + campaignId} className="text-[#2DA9B2] rounded-md px-3 h-[35px] flex items-center text-sm font-medium">PAYMENT RECORD</Link>
                        <>
                            <button className="border border-[#2DA9B2] text-[#2DA9B2] rounded-md px-3 h-[35px] flex items-center text-sm font-medium" onClick={shareLink}>SHARE WITH BRAND</button>
                            <Popover
                                id="share-popover"
                                className='w-2/3'
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <div className="flex flex-col gap-2 py-2 px-4 rounded-md text-center">
                                    <span>Share with the brand using this link:</span>
                                    <div className="flex items-center justify-between gap-2">
                                        <span className="overflow-hidden overflow-ellipsis whitespace-nowrap border px-1 rounded-md text-md border-[darkgray]" style={{ pointerEvents: "none" }}>
                                            {frontend + "/brand/campaignDetail?id=" + campaignId + "&token=" + token}
                                        </span>
                                        <CopyClipboard text={frontend + "/brand/campaignDetail?id=" + campaignId + "&token=" + token} />
                                    </div>
                                </div>
                            </Popover>
                        </>
                        <button className="text-white bg-[#2DA9B2] hover:bg-[#2da9b2d8] rounded-md px-3 h-[35px] flex items-center text-sm font-medium" onClick={() => navigate("/campaignInfluencerList?id=" + item._id)}>INVITE MORE</button>
                    </div>
                </div>

                <div className='flex w-11/12 mx-auto justify-between my-4'>
                    <button className=" text-[#2DA9B2] flex items-center" onClick={() => navigate("/campaignList")}><IoMdArrowRoundBack /> Back</button>
                </div>

                {/*main part*/}
                {item ? (
                    <div className="w-11/12 mx-auto mt-8 mb-16">
                        <div className="mt-8 flex gap-4 shadow-md px-4 py-4 rounded-t-md bg-white">
                            <img src={item.image || placeholder} className='w-[250px] h-[175px] rounded-md' alt="Campaign Img" />
                            <div className="flex justify-between w-full">
                                <div className="flex flex-col gap-3 max-w-[70%]">
                                    <div className="flex gap-4 items-center">
                                        <span className='font-medium text-lg'>
                                            {item.name}
                                        </span>
                                        <span className={
                                            "text-center py-[0.1rem] px-4 text-[0.8rem] font-medium rounded-full h-fit " +
                                            (item.campaignStatus === "PAST"
                                                ? "bg-[#00000010] text-slate-600"
                                                : "bg-[#36B37E1A] text-[#36B37E]")
                                        }>
                                            {item.campaignStatus}
                                        </span>
                                    </div>
                                    <p className="text-slate-500">{item.description}</p>
                                </div>
                                <div className="flex flex-col items-end gap-1 justify-between w-[30%]">
                                    <div>
                                        <p className="text-slate-500"><span className="font-medium">Start Date:</span> {new Date(item.startDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/ /g, '-')}</p>
                                        <p className="text-slate-500"><span className="font-medium">End Date:</span> {new Date(item.endDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/ /g, '-')}</p>
                                    </div>
                                    <div className="flex gap-3">
                                        {item.platform?.map((platform, index) => (
                                            <button
                                                key={index}
                                                className="flex items-center gap-1 px-2 bg-[#2DA9B21A] text-[#000000CC] rounded-md text-sm font-medium h-[30px]"
                                            >
                                                {platform === "INSTAGRAM" && (
                                                    <>
                                                        <img src={insta} className="w-[20px] h-[20px]" />
                                                        Instagram
                                                    </>
                                                )}
                                                {platform === "YOUTUBE" && (
                                                    <>
                                                        <FaYoutube className="text-2xl text-[#FF0000]" />
                                                        Youtube
                                                    </>
                                                )}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Influencer Table */}
                        <ThemeProvider theme={customTheme}>
                            <Box sx={{
                                background: "#2DA9B21A",
                                borderBottom: 1,
                                borderColor: "divider",
                                borderRadius: "0px 0px 6px 6px",
                                px: 2,
                            }}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    aria-label="campaign table tabs"
                                >
                                    <Tab
                                        label={
                                            <Tooltip title="View influencers shortlisted for potential collaboration in the campaign(visible only to you)">
                                                <span style={{ fontSize: "14px", fontWeight: 500 }}>SHORTLISTED INFLUENCERS</span>
                                            </Tooltip>
                                        }
                                        value="shortlist" sx={{ fontSize: "14px", fontWeight: 500 }} />
                                    <Tab
                                        label={
                                            <Tooltip title="Contains potential influencers shared with the brands">
                                                <span style={{ fontSize: "14px", fontWeight: 500 }}>SHARED INFLUENCERS</span>
                                            </Tooltip>
                                        }
                                        value="shared" sx={{ fontSize: "14px", fontWeight: 500 }} />
                                    <Tab
                                        label={
                                            <Tooltip title="Explore influencers currently active in the campaign">
                                                <span style={{ fontSize: "14px", fontWeight: 500 }}>ONGOING INFLUENCERS</span>
                                            </Tooltip>
                                        }
                                        value="ongoing" sx={{ fontSize: "14px", fontWeight: 500 }} />
                                </Tabs>
                            </Box>
                        </ThemeProvider>
                        <DataTable status={
                            clickout === 0
                                ? "shortlist"
                                : clickout === 1
                                    ? "suggest"
                                    : "ongoing"
                        }
                            type="campaign"
                            id={campaignId}
                            delTypes={item.deliveryTypes}
                        />
                    </div>
                ) : (
                    <Loader />
                )}
            </div >
            <Footer />
        </>
    )
}

export default CampaignDetail