import React, { useEffect, useState } from "react";
import image1 from "../../Assets/49e58d5922019b8ec4642a2e2b9291c2.png";
import {
  Avatar,
  Chip,
  Pagination,
  PaginationItem,
  Stack,
  TablePagination,
  ThemeProvider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { FaYoutube } from "react-icons/fa";
import insta from "../../Assets/instagram.png";
import {
  capitalize,
  formatCurrency,
  formatNumber,
} from "../../utils/formatNumber";
import reduce from "../../utils/reduce";
import Loader from "../common/Loader";
import { debounce } from "lodash";
import useFetch from "../../hooks/useFetch";
import { customTheme } from "../../utils/theme";

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "platform",
    numeric: false,
    disablePadding: false,
    label: "Platform",
  },
  {
    id: "followers",
    numeric: false,
    disablePadding: false,
    label: "Audience",
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Earned Amount",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

const brandDealHeadCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: false,
    label: "Brand Name",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Amount",
  },
  {
    id: "agencyAmount",
    numeric: false,
    disablePadding: false,
    label: "Agency Amount",
  },
  {
    id: "expectedDate",
    numeric: false,
    disablePadding: false,
    label: "Expected Date",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const { onboard } = props;

  return (
    <>
      <TableHead style={{ background: "lightgrey" }}>
        <TableRow>
          {onboard
            ? headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                >
                  {headCell.label}
                </TableCell>
              ))
            : brandDealHeadCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                >
                  {headCell.label}
                </TableCell>
              ))}
        </TableRow>
      </TableHead>
    </>
  );
}

export default function OnboardTable({ onboard, filterValues }) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [abortController, setAbortController] = useState(new AbortController());
  const { makeRequest } = useFetch();

  const parseMinMaxString = (string) => {
    if (!string || string === "") return {};
    const [min, max] = string.split(" - ").map(Number);
    return { min: min, max: max };
  };

  const fetchInfluencers = async (pageNumber = 1) => {
    setData([]);

    try {
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      setLoading(true);

      // Common filters
      const commonFilters = {
        fullName: filterValues.name,
        category: filterValues.category,
        gender: filterValues.gender,
        state: filterValues.state,
      };

      // Platform-specific filters
      let platformFilters = {};

      if (filterValues.platform === "INSTAGRAM") {
        platformFilters = {
          instagramEngagementRate: parseMinMaxString(
            filterValues.engagementRate
          ),
          instagramAverageLikes: parseMinMaxString(filterValues.avgLikes),
          instagramAverageViews: parseMinMaxString(filterValues.avgViews),
          instagramAudience: parseMinMaxString(filterValues.popularity),
        };
      } else if (filterValues.platform === "YOUTUBE") {
        platformFilters = {
          engagementRate: parseMinMaxString(filterValues.engagementRate),
          averageLikes: parseMinMaxString(filterValues.avgLikes),
          averageViews: parseMinMaxString(filterValues.avgViews),
          audience: parseMinMaxString(filterValues.popularity),
        };
      }

      const filters = {
        ...commonFilters,
        ...platformFilters,
      };

      const requestData = {
        filters: reduce(filters),
        options: {
          agencyCommission: true,
        },
        expand: {
          influencer: {},
          campaign: {},
          brand: {},
        },
        expandArray: {
          works: {},
        },
        page: pageNumber,
        pageSize: rowsPerPage,
      };
      if (onboard) {
        delete requestData.expand;
      } else {
        delete requestData.expandArray;
        delete requestData.options;
        requestData.expand.influencer.filters = requestData.filters;
        requestData.filters = {};
      }
      console.log("Request Data: ", requestData);
      const endpoint = onboard ? "/influencers/list" : "/works/list";
      const backend = "https://brandapi.getmentore.com";

      const response = await makeRequest(
        "POST",
        endpoint,
        requestData,
        null,
        newAbortController.signal,
        backend
      );

      if (response) {
        const newData = response?.data?.results || [];
        const totalItems = response?.data?.total || 0;

        setData((prevData) => [...prevData, ...newData]);

        const updatedTotalPages = Math.ceil(totalItems / rowsPerPage);
        setTotalPages(updatedTotalPages);
      }
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchInfluencers(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Call fetchInfluencers to fetch new data whenever there is a change in filters
  useEffect(() => {
    setLoading(true);

    const debouncedFetchInfluencers = debounce(() => {
      setData([]);
      setPage(1);
      fetchInfluencers();
    }, 1000);
    debouncedFetchInfluencers();

    // Cleanup
    return () => {
      // debouncedSearch.cancel();
      debouncedFetchInfluencers.cancel();
    };
  }, [filterValues, rowsPerPage, onboard]);

  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ width: "100%" }}>
        {data.length > 0 ? (
          <TableContainer>
            <Table
              sx={{ minWidth: 450 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead onboard={onboard} />
              <TableBody>
                {data.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      sx={{ cursor: "pointer", height: "100px" }}
                    >
                      <TableCell component="th" id={labelId} scope="row">
                        <Stack
                          direction={"row"}
                          alignItems={"center"}
                          spacing={"10px"}
                        >
                          <Avatar
                            alt={
                              onboard ? row.fullName : row.influencer?.fullName
                            }
                            src={
                              onboard
                                ? row.profilePicPath
                                : row.influencer?.profilePicPath
                            }
                            variant="circle"
                          />
                          <Typography sx={{ alignItems: "center" }}>
                            {onboard ? row.fullName : row.influencer?.fullName}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {onboard &&
                          (row.instagramReelPrice != 0 ||
                            row.instagramPostPrice != 0 ||
                            row.instagramStoriesPrice != 0) && (
                            <button className="flex items-center rounded-lg p-1 gap-1">
                              <img src={insta} className="w-[20px] h-[20px]" />
                              Instagram
                            </button>
                          )}
                        {onboard && row.reelPrice != 0 && (
                          <button className="flex items-center rounded-lg p-1 gap-1">
                            <FaYoutube className="text-2xl text-[#FF0000]" />
                            Youtube
                          </button>
                        )}
                        {!onboard && capitalize(row.brand?.brandName)}
                      </TableCell>
                      <TableCell>
                        {onboard ? (
                          <>
                            <p>
                              Follower{" "}
                              {formatNumber(
                                Number(row.instagramAudience) || undefined
                              )}
                            </p>
                            <p>
                              Subscriber{" "}
                              {formatNumber(Number(row.audience) || undefined)}
                            </p>
                          </>
                        ) : (
                          <p>{formatCurrency(row.price)}</p>
                        )}
                      </TableCell>
                      <TableCell>
                        {onboard
                          ? capitalize(row.gender)
                          : formatCurrency(row.priceDetails?.agencyCommission)}
                      </TableCell>

                      <TableCell>
                        {onboard ? (
                          <p>{formatCurrency(row.agencyCommission)}</p>
                        ) : (
                          <p>
                            {new Date(row.campaign?.endDate).toLocaleDateString(
                              "en-IN",
                              {
                                day: "numeric",
                                month: "numeric",
                                year: "2-digit",
                              }
                            )}
                          </p>
                        )}
                      </TableCell>

                      <TableCell>
                        {onboard ? (
                          <Chip
                            label="Onboarded"
                            size="small"
                            style={{
                              background: "#999999",
                              color: "#252525",
                            }}
                          />
                        ) : row.status === "COMPLETED" ? (
                          <Chip
                            label="Completed"
                            size="small"
                            style={{
                              background: "#14BC10",
                              color: "#fff",
                            }}
                          />
                        ) : row.status === "DECLINED" ? (
                          <Chip
                            label="Declined"
                            size="small"
                            style={{
                              background: "#d32f2f",
                              color: "#fff",
                            }}
                          />
                        ) : row.status === "ACCEPTED" ? (
                          <Chip
                            label="Accepted"
                            size="small"
                            style={{
                              background: "#00b148",
                              color: "#fff",
                            }}
                          />
                        ) : row.status === "PENDING_INVITE" ? (
                          <Chip
                            label="Pending Invite"
                            size="small"
                            style={{
                              background: "#999999",
                              color: "#252525",
                            }}
                          />
                        ) : (
                          <Chip
                            label="Shortlisted"
                            size="small"
                            style={{
                              background: "#6B8DE6",
                              color: "#fff",
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}

                {loading && (
                  <TableCell colSpan={8} align="center">
                    <Loader />
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className=" mt-20 flex justify-center items-center">
            {!loading && <img src={image1} alt="Placeholder" />}
            {loading && <Loader />}
          </div>
        )}
      </Box>
      {data?.length > 0 && (
        <div className="flex justify-center">
          <Stack spacing={2} direction="row" alignItems="center">
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => (
                <PaginationItem
                  slots={{
                    previous: ArrowBackIcon,
                    next: ArrowForwardIcon,
                  }}
                  {...item}
                />
              )}
            />
            <TablePagination
              component="div"
              count={totalPages}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelDisplayedRows={() => null}
              rowsPerPageOptions={[5, 10, 15, 25]}
              slotProps={{
                actions: {
                  nextButton: {
                    style: {
                      display: "none",
                    },
                  },
                  previousButton: {
                    style: {
                      display: "none",
                    },
                  },
                },
              }}
            />
          </Stack>
        </div>
      )}
    </ThemeProvider>
  );
}
