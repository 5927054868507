const FilePreview = ({ file, onRemove }) => {
    if (!file) {
        return null;
    }

    const handleRemove = () => {
        // Call the onRemove callback with the file as an argument
        onRemove(file);
    };

    return (
        <div className="relative">
            {file.type.startsWith("image/") && (
                <img src={URL.createObjectURL(file)} alt="Upload" />
            )}

            {file.type.startsWith("video/") && (
                <video width="320" height="240" controls>
                    <source src={URL.createObjectURL(file)} type={file.type} />
                </video>
            )}

            <button
                className="absolute top-2 right-2 bg-gray-200 text-gray-800 font-medium rounded-full cursor-pointer w-8 h-8"
                onClick={handleRemove}
            >
                X
            </button>
        </div>
    );
};

export default FilePreview;