import React, { useCallback, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import image1 from "../Assets/49e58d5922019b8ec4642a2e2b9291c2.png";
import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import {
  Box,
  Checkbox,
  Chip,
  Input,
  Menu,
  MenuItem,
  Pagination,
  PaginationItem,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  TablePagination,
  ThemeProvider,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { MdSort } from "react-icons/md";
import { GoSortDesc } from "react-icons/go";
import { customTheme } from "../utils/theme";
import reduce from "../utils/reduce";
import ProfileCard from "./Influencer/ProfileCard";
import Navbar from "./common/Navbar";
import { debounce, throttle } from "lodash";
import Loader from "../component/common/Loader";
import Footer from "./common/Footer";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const influencerFilters = [
  {
    id: "5",
    checkbox: "false",
    slider: "true",
    sliderRangeMin: 0,
    sliderRangeMax: 100,
    sliderLabelFormat: "%",
    textfield: "false",
    filterName: "Engagement Rate",
    name: "engagementRate",
    types: [""],
  },
  {
    id: "7",
    checkbox: "false",
    slider: "true",
    sliderRangeMin: 0,
    sliderRangeMax: 100000,
    sliderLabelFormat: "",
    textfield: "false",
    filterName: "Avg. Likes",
    name: "avgLikes",
    types: [""],
  },
  {
    id: "8",
    checkbox: "false",
    slider: "true",
    sliderRangeMin: 0,
    sliderRangeMax: 100000,
    sliderLabelFormat: "",
    textfield: "false",
    filterName: "Avg. Views",
    name: "avgViews",
    types: [""],
  },
];

const categories = [
  { name: "Finance And Investment", value: "FINANCE_AND_INVESTMENT" },
  { name: "Educational", value: "EDUCATIONAL" },
  { name: "Entertainment", value: "ENTERTAINMENT" },
  { name: "Technology", value: "TECHNOLOGY" },
  { name: "Travel", value: "TRAVEL" },
  { name: "Lifestyle", value: "LIFESTYLE" },
  { name: "Fashion", value: "FASHION" },
  { name: "Gaming", value: "GAMING" },
  { name: "Auto", value: "AUTO" },
  { name: "Health And Fitness", value: "HEALTH_AND_FITNESS" },
  { name: "Other", value: "OTHER" },
];

const platforms = [
  { name: "Youtube", value: "YOUTUBE" },
  { name: "Instagram", value: "INSTAGRAM" },
];

const popularity = [
  { value: "1000 - 9999", label: "Nano (1k-9k)" },
  { value: "10000 - 999999", label: "Micro (10k-999k)" },
  { value: "1000000 - 9999999", label: "Macro (1M-10M)" },
  { value: "10000000 - 100000000", label: "Mega (10M+)" },
];

const genders = [
  { name: "Male", value: "MALE" },
  { name: "Female", value: "FEMALE" },
];

const AgencyPool = () => {
  const navigate = useNavigate();
  const { makeRequest } = useFetch();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sliderValues, setSliderValues] = useState([0, 100]);
  const [clickout, setClickout] = useState(-1);
  const [sort, setSort] = useState(1);
  const [filterValues, setFilterValues] = useState({
    platform: "INSTAGRAM",
    popularity: "",
    name: "",
    category: [],
    gender: [],
    state: [],
  });
  const [abortController, setAbortController] = useState(new AbortController());
  const [sortEl, setSortEl] = useState(null);

  const parseMinMaxString = (string) => {
    if (!string || string === "") return {};
    const [min, max] = string.split(" - ").map(Number);
    return { min: min, max: max };
  };

  const fetchInfluencers = async (pageNumber = 1) => {
    setData([]);

    try {
      abortController.abort();
      const newAbortController = new AbortController();
      setAbortController(newAbortController);

      setLoading(true);

      // Common filters
      const commonFilters = {
        name: filterValues.name,
        category: filterValues.category,
        gender: filterValues.gender,
        state: filterValues.state,
      };

      // Platform-specific filters
      let platformFilters = {};

      if (filterValues.platform === "INSTAGRAM") {
        platformFilters = {
          "instagram.engagementRate": parseMinMaxString(
            filterValues.engagementRate
          ),
          "instagram.avgLikes": parseMinMaxString(filterValues.avgLikes),
          "instagram.avgViews": parseMinMaxString(filterValues.avgViews),
          "instagram.audience": parseMinMaxString(filterValues.popularity),
        };
      } else if (filterValues.platform === "YOUTUBE") {
        platformFilters = {
          "youtube.engagementRate": parseMinMaxString(
            filterValues.engagementRate
          ),
          "youtube.avgLikes": parseMinMaxString(filterValues.avgLikes),
          "youtube.avgViews": parseMinMaxString(filterValues.avgViews),
          "youtube.audience": parseMinMaxString(filterValues.popularity),
        };
      }

      const filters = {
        ...commonFilters,
        ...platformFilters,
      };

      const requestData = {
        filters: reduce(filters),
        page: pageNumber,
        pageSize: rowsPerPage,
      };
      if (clickout !== -1) {
        switch (clickout) {
          case 0:
            requestData.sort = {
              name: sort,
            };
            break;
          case 1:
            if (filterValues.platform === "INSTAGRAM")
              requestData.sort = {
                "instagram.audience": sort,
              };
            else
              requestData.sort = {
                "youtube.audience": sort,
              };
            break;
          case 2:
            if (filterValues.platform === "INSTAGRAM")
              requestData.sort = {
                "instagram.engagementRate": sort,
              };
            else
              requestData.sort = {
                "youtube.engagementRate": sort,
              };
            break;
          case 3:
            if (filterValues.platform === "INSTAGRAM")
              requestData.sort = {
                "instagram.avgLikes": sort,
              };
            else
              requestData.sort = {
                "youtube.avgLikes": sort,
              };
            break;
          case 4:
            if (filterValues.platform === "INSTAGRAM")
              requestData.sort = {
                "instagram.avgViews": sort,
              };
            else
              requestData.sort = {
                "youtube.avgViews": sort,
              };
            break;
          case 5:
            if (filterValues.platform === "INSTAGRAM")
              requestData.sort = {
                "instagram.reach": sort,
              };
            else
              requestData.sort = {
                "youtube.reach": sort,
              };
            break;
        }
      }
      console.log("Request Data: ", requestData);

      const response = await makeRequest(
        "POST",
        "/influencers/list",
        requestData,
        null,
        newAbortController.signal
      );

      if (response) {
        const newData = response?.data?.results || [];
        const totalItems = response?.data?.total || 0;

        setData((prevData) => [...prevData, ...newData]);

        const updatedTotalPages = Math.ceil(totalItems / rowsPerPage);
        setTotalPages(updatedTotalPages);
      }
    } catch (error) {
      console.log("error", error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = (fieldName, value) => {
    setFilterValues((prevFormValue) => ({
      ...prevFormValue,
      [fieldName]: value,
    }));
  };

  const handleSliderChange = (filterName, newValue) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [filterName]: newValue,
    }));
    const newFilterValues = {
      ...filterValues,
      [filterName]: `${
        newValue[0] ? (newValue[0] < 0 ? 0 : newValue[0]) : 0
      } - ${newValue[1]}`,
    };
    setFilterValues(newFilterValues);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    fetchInfluencers(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // Call fetchInfluencers to fetch new data whenever there is a change in filters
  useEffect(() => {
    setLoading(true);

    const debouncedFetchInfluencers = debounce(() => {
      setData([]);
      setPage(1);
      fetchInfluencers();
    }, 1000);
    debouncedFetchInfluencers();

    // Cleanup
    return () => {
      // debouncedSearch.cancel();
      debouncedFetchInfluencers.cancel();
    };
  }, [filterValues, sort, clickout, rowsPerPage]);

  return (
    <ThemeProvider theme={customTheme}>
      <Navbar />
      <div className="w-11/12 mx-auto mt-8">
        <div className="flex justify-between gap-4 items-center">
          <p className=" text-2xl font-medium">Agency Pool</p>
          <div className="flex border gap-3 items-center px-4 py-2 rounded-md w-1/3 text-gray-500 bg-white">
            <FaSearch />
            <input
              placeholder="Search"
              className="border-none outline-none text-black w-full"
              value={filterValues.name}
              onChange={(e) => handleFieldChange("name", e.target.value)}
            />
          </div>
          <div className="flex gap-3">
            <button
              className="border border-[#2DA9B2] text-[#2DA9B2] rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
              onClick={() => navigate("/addInfluencer")}
            >
              ADD SINGLE INFLUENCER
            </button>
            <button
              className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
              onClick={() => navigate("/bulkInfluencer")}
            >
              ADD INFLUENCER IN BULK
            </button>
          </div>
        </div>

        <div className="flex justify-around gap-8 py-8">
          {/* Filters */}
          <div className="shadow-md bg-white px-4 pt-2 pb-8 h-fit w-1/5 rounded-md">
            {/* Platform */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Platform</h2>
              <RadioGroup defaultValue="INSTAGRAM" name="radio-buttons-group">
                {platforms.map((plat) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Radio
                        size="small"
                        checked={filterValues.platform === plat.value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleFieldChange("platform", plat.value);
                          } else {
                            handleFieldChange("platform", "");
                          }
                        }}
                      />
                      <span className="text-[#000000B2]">{plat.name}</span>
                    </Stack>
                  </>
                ))}
              </RadioGroup>
            </div>

            {/* Popularity */}
            <div className="flex flex-col gap-2 mb-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Popularity</h2>
              <div>
                {popularity.map((item) => (
                  <>
                    <RadioGroup defaultValue="" name="radio-buttons-group">
                      <Stack direction="row" alignItems="center">
                        <Radio
                          size="small"
                          checked={filterValues.popularity === item.value}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleFieldChange("popularity", item.value);
                            } else {
                              handleFieldChange("popularity", "");
                            }
                          }}
                        />
                        <span className="text-[#000000B2]">{item.label}</span>
                      </Stack>
                    </RadioGroup>
                  </>
                ))}
              </div>
              <Slider
                value={
                  filterValues["popularity"] !== ""
                    ? [
                        parseInt(filterValues["popularity"].split("-")[0], 10),
                        parseInt(filterValues["popularity"].split("-")[1], 10),
                      ]
                    : [0, 100000000]
                }
                onChange={(e, newValue) =>
                  handleSliderChange("popularity", newValue)
                }
                // valueLabelDisplay="auto"
                min={0}
                max={100000000}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value}`}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Input
                  size="small"
                  style={{ width: "50%" }}
                  value={
                    filterValues["popularity"]
                      ? parseInt(filterValues["popularity"].split("-")[0], 10)
                      : 0
                  }
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                    handleSliderChange("popularity", [
                      e.target.value,
                      sliderValues["popularity"]
                        ? sliderValues["popularity"][1]
                        : 100000000,
                    ]);
                  }}
                  onBlur={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100000000,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
                <div>&emsp;&emsp;</div>
                <Input
                  size="small"
                  style={{ width: "50%" }}
                  value={
                    filterValues["popularity"]
                      ? parseInt(filterValues["popularity"].split("-")[1], 10)
                      : 100000000
                  }
                  onChange={(e) => {
                    if (e.target.value > 100000000) {
                      e.target.value = 100000000;
                    }
                    handleSliderChange("popularity", [
                      sliderValues["popularity"]
                        ? sliderValues["popularity"][0]
                        : 100000000,
                      e.target.value,
                    ]);
                  }}
                  onBlur={(e) => {
                    if (e.target.value > 100000000) {
                      e.target.value = 100000000;
                    }
                  }}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100000000,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </div>
            </div>

            {/* Category */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Categories</h2>
              <div>
                {categories.map((item) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        checked={filterValues.category.includes(item.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleFieldChange("category", [
                              ...filterValues.category,
                              item.value,
                            ]);
                          } else {
                            handleFieldChange(
                              "category",
                              filterValues.category.filter(
                                (category) => category !== item.value
                              )
                            );
                          }
                        }}
                      />
                      <span className="text-[#000000B2]">{item.name}</span>
                    </Stack>
                  </>
                ))}
              </div>
            </div>

            {/* Gender */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Gender</h2>
              <div>
                {genders.map((item) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        checked={filterValues.gender.includes(item.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleFieldChange("gender", [
                              ...filterValues.gender,
                              item.value,
                            ]);
                          } else {
                            handleFieldChange(
                              "gender",
                              filterValues.gender.filter(
                                (gender) => gender !== item.value
                              )
                            );
                          }
                        }}
                      />
                      <span className="text-[#000000B2]">{item.name}</span>
                    </Stack>
                  </>
                ))}
              </div>
            </div>

            {/* Other Filter based on platform like avg views, avg likes */}
            {influencerFilters.map((filter) => (
              <>
                <div key={filter.id} className="flex flex-col gap-2">
                  <>
                    <h2 className="mt-2 font-medium text-[#000000B2]">
                      {filter.filterName}
                    </h2>
                    <Box
                      sx={{
                        "@media(min-width: 768px)": {
                          width: "70%",
                        },
                        "@media(min-width: 1024px)": {
                          width: "90%",
                        },
                        "@media(min-width: 1280px)": {
                          width: "100%",
                        },

                        textAlign: "center",
                      }}
                    >
                      <Slider
                        className=""
                        value={
                          sliderValues[filter.name] || [
                            filter.sliderRangeMin,
                            filter.sliderRangeMax,
                          ]
                        }
                        onChange={(e, newValue) =>
                          handleSliderChange(filter.name, newValue)
                        }
                        // valueLabelDisplay="auto"
                        min={filter.sliderRangeMin}
                        max={filter.sliderRangeMax}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(vaue) =>
                          `${vaue}${filter.sliderLabelFormat}`
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Input
                          size="small"
                          style={{ width: "50%" }}
                          value={
                            filterValues[filter.name]
                              ? parseInt(
                                  filterValues[filter.name].split("-")[0],
                                  10
                                )
                              : 0
                          }
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              e.target.value = 0;
                            }
                            handleSliderChange(filter.name, [
                              e.target.value,
                              sliderValues[filter.name]
                                ? sliderValues[filter.name][1]
                                : filter.sliderRangeMax,
                            ]);
                          }}
                          onBlur={(e) => {
                            if (e.target.value < 0) {
                              e.target.value = 0;
                            }
                          }}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: filter.sliderRangeMax,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                        <div>&emsp;&emsp;</div>
                        <Input
                          size="small"
                          style={{ width: "50%" }}
                          value={
                            filterValues[filter.name]
                              ? parseInt(
                                  filterValues[filter.name].split("-")[1],
                                  10
                                )
                              : filter.sliderRangeMax
                          }
                          onChange={(e) => {
                            if (e.target.value > filter.sliderRangeMax) {
                              e.target.value = filter.sliderRangeMax;
                            }
                            handleSliderChange(filter.name, [
                              sliderValues[filter.name]
                                ? sliderValues[filter.name][0]
                                : filter.sliderRangeMax,
                              e.target.value,
                            ]);
                          }}
                          onBlur={(e) => {
                            if (e.target.value > filter.sliderRangeMax) {
                              e.target.value = filter.sliderRangeMax;
                            }
                          }}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: filter.sliderRangeMax,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </div>
                    </Box>
                  </>
                </div>
              </>
            ))}
          </div>

          {/* Data based on filters */}
          <div className="flex flex-col w-4/5 gap-4 justify-between">
            <div className="w-full">
              <p className="font-medium text-sm flex gap-2 items-center">
                Sort By
                <button
                  className="text-lg font-medium"
                  onClick={(e) => setSortEl(e.currentTarget)}
                >
                  {sort === 1 ? <MdSort /> : <GoSortDesc />}
                </button>
                <Menu
                  id="sort-menu"
                  anchorEl={sortEl}
                  open={Boolean(sortEl)}
                  onClose={() => setSortEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      setSort(-1);
                      setSortEl(null);
                    }}
                  >
                    High to Low
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setSort(1);
                      setSortEl(null);
                    }}
                  >
                    Low to High
                  </MenuItem>
                </Menu>
              </p>
              <Stack direction="row" spacing={2} marginTop={1} marginBottom={4}>
                <Chip
                  label="Name"
                  size="small"
                  color={clickout === 0 ? "primary" : "default"}
                  sx={{ color: `${clickout === 0 ? "white" : "black"}` }}
                  onClick={() => setClickout(0)}
                  onDelete={clickout === 0 ? () => setClickout(-1) : undefined}
                  deleteIcon={<HighlightOffIcon style={{ color: "white" }} />}
                />
                <Chip
                  label="Audience"
                  size="small"
                  color={clickout === 1 ? "primary" : "default"}
                  sx={{ color: `${clickout === 1 ? "white" : "black"}` }}
                  onClick={() => setClickout(1)}
                  onDelete={clickout === 1 ? () => setClickout(-1) : undefined}
                  deleteIcon={<HighlightOffIcon style={{ color: "white" }} />}
                />
                <Chip
                  label="Engagement Rate"
                  size="small"
                  color={clickout === 2 ? "primary" : "default"}
                  sx={{ color: `${clickout === 2 ? "white" : "black"}` }}
                  onClick={() => setClickout(2)}
                  onDelete={clickout === 2 ? () => setClickout(-1) : undefined}
                  deleteIcon={<HighlightOffIcon style={{ color: "white" }} />}
                />
                <Chip
                  label="Avg. Likes"
                  size="small"
                  color={clickout === 3 ? "primary" : "default"}
                  sx={{ color: `${clickout === 3 ? "white" : "black"}` }}
                  onClick={() => setClickout(3)}
                  onDelete={clickout === 3 ? () => setClickout(-1) : undefined}
                  deleteIcon={<HighlightOffIcon style={{ color: "white" }} />}
                />
                <Chip
                  label="Avg. Views"
                  size="small"
                  color={clickout === 4 ? "primary" : "default"}
                  sx={{ color: `${clickout === 4 ? "white" : "black"}` }}
                  onClick={() => setClickout(4)}
                  onDelete={clickout === 4 ? () => setClickout(-1) : undefined}
                  deleteIcon={<HighlightOffIcon style={{ color: "white" }} />}
                />
                <Chip
                  label="Reach"
                  size="small"
                  color={clickout === 5 ? "primary" : "default"}
                  sx={{ color: `${clickout === 5 ? "white" : "black"}` }}
                  onClick={() => setClickout(5)}
                  onDelete={clickout === 5 ? () => setClickout(-1) : undefined}
                  deleteIcon={<HighlightOffIcon style={{ color: "white" }} />}
                />
              </Stack>

              <div className="flex flex-col w-full gap-6">
                {data?.length > 0 ? (
                  data.map((info, index) => (
                    <ProfileCard
                      key={index}
                      data={info}
                      type="agencyPool"
                      showAdd={true}
                    />
                  ))
                ) : (
                  <div className=" mt-20 flex  justify-center items-center">
                    {!loading && <img src={image1} alt="Placeholder" />}
                  </div>
                )}
                {loading && <Loader />}
              </div>
            </div>
            {data?.length > 0 && (
              <div className="flex justify-center">
                <Stack spacing={2} direction="row" alignItems="center">
                  <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handlePageChange}
                    renderItem={(item) => (
                      <PaginationItem
                        slots={{
                          previous: ArrowBackIcon,
                          next: ArrowForwardIcon,
                        }}
                        {...item}
                      />
                    )}
                  />
                  <TablePagination
                    component="div"
                    count={totalPages}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelDisplayedRows={() => null}
                    rowsPerPageOptions={[5, 10, 15]}
                    slotProps={{
                      actions: {
                        nextButton: {
                          style: {
                            display: "none",
                          },
                        },
                        previousButton: {
                          style: {
                            display: "none",
                          },
                        },
                      },
                    }}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </ThemeProvider>
  );
};

export default AgencyPool;
