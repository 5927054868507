import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#2DA9B2",
    },
  },
});
export { customTheme };
