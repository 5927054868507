import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";

const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    if (!token || token === "") {
      navigate("/");
    }
  }, [token]);

  return (
    <div className="flex flex-col gap-4 w-full h-screen justify-center items-center">
      <FaCheckCircle className="text-4xl text-[#4abe4a]" />
      <p>Thanks! Your response has been recorded.</p>
      <p className="text-sm">
        Go to{" "}
        <span
          className="text-[#2DA9B2] cursor-pointer"
          onClick={() => navigate("/")}
        >
          Home Page
        </span>
      </p>
    </div>
  );
};

export default SuccessPage;
