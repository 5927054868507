import React, { useEffect, useState } from "react";
import { FaSearch, FaRegCopy, FaCopy, FaCheck } from "react-icons/fa";
import Navbar from "../common/Navbar";
import {
  Box,
  Checkbox,
  Input,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  ThemeProvider,
} from "@mui/material";
import OnboardTable from "./OnboardTable";
import notify from "../../utils/notification";
import { useSelector } from "react-redux";
import Footer from "../common/Footer";
import { customTheme } from "../../utils/theme";

const frontend = process.env.REACT_APP_CLIENT;
const referURL = process.env.REACT_APP_URL;

const influencerFilters = [
  {
    id: "5",
    checkbox: "false",
    slider: "true",
    sliderRangeMin: 0,
    sliderRangeMax: 100,
    sliderLabelFormat: "%",
    textfield: "false",
    filterName: "Engagement Rate",
    name: "engagementRate",
    types: [""],
  },
  {
    id: "7",
    checkbox: "false",
    slider: "true",
    sliderRangeMin: 0,
    sliderRangeMax: 100000,
    sliderLabelFormat: "",
    textfield: "false",
    filterName: "Avg. Likes",
    name: "avgLikes",
    types: [""],
  },
  {
    id: "8",
    checkbox: "false",
    slider: "true",
    sliderRangeMin: 0,
    sliderRangeMax: 100000,
    sliderLabelFormat: "",
    textfield: "false",
    filterName: "Avg. Views",
    name: "avgViews",
    types: [""],
  },
];

const categories = [
  { name: "Finance And Investment", value: 0 },
  { name: "Educational", value: 1 },
  { name: "Entertainment", value: 2 },
  { name: "Technology", value: 3 },
  { name: "Travel", value: 4 },
  { name: "Lifestyle", value: 5 },
  { name: "Fashion", value: 6 },
  { name: "Gaming", value: 7 },
  { name: "Auto", value: 8 },
  { name: "Health And Fitness", value: 9 },
  { name: "Other", value: 10 },
];

const platforms = [
  { name: "Youtube", value: "YOUTUBE" },
  { name: "Instagram", value: "INSTAGRAM" },
];

const popularity = [
  { value: "1000 - 9999", label: "Nano (1k-9k)" },
  { value: "10000 - 999999", label: "Micro (10k-999k)" },
  { value: "1000000 - 9999999", label: "Macro (1M-10M)" },
  { value: "10000000 - 100000000", label: "Mega (10M+)" },
];

const genders = [
  { name: "Male", value: "MALE" },
  { name: "Female", value: "FEMALE" },
];

const CopyClipboard = ({ text }) => {
  const [copy, setCopy] = useState(false);
  const copyText = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setCopy(true);
      setTimeout(() => setCopy(false), 3000);
      notify("info", "Link Copied!", 1500);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <span className="cursor-pointer ml-2">
      {!copy ? (
        <FaCopy
          className="text-[#2DA9B2] hover:text-[#2da9b2d8]"
          onClick={copyText}
        />
      ) : (
        <FaCheck className="text-green-500" />
      )}
    </span>
  );
};

const Onboarding = () => {
  const [onboard, setOnboard] = useState(true);
  const [referralCode, setCode] = useState("");
  const [sliderValues, setSliderValues] = useState([0, 100]);
  const [filterValues, setFilterValues] = useState({
    platform: "INSTAGRAM",
    popularity: "",
    name: "",
    category: [],
    gender: [],
    state: [],
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const data = useSelector((state) => state?.auth?.user);

  const handleFieldChange = (fieldName, value) => {
    setFilterValues((prevFormValue) => ({
      ...prevFormValue,
      [fieldName]: value,
    }));
  };

  const handleSliderChange = (filterName, newValue) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [filterName]: newValue,
    }));
    const newFilterValues = {
      ...filterValues,
      [filterName]: `${
        newValue[0] ? (newValue[0] < 0 ? 0 : newValue[0]) : 0
      } - ${newValue[1]}`,
    };
    setFilterValues(newFilterValues);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setCode(data?.agencyId);
  }, [data]);

  return (
    <ThemeProvider theme={customTheme}>
      <Navbar />
      <div className="flex justify-center mt-[-1.1rem] z-0">
        <button
          className={`rounded-[1.3rem] font-medium pb-2 px-4 pt-5 ${
            onboard === true
              ? "bg-[#2DA9B2] text-white"
              : "bg-[#E8E8E8] text-gray-600"
          }`}
          onClick={() => setTimeout(() => setOnboard(true), 300)}
        >
          Onboard
        </button>
        <button
          className={`rounded-[1.3rem] font-medium pb-2 px-4 pt-5 ${
            onboard === false
              ? "bg-[#2DA9B2] text-white"
              : "bg-[#E8E8E8] text-gray-600"
          }`}
          onClick={() => setTimeout(() => setOnboard(false), 300)}
        >
          Brand Deal
        </button>
      </div>

      <div className="mt-5 w-11/12 mx-auto">
        <div className="flex justify-between items-center">
          <p className="text-2xl font-medium">Echio Onboarding</p>
          <div className="flex border gap-3 items-center px-4 py-2 rounded-md w-1/3 text-gray-500 bg-white">
            <FaSearch />
            <input
              placeholder="Search"
              className="border-none outline-none text-black w-full"
              value={filterValues.name}
              onChange={(e) => handleFieldChange("name", e.target.value)}
            />
          </div>
          <div>
            <>
              <button
                className="border border-[#2DA9B2] text-[#2DA9B2] rounded-md px-3 h-[35px] flex gap-2 items-center text-sm   font-medium"
                onClick={(event) => setAnchorEl(event.currentTarget)}
              >
                <FaRegCopy /> Influencer Refer Link
              </button>
              <Popover
                id="share-popover"
                className="w-1/3"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className="flex flex-col gap-2 py-2 px-4 rounded-md text-center">
                  <span>Referral link for the inviting other influencers:</span>
                  <div className="flex items-center justify-between gap-2">
                    <span
                      className="overflow-hidden overflow-ellipsis whitespace-nowrap border px-1 rounded-md text-md border-[darkgray]"
                      style={{ pointerEvents: "none" }}
                    >
                      {referURL+ "?agency_id=" + referralCode}
                    </span>
                    <CopyClipboard
                      text={referURL+ "?agency_id=" + referralCode}
                    />
                  </div>
                </div>
              </Popover>
            </>
          </div>
        </div>

        <div className="flex justify-around gap-8 py-8">
          {/* Filters */}
          <div className="shadow-md bg-white px-4 pt-2 pb-8 h-fit w-1/5 rounded-md">
            {/* Platform */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000b2]">Platform</h2>
              <RadioGroup defaultValue="INSTAGRAM" name="radio-buttons-group">
                {platforms.map((plat) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Radio
                        size="small"
                        checked={filterValues.platform === plat.value}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleFieldChange("platform", plat.value);
                          } else {
                            handleFieldChange("platform", "");
                          }
                        }}
                      />
                      <span className="text-[#000000B2]">{plat.name}</span>
                    </Stack>
                  </>
                ))}
              </RadioGroup>
            </div>

            {/* Popularity */}
            <div className="flex flex-col gap-2 mb-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Popularity</h2>
              <div>
                {popularity.map((item) => (
                  <>
                    <RadioGroup defaultValue="" name="radio-buttons-group">
                      <Stack direction="row" alignItems="center">
                        <Radio
                          size="small"
                          checked={filterValues.popularity === item.value}
                          onChange={(e) => {
                            if (e.target.checked) {
                              handleFieldChange("popularity", item.value);
                            } else {
                              handleFieldChange("popularity", "");
                            }
                          }}
                        />
                        <span className="text-[#000000B2]">{item.label}</span>
                      </Stack>
                    </RadioGroup>
                  </>
                ))}
              </div>
              <Slider
                className=""
                value={
                  filterValues["popularity"] !== ""
                    ? [
                        parseInt(filterValues["popularity"].split("-")[0], 10),
                        parseInt(filterValues["popularity"].split("-")[1], 10),
                      ]
                    : [0, 100000000]
                }
                onChange={(e, newValue) =>
                  handleSliderChange("popularity", newValue)
                }
                // valueLabelDisplay="auto"
                min={0}
                max={100000000}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => `${value}`}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Input
                  size="small"
                  style={{ width: "50%" }}
                  value={
                    filterValues["popularity"]
                      ? parseInt(filterValues["popularity"].split("-")[0], 10)
                      : 0
                  }
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                    handleSliderChange("popularity", [
                      e.target.value,
                      sliderValues["popularity"]
                        ? sliderValues["popularity"][1]
                        : 100000000,
                    ]);
                  }}
                  onBlur={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100000000,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
                <div>&emsp;&emsp;</div>
                <Input
                  size="small"
                  style={{ width: "50%" }}
                  value={
                    filterValues["popularity"]
                      ? parseInt(filterValues["popularity"].split("-")[1], 10)
                      : 100000000
                  }
                  onChange={(e) => {
                    if (e.target.value > 100000000) {
                      e.target.value = 100000000;
                    }
                    handleSliderChange("popularity", [
                      sliderValues["popularity"]
                        ? sliderValues["popularity"][0]
                        : 100000000,
                      e.target.value,
                    ]);
                  }}
                  onBlur={(e) => {
                    if (e.target.value > 100000000) {
                      e.target.value = 100000000;
                    }
                  }}
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 100000000,
                    type: "number",
                    "aria-labelledby": "input-slider",
                  }}
                />
              </div>
            </div>

            {/* Category */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Categories</h2>
              <div>
                {categories.map((item) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        checked={filterValues.category.includes(item.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleFieldChange("category", [
                              ...filterValues.category,
                              item.value,
                            ]);
                          } else {
                            handleFieldChange(
                              "category",
                              filterValues.category.filter(
                                (category) => category !== item.value
                              )
                            );
                          }
                        }}
                      />
                      <span className="text-[#000000B2]">{item.name}</span>
                    </Stack>
                  </>
                ))}
              </div>
            </div>

            {/* Gender */}
            <div className="flex flex-col gap-2">
              <h2 className="mt-2 font-medium text-[#000000B2]">Gender</h2>
              <div>
                {genders.map((item) => (
                  <>
                    <Stack direction="row" alignItems="center">
                      <Checkbox
                        size="small"
                        checked={filterValues.gender.includes(item.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            handleFieldChange("gender", [
                              ...filterValues.gender,
                              item.value,
                            ]);
                          } else {
                            handleFieldChange(
                              "gender",
                              filterValues.gender.filter(
                                (gender) => gender !== item.value
                              )
                            );
                          }
                        }}
                      />
                      <span className="text-[#000000B2]">{item.name}</span>
                    </Stack>
                  </>
                ))}
              </div>
            </div>

            {/* Other Filter based on platform like avg views, avg likes */}
            {influencerFilters.map((filter) => (
              <>
                <div key={filter.id} className="flex flex-col gap-2">
                  <>
                    <h2 className="mt-2 font-medium text-[#000000B2]">
                      {filter.filterName}
                    </h2>
                    <Box
                      sx={{
                        "@media(min-width: 768px)": {
                          width: "70%",
                        },
                        "@media(min-width: 1024px)": {
                          width: "90%",
                        },
                        "@media(min-width: 1280px)": {
                          width: "100%",
                        },

                        textAlign: "center",
                      }}
                    >
                      <Slider
                        className=""
                        value={
                          sliderValues[filter.name] || [
                            filter.sliderRangeMin,
                            filter.sliderRangeMax,
                          ]
                        }
                        onChange={(e, newValue) =>
                          handleSliderChange(filter.name, newValue)
                        }
                        // valueLabelDisplay="auto"
                        min={filter.sliderRangeMin}
                        max={filter.sliderRangeMax}
                        valueLabelDisplay="auto"
                        valueLabelFormat={(vaue) =>
                          `${vaue}${filter.sliderLabelFormat}`
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Input
                          size="small"
                          style={{ width: "50%" }}
                          value={
                            filterValues[filter.name]
                              ? parseInt(
                                  filterValues[filter.name].split("-")[0],
                                  10
                                )
                              : 0
                          }
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              e.target.value = 0;
                            }
                            handleSliderChange(filter.name, [
                              e.target.value,
                              sliderValues[filter.name]
                                ? sliderValues[filter.name][1]
                                : filter.sliderRangeMax,
                            ]);
                          }}
                          onBlur={(e) => {
                            if (e.target.value < 0) {
                              e.target.value = 0;
                            }
                          }}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: filter.sliderRangeMax,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                        <div>&emsp;&emsp;</div>
                        <Input
                          size="small"
                          style={{ width: "50%" }}
                          value={
                            filterValues[filter.name]
                              ? parseInt(
                                  filterValues[filter.name].split("-")[1],
                                  10
                                )
                              : filter.sliderRangeMax
                          }
                          onChange={(e) => {
                            if (e.target.value > filter.sliderRangeMax) {
                              e.target.value = filter.sliderRangeMax;
                            }
                            handleSliderChange(filter.name, [
                              sliderValues[filter.name]
                                ? sliderValues[filter.name][0]
                                : filter.sliderRangeMax,
                              e.target.value,
                            ]);
                          }}
                          onBlur={(e) => {
                            if (e.target.value > filter.sliderRangeMax) {
                              e.target.value = filter.sliderRangeMax;
                            }
                          }}
                          inputProps={{
                            step: 1,
                            min: 0,
                            max: filter.sliderRangeMax,
                            type: "number",
                            "aria-labelledby": "input-slider",
                          }}
                        />
                      </div>
                    </Box>
                  </>
                </div>
              </>
            ))}
          </div>

          {/* Data based on filters */}
          <div className="flex flex-col w-4/5 gap-4 justify-between">
            <OnboardTable onboard={onboard} filterValues={filterValues} />
          </div>
        </div>
      </div>

      <Footer />
    </ThemeProvider>
  );
};

export default Onboarding;
