import Noty from "noty";

export default function notification(type = "info", text, timeout = 3000) {
  const noty = new Noty({
    text: text,
    type: type,
    theme: "metroui",
    timeout: timeout,
  });
  noty.show();
}
