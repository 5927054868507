// Func for making request to the backend server
const useFetch = () => {
  const makeRequest = async (
    method = "GET",
    endpoint = "/",
    data = null,
    token = null,
    signal = null,
    backend = process.env.REACT_APP_BACKEND
  ) => {
    if (token === null) {
      token = localStorage.getItem("token");
    }

    if (!token) {
      window.location = "/signIn";
    }

    try {
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
        "Agency-Auth": "1",
      };

      const config = {
        method: method,
        headers: headers,
      };

      if (data !== null) {
        config.body = JSON.stringify(data);
      }

      if (signal) {
        config.signal = signal;
      }

      const response = await fetch(backend + endpoint, config);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  };

  return { makeRequest };
};

export default useFetch;
