import { Box, Button, Popover, Tab, Tabs, ThemeProvider, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaYoutube } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import placeholder from "../../asset/placeholder.webp";
import signlogo from "../../asset/signlogo.png";
import useApi from '../../hooks/useApi';
import BrandCampaignTable from './BrandCampaignTable';
import Loader from "../common/Loader";
import insta from "../../Assets/instagram.png";
import { calculateTime, formatAlias } from '../../utils/formatNumber';
import { IoIosNotifications } from 'react-icons/io';
import Footer from "../common/Footer";
import { customTheme } from '../../utils/theme';

const BrandCampaignDetail = () => {
    const [clickout, setClickout] = useState(0);
    const [tabValue, setTabValue] = useState("shared");
    const [notification, setNotification] = useState([]);
    const [total, setTotal] = useState(-1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState();
    const [popoverEl, setPopoverEl] = useState(null);
    const { makeRequest } = useApi();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const campaignId = queryParams.get('id');
    const token = queryParams.get('token');
    const navigate = useNavigate();

    if (!localStorage.getItem("token")) {
        localStorage.setItem("token", token);
    }

    const handleChange = (event, value) => {
        setTimeout(() => setTabValue(value), 150)

        if (value === "shared") setClickout(0);
        else if (value === "ongoing") setClickout(1);
    };

    const getDetails = async () => {
        const requestData = {
            filters: {
                campaignId: campaignId
            },
            expand: {
                campaign: {}
            },
            pageSize: 1,
            page: 1
        }
        const response = await makeRequest("POST", "/works/list", requestData, token);
        setItem(response.data.data.results[0].campaign);
        console.log(response.data.data.results[0]);
    }

    const handlePopoverClick = (event) => {
        setPopoverEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setPopoverEl(null);
    };

    const getNotifications = async (pageNumber) => {
        if (total === notification.length) {
            return;
        }
        if (pageNumber === 1) {
            setNotification([]);
        }
        setLoading(true);
        const reqData = {
            filters: {},
            sort: {
                createdAt: -1,
            },
            expand: {
                influencer: {},
                campaign: {},
            },
            pageSize: 5,
            page: pageNumber,
        };
        try {
            const res = await makeRequest("POST", "/notifications/list", reqData, token);
            if (res) {
                const data = res.data.data?.results;
                console.log(data);
                setNotification((prev) => [...prev, ...data]);
                setTotal(res.data.data?.total);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const showMessage = (item) => {
        switch (item.kind) {
            case "INFLUENCER_SUGGESTED":
                return (
                    <span className="">
                        {item.influencerIds.length} influencers Suggested in the{" "}
                        {item?.campaign?.name} campaign
                    </span>
                );
            case "INFLUENCER_REVIEWED":
                return (
                    <span className="break-words">
                        A influencer is {item.approved ? "approved" : "rejected"} in the{" "}
                        {item?.campaign?.name} campaign
                    </span>
                );
            case "WORK_SUBMITTED":
                return (
                    <span className="word-wrap">
                        {item?.influencer?.name} has submitted{" "}
                        {formatAlias(item.deliveryStep)} of {formatAlias(item.deliveryType)}{" "}
                        in {" " + item?.campaign?.name} campaign
                    </span>
                );
            case "WORK_REVIEWED":
                return (
                    <span className="word-wrap">
                        {formatAlias(item.deliveryStep)} of {formatAlias(item.deliveryType)}{" "}
                        for {item?.influencer?.name} has been{" "}
                        {item.approved ? "approved" : "rejected"} by the brand in
                        {" " + item?.campaign?.name} campaign
                    </span>
                );
            default:
                return <></>;
        }
    };

    useEffect(() => {
        getDetails();
    }, [])

    useEffect(() => {
        if (popoverEl) {
            getNotifications(page);
        }
    }, [popoverEl, page]);

    return (
        <>
            {/* <Navbar /> */}
            <div className="flex justify-between items-center px-4 py-2 border shadow-md bg-white z-50 relative">
                <div>
                    <img src={signlogo} className="h-9" alt="Logo" />
                </div>
                <button>
                    <IoIosNotifications
                        className="text-2xl text-[#abb3bb] outline-none border-none"
                        onClick={handlePopoverClick}
                    />
                    <Popover
                        id="notifications"
                        open={Boolean(popoverEl)}
                        anchorEl={popoverEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                        }}
                        className="bg-transparent mt-4"
                    >
                        {/* <div className="py-1 pl-1 w-[280px] h-[300px] flex flex-col gap-3 overflow-y-scroll">
                            {notification.length > 0
                                ? notification.map((item, i) => (
                                    <div key={i} className="flex flex-col gap-1 w-full rounded-sm hover:bg-gray-100 py-1 px-2 cursor-pointer"
                                        onClick={() =>
                                            navigate(`/brand/campaignDetail?id=${item?.campaignId}&token=${token}`)
                                        }
                                    >
                                        {showMessage(item)}
                                        <span className="text-[0.75rem] text-gray-500 text-right">
                                            {calculateTime(item.createdAt)}
                                        </span>
                                    </div>
                                ))
                                : !loading && (
                                    <p className="flex justify-center items-center h-full w-full">
                                        No notifications to display!
                                    </p>
                                )}
                            {loading &&
                                <div className="py-4">
                                    <Loader />
                                </div>}
                            {!loading && page * 5 < total && (
                                <div className="flex justify-center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setPage(page + 1)}
                                    >
                                        See More
                                    </Button>
                                </div>
                            )}
                        </div> */}
                        <div className="py-1 px-1 w-[330px] h-[400px] flex flex-col gap-1 overflow-y-scroll">
                            <div className="flex w-full bg-[#ABB3BB4D] text-[#000000c2] py-1 px-2 font-medium rounded-md justify-center">
                                Notifications
                            </div>
                            {notification.length > 0
                                ? notification.map((item, i) => (
                                    <div
                                        key={i}
                                        className="flex flex-col gap-1 w-full rounded-md bg-[#ABB3BB4D] hover:bg-[#abb3bb2b] text-[#000000c2] py-1 px-2 cursor-pointer"
                                        onClick={() =>
                                            navigate(`/campaignDetail?id=${item?.campaignId}`)
                                        }
                                    >
                                        {showMessage(item)}
                                        <span className="text-[0.75rem] text-[#637381] text-right">
                                            {calculateTime(item.createdAt)}
                                        </span>
                                    </div>
                                ))
                                : !loading && (
                                    <p className="flex justify-center items-center h-full w-full">
                                        No notifications to display!
                                    </p>
                                )}
                            {loading && (
                                <div className="py-4">
                                    <Loader />
                                </div>
                            )}
                            {!loading && page * 5 < total && (
                                <div className="flex justify-center">
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => setPage(page + 1)}
                                    >
                                        See More
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Popover>
                </button>
            </div>

            <div className="my-10">
                <div className='flex w-11/12 mx-auto justify-between'>
                    <p className='text-2xl font-medium'>Campaign Detail</p>
                </div>

                {/*main part*/}
                {item ? (
                    <div className='w-11/12 min-h-[70vh] mx-auto my-8'>
                        <div className='mt-8 flex gap-4 shadow-md px-4 py-4 rounded-t-md bg-white'>
                            <img src={item.image || placeholder} className='w-[250px] h-[175px] rounded-md' alt="Campaign Img" />
                            <div className="flex justify-between w-full">
                                <div className=' flex flex-col gap-3 max-w-[70%]'>
                                    <div className='flex gap-4 items-center'>
                                        <span className='font-medium text-lg'>
                                            {item.name}
                                        </span>
                                        <span className={
                                            "text-center py-[0.1rem] px-4 text-[0.8rem] font-medium rounded-full h-fit " +
                                            (item.campaignStatus === "PAST"
                                                ? "bg-[#00000010] text-slate-600"
                                                : "bg-[#36B37E1A] text-[#36B37E]")
                                        }>
                                            {item.campaignStatus}
                                        </span>
                                    </div>
                                    <p className="text-slate-500">{item.description}</p>
                                </div>
                                <div className="flex flex-col items-end gap-1 justify-between w-[30%]">
                                    <div>
                                        <p className="text-slate-500"><span className="font-medium">Start Date:</span> {new Date(item.startDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/ /g, '-')}</p>
                                        <p className="text-slate-500"><span className="font-medium">End Date:</span> {new Date(item.endDate).toLocaleDateString('en-IN', { day: 'numeric', month: 'long', year: 'numeric' }).replace(/ /g, '-')}</p>
                                    </div>
                                    <div className="flex gap-3">
                                        {item.platform?.map((platform, index) => (
                                            <button
                                                key={index}
                                                className="flex items-center gap-1 px-2 bg-[#2DA9B21A] text-[#000000CC] rounded-md text-sm   font-medium h-[30px]"
                                            >
                                                {platform === "INSTAGRAM" && (
                                                    <>
                                                        <img src={insta} className="w-[20px] h-[20px]" />
                                                        Instagram
                                                    </>
                                                )}
                                                {platform === "YOUTUBE" && (
                                                    <>
                                                        <FaYoutube className="text-2xl text-[#FF0000]" />
                                                        Youtube
                                                    </>
                                                )}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Influencer Table */}
                        <ThemeProvider theme={customTheme}>
                            <Box sx={{
                                background: "#2DA9B21A",
                                borderBottom: 1,
                                borderColor: "divider",
                                borderRadius: "0px 0px 6px 6px",
                                px: 2,
                            }}>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                    aria-label="brand camp table tabs"
                                >
                                    <Tab
                                        label={
                                            <Tooltip title="Contains potential influencers shared with the brands">
                                                <span style={{ fontSize: "14px", fontWeight: 500 }}>SHARED INFLUENCERS</span>
                                            </Tooltip>
                                        }
                                        value="shared"
                                        sx={{ fontSize: "14px", fontWeight: 500 }}
                                    />
                                    <Tab
                                        label={
                                            <Tooltip title="Explore influencers currently active in the campaign">
                                                <span style={{ fontSize: "14px", fontWeight: 500 }}>ONGOING INFLUENCERS</span>
                                            </Tooltip>
                                        }
                                        value="ongoing"
                                        sx={{ fontSize: "14px", fontWeight: 500 }}
                                    />
                                </Tabs>
                            </Box>
                        </ThemeProvider>
                        <BrandCampaignTable status={
                            clickout === 0
                                ? "shared"
                                : "ongoing"
                        }
                            id={campaignId} token={token} delTypes={item.deliveryTypes} />
                    </div>
                ) : (
                    <Loader />
                )}
            </div>

            <Footer />
        </>
    )
}

export default BrandCampaignDetail