import React, { useEffect, useState } from "react";
import { Mms } from "@mui/icons-material";
import {
  Avatar,
  Button,
  Menu,
  MenuItem,
  Modal,
  Stack,
  TextField,
  ThemeProvider,
} from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";
import { useDispatch } from "react-redux";
import image2 from "../../Assets/Group 1000001634.png";
import image3 from "../../Assets/image 2 (1).png";
import insta from "../../Assets/instagram.png";
import useApi from "../../hooks/useApi";
import {
  setOngoingWorks,
  setSharedWorks,
  setShortlistedWorks,
  setSidebarData,
} from "../../redux/slices/campaignSlice";
import { toggleSidebar } from "../../redux/slices/componentSlice";
import {
  capitalize,
  formatCurrency,
  formatNumber,
  formatString,
} from "../../utils/formatNumber";
import notify from "../../utils/notification";
import reduce from "../../utils/reduce";
import Loader from "../common/Loader";
import { customTheme } from "../../utils/theme";

const deliveryTypes = [
  { name: "Instagram Stories", value: "INSTAGRAM_STORIES" },
  { name: "Instagram Posts", value: "INSTAGRAM_POSTS" },
  { name: "Instagram Reels", value: "INSTAGRAM_REELS" },
  { name: "YouTube Shorts", value: "YOUTUBE_SHORTS" },
  { name: "YouTube Dedicated Reels", value: "YOUTUBE_DEDICATED_REELS" },
  { name: "YouTube Integrated Videos", value: "YOUTUBE_INTEGRATED_VIDEOS" },
  { name: "YouTube Streams", value: "YOUTUBE_STREAMS" },
];

const deliveryPrice = [
  { type: "insta", name: "stories", value: "INSTAGRAM_STORIES" },
  { type: "insta", name: "post", value: "INSTAGRAM_POSTS" },
  { type: "insta", name: "reel", value: "INSTAGRAM_REELS" },
  { type: "yt", name: "shorts", value: "YOUTUBE_SHORTS" },
  { type: "yt", name: "dedicatedReel", value: "YOUTUBE_DEDICATED_REELS" },
  { type: "yt", name: "integratedVideo", value: "YOUTUBE_INTEGRATED_VIDEOS" },
  { type: "yt", name: "streaming", value: "YOUTUBE_STREAMS" },
];

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "platform",
    numeric: false,
    disablePadding: false,
    label: "Platform",
  },
  {
    id: "followers",
    numeric: false,
    disablePadding: false,
    label: "Reach",
  },
  {
    id: "gender",
    numeric: false,
    disablePadding: false,
    label: "Gender",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
  },
];

function EnhancedTableHead(props) {
  const {
    selected,
    setSelected,
    numSelected,
    onSelectAllClick,
    setReachOut,
    type,
    status,
    id,
    fetchInfluencers,
  } = props;
  const [detail, setDetail] = useState({
    campaignId: id,
    influencerIds: [],
  });
  const { makeRequest } = useApi();

  const suggestInfluencer = async () => {
    try {
      const influencerIds = selected.map((item) => item._id);
      detail.influencerIds = influencerIds;
      console.log(selected, detail);

      const res = await makeRequest("POST", "/influencers/suggest", detail);
      if (res) {
        notify("success", "Added to the suggested influencers!");
        setSelected([]);
        fetchInfluencers();
      }
    } catch (error) {
      console.log(error);
      notify("error", "Error, try again!");
    }
  };

  return (
    <>
      {numSelected > 0 ? (
        <TableHead style={{ background: "#2DA9B21f" }}>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0}
                checked={numSelected > 0}
                onChange={onSelectAllClick}
                inputProps={{
                  "aria-label": "select all desserts",
                }}
              />
            </TableCell>
            <TableCell>{numSelected} selected</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            {status === "shortlist" && <TableCell></TableCell>}
            {type === "campaign" && <TableCell></TableCell>}
            <TableCell align="right">
              {type === "plan" && status === "shortlisted" && (
                <button
                  onClick={() => {
                    setReachOut(true);
                  }}
                  type="button"
                  className="rounded-md text-[#2DA9B2] border border-[#2DA9B2] text-md px-4 py-1"
                >
                  Reach-Out
                </button>
              )}

              {type === "campaign" && status === "shortlist" && (
                <button
                  type="button"
                  onClick={suggestInfluencer}
                  className="rounded-md text-[#2DA9B2] border border-[#2DA9B2] text-md px-4 py-1"
                >
                  Add to Shared
                </button>
              )}
            </TableCell>
          </TableRow>
        </TableHead>
      ) : (
        <TableHead style={{ background: "lightgrey" }}>
          <TableRow>
            <TableCell padding="checkbox">
              {status !== "ongoing" && (
                <Checkbox
                  color="primary"
                  indeterminate={numSelected > 0}
                  checked={numSelected > 0}
                  onChange={onSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              )}
            </TableCell>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? "right" : "left"}
                padding={headCell.disablePadding ? "none" : "normal"}
              >
                {headCell.label}
              </TableCell>
            ))}
            {type === "campaign" && <TableCell>Agency Price</TableCell>}
            <TableCell key="status">Status</TableCell>
            {status === "shortlist" && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
      )}
    </>
  );
}

// EnhancedTableHead.propTypes = {
//   numSelected: PropTypes.number.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   setReachOut: PropTypes.func.isRequired,
// };

// function EnhancedTableToolbar(props) {
//   const { numSelected } = props;

//   return (
//     <div className="w-full flex justify-between items-center">
//       <Typography
//         sx={{ flex: "1 1 100%" }}
//         color="inherit"
//         variant="subtitle1"
//         component="div"
//       >
//         {numSelected} selected
//       </Typography>

//       <Tooltip title="Reach Out to these influencers">
//         <button className="mt-2 border uppercase text-white bg-[#2DA9B2] border-[#2DA9B2] p-2 rounded-md px-2 w-[150px] text-sm">
//           Reach Out
//         </button>
//       </Tooltip>
//     </div>
//   );
// }

// ReachOut.propTypes = {
//   setReachOut: PropTypes.func.isRequired,
// };

function ReachOut({ setReachOut, selected, setSelected, fetchInfluencers }) {
  const { makeRequest } = useApi();
  const [reachOutDetail, setReachOutDetail] = useState({
    responseIds: [],
    deliveryTypes: [],
    message: "",
  });

  const assignIds = async () => {
    selected.map((item) => {
      setReachOutDetail((prev) => ({
        ...prev,
        responseIds: [...prev.responseIds, item.responseId],
      }));
    });
  };

  useEffect(() => {
    assignIds();
  }, []);

  // For reach out responses
  const sendMessage = async (e) => {
    e.preventDefault();

    console.log("Selected: ", selected);
    console.log(reachOutDetail);

    if (reachOutDetail.deliveryTypes.length === 0) {
      notify("info", "Select delivery types!", 2000);
      return;
    }
    try {
      const res = await makeRequest(
        "POST",
        "/responses/reach-out",
        reachOutDetail
      );
      if (res) {
        notify("success", "Messages Sent!");
        setReachOutDetail({
          responseIds: [],
          deliveryTypes: [],
          message: "",
        });
        setReachOut(false);
        setSelected([]);
        fetchInfluencers();
      }
    } catch (error) {
      console.log(error);
      notify("error", "Error while reaching out, try again!");
    }
  };

  const handleTypeChange = (event) => {
    const value = event.target.value;
    const isIncluded = reachOutDetail.deliveryTypes?.includes(value);

    if (isIncluded) {
      console.log("filter");
      const newTypes = reachOutDetail.deliveryTypes.filter(
        (item) => item !== value
      );
      setReachOutDetail((prev) => ({ ...prev, deliveryTypes: newTypes }));
    } else {
      setReachOutDetail((prev) => ({
        ...prev,
        deliveryTypes: [...prev.deliveryTypes, event.target.value],
      }));
    }
  };

  return (
    <>
      <div className=" modal-background">
        <div className="modal-content">
          <IoIosCloseCircle
            className=" text-2xl -translate-y-6 cursor-pointer"
            onClick={() => setReachOut(false)}
          />
          <p className="font-medium text-lg">Reach Out Influencers</p>

          <div>
            <div className="mt-4 flex gap-4 p-1 py-2 rounded-md w-full bg-gray-300 justify-around">
              <p className="px-5">Name</p>
              <p>Customized Mesage</p>
            </div>
            <div className="flex mt-5 justify-between gap-4 px-4">
              <div className="overflow-y-scroll w-[50%] h-[25vh] px-1 md:px-4">
                {selected.map((item, i) => (
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={"10px"}
                    mb={2}
                    key={i}
                  >
                    <Avatar alt={item.name} src={item.image} variant="square" />
                    <Typography sx={{ alignItems: "center" }}>
                      {item.name}
                    </Typography>
                  </Stack>
                ))}
              </div>
              <textarea
                className="border border-[#2DA9B2] p-4 w-[26rem] h-[10rem] rounded-md outline-none"
                placeholder="Type a message"
                value={reachOutDetail.message}
                onChange={(e) =>
                  setReachOutDetail((prev) => ({
                    ...prev,
                    message: e.target.value,
                  }))
                }
              />
            </div>
          </div>

          <div className=" mt-4">
            <h1 className=" text-black font-medium text-lg">
              Select Dilevery Type
            </h1>
            <div className=" flex gap-2 mt-2 overflow-x-scroll lg:overflow-hidden">
              {deliveryTypes.map((type, i) => (
                <button
                  key={i}
                  className={
                    "rounded-md border text-md px-4 py-2 " +
                    (reachOutDetail.deliveryTypes?.includes(type.value)
                      ? "bg-[#2DA9B2] text-white"
                      : "border-[#2DA9B2] text-[#2DA9B2]")
                  }
                  name={type.name}
                  value={type.value}
                  onClick={handleTypeChange}
                >
                  {type.name}
                </button>
              ))}
            </div>
          </div>

          <div className=" mt-3">
            <h1 className=" text-black font-medium text-lg">Contact Via</h1>
            <div className=" flex justify-between items-center ">
              <div className=" flex mt-2 gap-5 items-center">
                <span className="flex items-center flex-col gap-1">
                  <img src={image3} className=" h-6" alt="" />
                </span>
                <span className="relative">
                  <img src={image2} alt="" className="opacity-70" />
                  <span className="text-black bg-opacity-70 text-[0.6rem] bg-white absolute top-0 left-[40%] rounded-sm text-center">
                    Coming Soon!
                  </span>
                </span>
              </div>
              <button
                type="button"
                className="rounded-md text-white bg-[#2DA9B2] font-medium text-sm px-5 py-2.5 me-2 mb-2"
                onClick={sendMessage}
              >
                Proceed
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default function DataTable({ type, status, id, delTypes }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const [openPrice, setOpenPrice] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [reachOut, setReachOut] = useState(false);
  const [instaPrice, setInstaPrice] = useState({
    reel: "",
    post: "",
    stories: "",
  });
  const [youtubePrice, setYoutubePrice] = useState({
    dedicatedReel: "",
    integratedVideo: "",
    streaming: "",
    shorts: "",
  });
  const [currentInfluencer, setCurrentInfluencer] = useState(null);
  const [sidebarstate, setsidebarState] = useState({ right: false });

  const { makeRequest } = useApi();

  const fetchInfluencers = async () => {
    if (!id) return;
    setLoading(true);
    let endpoint = "/";
    let filters = {
      filters: {
        status: [],
      },
      expand: {
        influencer: {},
      },
      pageSize: rowsPerPage,
      page: page + 1,
    };
    if (type === "plan") {
      filters.filters.groupId = [id];
      endpoint = "/responses/list";
      if (status === "shortlisted") {
        filters.filters.status.push("SHORTLISTED");
      } else if (status === "reachOut") {
        filters.filters.status.push("REACHED_OUT", "RESPONDED");
      } else {
        filters.filters.status.push("RESPONDED");
      }
    } else if (type === "campaign") {
      filters.filters.campaignId = id;
      endpoint = "/works/list";
      if (status === "shortlist") {
        filters.filters.status.push("SHORTLISTED");
      } else if (status === "ongoing") {
        filters.filters.status.push("ACTIVE", "COMPLETED");
      } else if (status === "suggest") {
        filters.filters.status.push("SUGGESTED", "DECLINED");
      }
    }
    try {
      const res = await makeRequest("POST", endpoint, filters);
      if (res) {
        setTotal(res.data.data?.total);
        const results = res.data.data.results;

        if (type === "campaign" && status === "shortlist") {
          dispatch(setShortlistedWorks(results));
        } else if (type === "campaign" && status === "suggest") {
          dispatch(setSharedWorks(results));
        } else if (type === "campaign" && status === "ongoing") {
          dispatch(setOngoingWorks(results));
        }

        setRows(
          results.map((item) => ({
            responseId: item._id,
            status: item.status,
            campaignId: item.campaignId,
            influencerId: item.influencerId,
            workId: item._id,
            responseDetails: item.responseDetails,
            agencyPrice: item.agencyPrice,
            instagramPrice: item.instagramPrice,
            youtubePrice: item.youtubePrice,
            ...item.influencer,
          }))
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setOpenPrice(false);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, item) => {
    if (status === "ongoing") return;
    const selectedIndex = selected.indexOf(item);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChatButtonClick = (e, row) => {
    dispatch(setSidebarData(row));
    dispatch(toggleSidebar());
  };

  const showPopup = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenPrice = (i) => {
    setOpenPrice(true);
  };

  const handleClosePrice = () => {
    setOpenPrice(false);
  };

  const handleInstagramPrice = (e) => {
    const { name, value } = e.target;
    setInstaPrice((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const handleYoutubePrice = (e) => {
    const { name, value } = e.target;
    setYoutubePrice((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const returnType = (type, instagramPrice, youtubePrice) => {
    const item = deliveryPrice.find((item) => item.value === type);

    if (type.includes("INSTAGRAM") && instagramPrice) {
      const priceItem = Object.entries(instagramPrice).find(
        ([key, value]) => key === item.name
      );
      if (priceItem) return { name: item.name, type: item.type };
    } else if (type.includes("YOUTUBE") && youtubePrice) {
      const priceItem = Object.entries(youtubePrice).find(
        ([key, value]) => key === item.name
      );
      if (priceItem) return { name: item.name, type: item.type };
    }
    return "";
  };

  const priceObj = (type, instagramPrice, youtubePrice) => {
    const item = deliveryPrice.find((item) => item.value === type);

    if (type.includes("INSTAGRAM") && instagramPrice) {
      const priceItem = Object.entries(instagramPrice).find(
        ([key, value]) => key === item.name
      );
      if (priceItem)
        return { name: item.name, value: priceItem[1], type: item.type };
    } else if (type.includes("YOUTUBE") && youtubePrice) {
      const priceItem = Object.entries(youtubePrice).find(
        ([key, value]) => key === item.name
      );
      if (priceItem)
        return { name: item.name, value: priceItem[1], type: item.type };
    }
    return { name: item.name, type: item.type };
  };

  const setPrice = async (e) => {
    e.preventDefault();
    const reqData = {
      campaignId: id,
      influencerId: currentInfluencer._id,
      agencyPrice: {
        instagram: reduce(instaPrice),
        youtube: reduce(youtubePrice),
      },
    };
    console.log(reqData);

    try {
      const res = await makeRequest("POST", "/works/set-agency-price", reqData);
      if (res) {
        notify("success", "Agency Price Set!");
        setSelected([]);
        fetchInfluencers();
        setOpenPrice(false);
        handleClose();
        setInstaPrice({
          reel: "",
          post: "",
          stories: "",
        });
        setYoutubePrice({
          dedicatedReel: "",
          integratedVideo: "",
          streaming: "",
          shorts: "",
        });
      }
    } catch (error) {
      console.log(error);
      notify("error", "Error, try again!");
    }
  };

  const isSelected = (item) => selected.indexOf(item) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  useEffect(() => {
    setSelected([]);
    fetchInfluencers();
  }, [page, rowsPerPage, type, status, id]);

  return (
    <>
      <ThemeProvider theme={customTheme}>
        <Box sx={{ width: "100%", mt: 2 }} className="shadow-lg">
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size="medium"
              >
                <EnhancedTableHead
                  selected={selected}
                  setSelected={setSelected}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                  setReachOut={setReachOut}
                  type={type}
                  status={status}
                  id={id}
                  fetchInfluencers={fetchInfluencers}
                />
                <TableBody>
                  {!loading ? (
                    rows.length > 0 ? (
                      rows.map((row, index) => {
                        const isItemSelected = isSelected(row);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row._id}
                            selected={isItemSelected}
                            sx={{ cursor: "pointer" }}
                          >
                            <TableCell padding="checkbox">
                              {status !== "ongoing" && (
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  onClick={(event) => handleClick(event, row)}
                                />
                              )}
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                spacing={"10px"}
                              >
                                <Avatar
                                  alt={row.name}
                                  src={row.image}
                                  variant="circle"
                                />
                                <Typography sx={{ alignItems: "center" }}>
                                  {row.name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell>
                              {row.instagram && (
                                <button className="flex items-center rounded-lg p-1 gap-1">
                                  <img
                                    src={insta}
                                    className="w-[20px] h-[20px]"
                                  />
                                  Instagram
                                </button>
                              )}
                              {row.youtube && (
                                <button className="flex items-center rounded-lg p-1 gap-1">
                                  <FaYoutube className="text-2xl text-[#FF0000]" />
                                  Youtube
                                </button>
                              )}
                            </TableCell>
                            <TableCell>
                              <p>
                                Follower {formatNumber(row.instagram?.audience)}
                              </p>
                              <p>
                                Subscriber {formatNumber(row.youtube?.audience)}
                              </p>
                            </TableCell>
                            <TableCell>{capitalize(row.gender)}</TableCell>

                            <TableCell>
                              {type === "campaign" ? (
                                <div className="flex gap-2 flex-col">
                                  {delTypes?.map((item, i) => {
                                    const obj = priceObj(
                                      item,
                                      row.instagramPrice,
                                      row.youtubePrice
                                    );
                                    return (
                                      <p key={i}>
                                        <span>{formatString(obj.name)}</span>{" "}
                                        <span>{formatCurrency(obj.value)}</span>
                                      </p>
                                    );
                                  })}
                                </div>
                              ) : row.status !== "RESPONDED" ? (
                                <>
                                  <p>
                                    Reel{" "}
                                    {formatCurrency(row.instagram?.price?.reel)}
                                  </p>
                                  <p>
                                    Posts{" "}
                                    {formatCurrency(row.instagram?.price?.post)}
                                  </p>
                                  <p>
                                    Stories{" "}
                                    {formatCurrency(
                                      row.instagram?.price?.stories
                                    )}
                                  </p>
                                </>
                              ) : (
                                <>
                                  {row.responseDetails?.instagramPrice
                                    ?.reel && (
                                    <p>
                                      Reel{" "}
                                      {formatCurrency(
                                        row.responseDetails?.instagramPrice
                                          ?.reel
                                      )}
                                    </p>
                                  )}
                                  {row.responseDetails?.instagramPrice
                                    ?.post && (
                                    <p>
                                      Posts{" "}
                                      {formatCurrency(
                                        row.responseDetails?.instagramPrice
                                          ?.post
                                      )}
                                    </p>
                                  )}
                                  {row.responseDetails?.instagramPrice
                                    ?.stories && (
                                    <p>
                                      Stories{" "}
                                      {formatCurrency(
                                        row.responseDetails?.instagramPrice
                                          ?.stories
                                      )}
                                    </p>
                                  )}

                                  {row.responseDetails?.youtubePrice
                                    ?.dedicatedReel && (
                                    <p>
                                      Dedicated Reel{" "}
                                      {formatCurrency(
                                        row.responseDetails?.youtubePrice
                                          ?.dedicatedReel
                                      )}
                                    </p>
                                  )}

                                  {row.responseDetails?.youtubePrice
                                    ?.streaming && (
                                    <p>
                                      Yt Streaming{" "}
                                      {formatCurrency(
                                        row.responseDetails?.youtubePrice
                                          ?.streaming
                                      )}
                                    </p>
                                  )}

                                  {row.responseDetails?.youtubePrice
                                    ?.shorts && (
                                    <p>
                                      Yt Shorts{" "}
                                      {formatCurrency(
                                        row.responseDetails?.youtubePrice
                                          ?.shorts
                                      )}
                                    </p>
                                  )}

                                  {row.responseDetails?.youtubePrice
                                    ?.integratedVideo && (
                                    <p>
                                      Yt Video{" "}
                                      {formatCurrency(
                                        row.responseDetails?.youtubePrice
                                          ?.integratedVideo
                                      )}
                                    </p>
                                  )}
                                </>
                              )}
                            </TableCell>

                            {type === "campaign" && (
                              <TableCell>
                                <p className="flex gap-2 flex-col">
                                  {Object.entries(row.agencyPrice.instagram)
                                    .length === 0 &&
                                    Object.entries(row.agencyPrice.youtube)
                                      .length === 0 && <span>N/A</span>}
                                  {Object.entries(
                                    row.agencyPrice.instagram
                                  ).map(([key, value]) => (
                                    <span key={key}>
                                      {formatString(key) +
                                        " " +
                                        formatCurrency(value)}{" "}
                                    </span>
                                  ))}
                                  {Object.entries(row.agencyPrice.youtube).map(
                                    ([key, value]) => (
                                      <span key={key}>
                                        {formatString(key) +
                                          " " +
                                          formatCurrency(value)}{" "}
                                      </span>
                                    )
                                  )}
                                </p>
                              </TableCell>
                            )}

                            <TableCell>
                              {(() => {
                                switch (row.status) {
                                  case "SHORTLISTED":
                                    return (
                                      <p className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                        Shortlisted
                                      </p>
                                    );
                                  case "RESPONDED":
                                    return (
                                      <p className="bg-[grey] rounded-full py-1 px-2 w-fit text-white">
                                        Responded
                                      </p>
                                    );
                                  case "REACHED_OUT":
                                    return (
                                      <p className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                        Reached Out
                                      </p>
                                    );
                                  case "SUGGESTED":
                                    return (
                                      <p className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                        Suggested
                                      </p>
                                    );
                                  case "ACTIVE":
                                    return (
                                      <>
                                        <span className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                          Active
                                        </span>
                                        &emsp;
                                        {type === "campaign" && (
                                          <Mms
                                            size="small"
                                            color="success"
                                            style={{
                                              cursor: "pointer",
                                              zIndex: 100,
                                            }}
                                            onClick={(e) => {
                                              handleChatButtonClick(e, row);
                                            }}
                                          />
                                        )}
                                      </>
                                    );
                                  case "COMPLETED":
                                    return (
                                      <>
                                        <span className="bg-green-500 rounded-full py-1 px-2 w-fit text-white">
                                          Completed
                                        </span>
                                        &emsp;
                                        {type === "campaign" && (
                                          <Mms
                                            size="small"
                                            color="success"
                                            style={{
                                              cursor: "pointer",
                                              zIndex: 100,
                                            }}
                                            onClick={(e) => {
                                              handleChatButtonClick(e, row);
                                            }}
                                          />
                                        )}
                                      </>
                                    );
                                  case "DECLINED":
                                    return (
                                      <p className="bg-[red] rounded-full py-1 px-2 w-fit text-white">
                                        Rejected
                                      </p>
                                    );
                                }
                              })()}
                            </TableCell>

                            {status === "shortlist" && (
                              <TableCell>
                                <>
                                  <Button
                                    id={index}
                                    aria-describedby="deliverable-popup"
                                    type="button"
                                    onClick={(e) => {
                                      setCurrentInfluencer(row);
                                      showPopup(e);
                                    }}
                                  >
                                    <BsThreeDotsVertical />
                                  </Button>
                                  <Menu
                                    id={index}
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    MenuListProps={{
                                      "aria-labelledby": "basic-button",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() => handleOpenPrice(row._id)}
                                    >
                                      Set Agency Price
                                    </MenuItem>
                                    <span>
                                      <Modal
                                        id={"p" + index}
                                        aria-labelledby="unstyled-modal-title"
                                        aria-describedby="unstyled-modal-description"
                                        open={
                                          openPrice &&
                                          row._id === currentInfluencer?._id
                                        }
                                        onClose={handleClosePrice}
                                        className="flex justify-center items-center"
                                      >
                                        <form
                                          sx={{ width: 400 }}
                                          className="bg-white text-black w-1/2 p-4 rounded-md flex flex-col gap-4"
                                          onSubmit={setPrice}
                                        >
                                          <h2 className="text-xl font-medium">
                                            Set Agency Price
                                          </h2>
                                          <div className="flex flex-col gap-2">
                                            {delTypes?.map((type, i) => {
                                              const item = returnType(
                                                type,
                                                row.instagramPrice,
                                                row.youtubePrice
                                              );
                                              return item !== "" ? (
                                                <TextField
                                                  key={i}
                                                  type="number"
                                                  name={item.name}
                                                  label={
                                                    formatString(item.name) +
                                                    " Price"
                                                  }
                                                  variant="outlined"
                                                  value={
                                                    item.type === "insta"
                                                      ? instaPrice[item.name]
                                                      : youtubePrice[item.name]
                                                  }
                                                  onChange={
                                                    item.type === "insta"
                                                      ? handleInstagramPrice
                                                      : handleYoutubePrice
                                                  }
                                                />
                                              ) : (
                                                <React.Fragment key={i} />
                                              );
                                            })}
                                          </div>

                                          <div className="flex justify-end">
                                            <button
                                              type="submit"
                                              className="bg-[#2DA9B2] hover:bg-[#2da9b2d8] text-white rounded-md px-3 h-[35px] flex items-center text-sm font-medium"
                                            >
                                              SET PRICE
                                            </button>
                                          </div>
                                        </form>
                                      </Modal>
                                    </span>
                                  </Menu>
                                </>
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableCell colSpan={8} height={150} align="center">
                        No influencers to display!
                      </TableCell>
                    )
                  ) : (
                    <TableCell colSpan={8} height={150} align="center">
                      <Loader />
                    </TableCell>
                  )}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 10 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        {reachOut && (
          <ReachOut
            setReachOut={setReachOut}
            selected={selected}
            setSelected={setSelected}
            fetchInfluencers={fetchInfluencers}
          />
        )}
      </ThemeProvider>
    </>
  );
}
