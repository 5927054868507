function formatNumber(number) {
  if (isNaN(number)) {
    return "N/A";
  }

  if (number < 1000) {
    return number.toString();
  }

  if (number < 1000000) {
    return (number / 1000).toFixed(1) + "k";
  }

  if (number < 1000000000) {
    return (number / 1000000).toFixed(1) + "M";
  }

  return (number / 1000000000).toFixed(1) + "B";
}

function formatCurrency(number) {
  if (isNaN(number)) {
    return "₹ N/A";
  }

  if (number < 1000) {
    return "₹" + number.toString();
  }

  if (number < 100000) {
    return "₹" + (number / 1000).toFixed(1) + "K";
  }

  return "₹" + (number / 100000).toFixed(1) + "Lacs";
}

function capitalize(string) {
  if (!string) return "N/A";
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function formatString(string) {
  if (!string) return "";

  const words = string.split(/(?=[A-Z])/);

  const formattedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  const formattedString = formattedWords.join(" ");

  return formattedString;
}

function formatBytes(bytes) {
  if (!bytes) return "N/A";
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

function formatAlias(item) {
  if (!item) return "";

  if (typeof item === "string") {
    const words = item.split("_");
    const formattedWord = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    return formattedWord.join(" ");
  }

  const formattedArray = [];
  item.forEach((word) => {
    const words = word.split("_");
    const formattedWord = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    formattedArray.push(formattedWord.join(" "));
  });
  return formattedArray.join(", ");
}

function getAlias(string) {
  if (!string) return "";

  let results = [];
  const words = string.split(" ");
  for (let word of words) {
    results.push(word.toUpperCase());
  }
  return results.join("_");
}

function calculateTime(timestamp) {
  if (timestamp === undefined) {
    return "";
  }
  const currentTime = new Date();
  const commentTime = new Date(timestamp);
  const difference = (currentTime.getTime() - commentTime.getTime()) / 60000; // Difference in minutes
  if (difference < 1) {
    return "just now";
  } else if (difference < 60) {
    return `${Math.round(difference)} min ago`;
  } else if (difference < 1440) {
    return `${Math.round(difference / 60)} hours ago`;
  } else {
    return `${Math.round(difference / 1440)} days ago`;
  }
}

export {
  formatNumber,
  formatCurrency,
  capitalize,
  formatString,
  formatAlias,
  formatBytes,
  getAlias,
  calculateTime,
};
